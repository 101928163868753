import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { getUserFeature } from "./UserFeatures.actions";
import { UserFeaturesState } from "../../Models/UserFeatures";

const initialState: UserFeaturesState = {
  loading: false,
  error: null,
  data: null
}

export const UserFeatureSlice = createSlice({
  name: "UserFeature",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserFeature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserFeature.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUserFeature.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default UserFeatureSlice.reducer;

export const userFeatureSelector = (state: State) => state.UserFeature;
