// Feature for direct deposit
export const DIRECT_DEPOSIT = "sharedDD";

export const WELCOME_NEW_APP = "WelcomeNewApp";

// Feature for update sendola
export const UPDATE_SENDOLA = "migracionSolidUSI";

// Feature for banners
export const CASH_IN_BANNER = "cashInBanner";
export const BUY_PHONE_BANNER = "buyPhoneBanner";
export const REPATRIATION_BANNER = "repatriationBanner";
export const EXTERNAL_CASH_IN_BANNER = "externalCashInBanner";
export const ATM_BANNER = "atmBanner";
export const PAYROLL_ADVANCE_BANNER = "payrollAdvanceBanner";

// Feature for Plaid
export const PLAID_FEATURE = "PlaidFeature";
export const PLAID_GLOBAL_PAYMENTS_FEATURE = "PlaidGlobalPaymentsFeature";

// Feature for Direct Deposit
export const DIRECT_DEPOSIT_REQUEST_FEATURE = "DirectDepositRequestFeature";
export const COPPEL_ACCESS_ACCOUNT_FEATURE = "CoppelAccessAccount";

// Feature for Pending SSN
export const PENDING_SSN = "pendingSSN";

// Feature for Visa Request
export const VISA_REQUEST = "approvedVisa";

// Feature for Modal Tutorial Transaction
export const MODAL_TRANSACTIONS = "modalTransaction";

// Feature for Payroll banner
export const PAYROLL_BANNER = "payrollBanner";

// Feature for Soccer banner
export const PROMOTION_SOCCER_BANNER = "promotionSoccerBanner";