import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_USER_FEATURE } from "../../Constants/user";
import { request } from "../../Models/UserFeatures";
import { defaultErrorMessage } from "../../Constants/Requests";

/**
 * Method to get user feature
 */
export const getUserFeature = createAsyncThunk(
  GET_USER_FEATURE,
  async (payload: request, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/userfeatures/${payload.userId}/${payload.featureCode}`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
