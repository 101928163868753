import styled, { css, keyframes } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

import { handleSizeButton, handleText } from "../../Utilities/SizesButtons";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import {
  ButtonBorderProps,
  ButtonGradientProps,
} from "../../Models/buttonInterface";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const GeneralStyles = `
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  margin: 12px;
  padding: 8px;
  border-radius: 50px;
  width: 80%;
  &:hover {
    opacity: 0.9;
  }
`;
export const ButtonOutline = styled.button`
  ${GeneralStyles}
  background-color: ${(props) => props.theme.Background_01};
  color: ${(props) => props.theme.Background_02};
  border: 1px solid ${(props) => props.theme.Background_02};
`;

export const ButtonDefault = styled.button<{
  $type?: string;
  $size?: string;
  $sizeButton?: string;
  $border?: ButtonBorderProps;
  $textColor?: string;
  $bgGradient?: ButtonGradientProps;
  $fontWeight?: string;
}>`
  ${GeneralStyles}
  ${(props) => {
    switch (props.$type) {
      case "primary":
        return `border: none; background: ${props.theme.Primary_01}; color: ${props.theme.Background_01}; `;

      case "outline":
        return `border: 2px solid ${props.theme.Primary_01} !important; background: ${props.theme.Background_01}; color: ${props.theme.Primary_01};`;

      case "secondary":
        return `box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset; background: ${props.theme.Background_02}; color: ${props.theme.Background_01}; `;

      case "danger":
        return `border: 1px solid ${props.theme.Red_08}; background: ${props.theme.Background_01}; color: ${props.theme.Red_08}; `;
      case "gradient":
        return `background: linear-gradient(
          ${
            props.$bgGradient?.orientation === "horizontal"
              ? "to bottom"
              : "to right"
          }, 
          ${props.$bgGradient?.firstColorStop}, 
          ${props.$bgGradient?.secondColorStop});`;

      case "purple":
        return `border: 1px solid ${props.theme.Primary_13}; background: ${props.theme.Primary_13}; color: ${props.theme.Background_01}; `;
      case "contrast":
        return `border: 1px solid ${props.theme.Black_Contrast_01}; background: ${props.theme.Black_Contrast_01}; color: ${props.theme.Background_01}; `;
      default:
        return `border: 1px solid ${props.theme.Content_UX_02}; background: ${props.theme.Content_UX_02}; color: ${props.theme.Background_01}; `;
    }
  }};
  ${ifProp(
    "$textColor",
    css`
      color: ${prop("$textColor")};
    `,
    ""
  )};
  ${ifProp(
    "$border",
    css`
      border-width: ${ifProp("$border.size", prop("$border.size"), "1")}px;
      border-color: ${ifProp(
        "$border.color",
        prop("$border.color"),
        theme("Content_UX_02")
      )};
      border-style: solid;
    `,
    css`
      border: none;
    `
  )};
  position: relative;
  font-family: "Poppins";
  max-width: ${(props) =>
    props.$sizeButton
      ? `${handleSizeButton(props.$sizeButton)}px`
      : `${handleSizeButton("default")}px`};
  width: ${(props) =>
    props.$sizeButton
      ? `${handleSizeButton(props.$sizeButton)}px`
      : `${handleSizeButton("default")}px`};
  font-size: ${(props) =>
    props.$size ? `${handleText(props.$size)}px` : "20px"};
  font-weight: ${(props) =>
    props.$fontWeight ? props.$fontWeight : "700"};
  ${(props) => {
    switch (props.$sizeButton) {
      case "xsmall":
        return `padding: 5px 0px 5px 0px !important; min-width: ${handleSizeButton(props.$sizeButton)}px !important; margin 6px !important`;
      default:
        return `padding: 8px; min-width: 110px;`;
    }
  }};

  &.disabled {
    background-color: ${(props) => props.theme.Content_UX_02};
    color: ${(props) => props.theme.Background_01};
    border: 1px solid ${(props) => props.theme.Content_UX_02};

    background: ${(props) =>
      props.$type === "outline"
        ? props.theme.Background_01
        : props.theme.Content_UX_02};
    color: ${(props) =>
      props.$type === "outline"
        ? props.theme.Content_UX_02
        : props.theme.Content_UX_03};
    border: 1px solid ${(props) => props.theme.Content_UX_02};
  }

  &:hover {
    ${mediaQuery[Screen.lg]} {
      background: ${(props) => {
        switch (props.$type) {
          case "primary":
            return `linear-gradient(180deg, ${props.theme.Primary_03} 0%, ${props.theme.Primary_01} 100%)`;
          case "outline":
            return props.theme.Background_07;
          default:
            return props.theme.Primary_01;
        }
      }};

      color: ${(props) => {
        switch (props.$type) {
          case "primary":
            return props.theme.Background_01;
          case "outline":
            return props.theme.Primary_01;
          default:
            return props.theme.Background_01;
        }
      }};

      border: 1px solid
        ${(props) => {
          switch (props.$type) {
            case "primary":
              return `linear-gradient(180deg, ${props.theme.Primary_03} 0%, ${props.theme.Primary_01} 100%)`;
            case "outline":
              return `linear-gradient(180deg, ${props.theme.Primary_03} 0%, ${props.theme.Primary_01} 100%)`;
            default:
              return props.theme.Content_UX_03;
          }
        }};
    }
  }

  ${mediaQuery[Screen.xs]} {
    margin: 8px auto;
    text-align: center;
    ${(props) => {
      switch (props.$sizeButton) {
        case "xsmall":
          return ``;
        default:
          return `padding: 8px 16px; width: 80%`;
      }
    }};
  }
`;

export const ButtonLoader = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border: 0.1em solid rgba(${theme("RGB_Background_01")}, 0.3);
  border-left-color: ${theme("Content_UX_03")};
  border-radius: 50%;
  animation: ${spin} 0.5s linear infinite;
  margin: 0 auto;
`;

export const TextContainer = styled.div<{
  $iconButton?: string;
  $iconButtonLeft?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    width: ${(props) =>
      !props.$iconButton && !props.$iconButtonLeft ? "100%" : ""};
    text-align: ${(props) => (!props.$iconButton ? "center" : "right")};
    padding-left: 10px;
    padding-right: 10px;

    ${mediaQuery[Screen.xs]} {
      padding: 0;
    }
  }

  div {
    padding-left: ${(props) => (!props.$iconButton ? 0 : "10px")};
    padding-right: ${(props) => (!props.$iconButtonLeft ? 0 : "10px")};
  }
`;
