import { components, OptionProps } from "react-select";
import {
  SectionOption,
  TextOption,
  TextOptionImageAvatar,
  TextOptionNew,
} from "./SearchSelect.styled";
import { useTranslation } from "react-i18next";
import { SelectOption } from "../../Models/Select";

export const CustomOption = ({
  children,
  ...props
}: OptionProps<SelectOption | null>) => {
  const [t] = useTranslation("global");

  return (
    <components.Option {...props}>
      <SectionOption>
        {props.data?.showAvatar && (
          <>
            {props.data.image && (
              <TextOptionImageAvatar src={props.data.image} />
            )}
          </>
        )}
        <TextOption>{children}</TextOption>
        {props.data?.isNew && <TextOptionNew>{t("Quote.News")}</TextOptionNew>}
      </SectionOption>
    </components.Option>
  );
};
