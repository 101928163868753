import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { MESSAGES, createVeriffFrame } from "@veriff/incontext-sdk";
import isEmpty from "lodash.isempty";
import Carousel from "../../Components/Carousel";

// Own states of redux
import { AppDispatch, State } from "../../Redux/Store";
import { PrefilledDataSelector } from "../../Redux/PrefilledData/PrefilledData.slice";
import { ByUserSliceSelector } from "../../Redux/ByUser/ByUser.slice";
import { getKycStatusSelector } from "../../Redux/GetKYCStatus/GetKYCStatus.slice";
import { changeTypeAccount } from "../../Redux/SelectTypeAccount";
import { getAllAccounts } from "../../Redux/Accounts/Accounts.actions";
// import { getPayrollByUserAction } from "../../Redux/Payroll/PayrollData.actions";
import { GetDirectDeposit } from "../../Redux/CheckDirectDeposit/CheckDirectDeposit.actions";
import { selectorLanguage } from "../../Redux/Translate";
import { getUserFeature } from "../../Redux/UserFeatures/UserFeatures.actions";
import { UploadCashIn } from "../../Redux/CashIn/CashIn.actions";
import { getCoppelAccess } from "../../Redux/CoppelAccess/CoppelAccess.actions";
import { updateCoppelAccess } from "../../Redux/UpdateCoppelAccess/UpdateCoppelAccess.actions";
import { PlaidAccountAction } from "../../Redux/FundsAccount/FundsAccount.actions";
import { selectCreditCellphoneId } from "../../Redux/Cellphone/Cellphone.slice";
import { getCellphoneAction } from "../../Redux/Cellphone/Cellphone.actions";
import { GetIdvIsRequiredAction } from "../../Redux/IDVRequired/IDVRequired.actions";

// Own Models
import { AccountObject } from "../../Models/Account";
import { Initial_Results, KYC_Data_Results } from "../../Models/KYC";
import { AlignItems, JustifyContent } from "../../Models/Column";
import { PlaidAccounts, PlaidAccountsResponse } from "../../Models/Funds";
import { CellphoneData } from "../../Models/Cellphone";

// Own utils
import { filterObject } from "../../Utilities/FilterObjects";
import { formatDate } from "../../Utilities/FormatDate";

// Own constants
import {
  CashInLink,
  CashInLinkEn,
  CoppelAccessCelularExternalServices,
  CoppelAccessCelularExternalServicesEn,
  CoppelAccessLinkEN,
  CoppelAccessLinkES,
  PayrollAdvanceLinkEN,
  PayrollAdvanceLinkES,
  MyCelularExternalServices,
  MyCelularExternalServicesEn,
  OpenCoppelAccess,
  RepatriationExternalServices,
  RepatriationExternalServicesEn,
  SoccerBannerEN,
  SoccerBannerES,
} from "../../Constants/LinksExternals";
import {
  KYC_APPROVED,
  KYC_DECLINED,
  KYC_IN_REVIEW,
  KYC_RESUBMISSION,
  KYC_RESUBMISSION_REQUESTED,
} from "../../Constants/KYCStatus";
import { GET_ALL_ACCOUNTS_FULFILLED } from "../../Constants/Account";
import {
  GET_DIRECT_DEPOSIT_FULFILLED,
  // GET_PAYROLL_BYUSER_FULFILLED,
} from "../../Constants/Payroll";
import {
  GET_USER_FEATURE_FULFILLED,
} from "../../Constants/user";
import {
  BUY_PHONE_BANNER,
  CASH_IN_BANNER,
  REPATRIATION_BANNER,
  DIRECT_DEPOSIT_REQUEST_FEATURE,
  COPPEL_ACCESS_ACCOUNT_FEATURE,
  // PAYROLL_BANNER,
  PROMOTION_SOCCER_BANNER,
  PLAID_GLOBAL_PAYMENTS_FEATURE,
  PAYROLL_ADVANCE_BANNER,
} from "../../Constants/Features";
import { GET_COPPEL_STATUS_FULFILLED } from "../../Constants/CoppelAccess";
import { GET_PLAID_ACCOUNT_FULFILLED } from "../../Constants/FundsAccount";
import { BLDSpecialityContractor } from "../../Constants/Customers";
import { WhatsAppLinkEn, WhatsAppLinkEs } from "../../Constants/ConfigureApi";
import { GET_CELLPHONE_FULFILLED } from "../../Constants/Cellphone";
import { EN } from "../../Constants/Langs";
import { IDV_IS_REQUIRED_FULFILLED } from "../../Constants/IDV";

// Own components
import { Container, Row, Column } from "../../Components/Grid";
import Layout from "../../Components/Layout";
import Text from "../../Components/Text";
import {
  BalanceCard,
  BalanceCardEmpty,
  BannerBankBalanceCard,
} from "../../Components/BalanceCard";
import WarningMessage from "../../Components/WarningMessage";
import LoaderIcon from "../../Components/LoaderIcon/LoaderIcon.component";
// import { PayrollStatusBanner } from "../ManageAccount/Components";
import ZelleBanner from "../../Components/ZelleBanner";
import PayrollSetting from "../../Components/PayrollSetting";
import BannerServicesCard from "../../Components/BannerServicesCard";
import CoppelCard from "../../Components/CoppelCard";
import NotificationModal from "../../Components/NotificationModal";
import CoppelCardAccount from "../../Components/CoppelCardAccount";
import Icon from "../../Components/Icon";
import LinkingCard from "../../Components/LinkingCard";
import LinkedAccountCard from "../../Components/LinkedAccountCard";
import PlaidLink from "../Funds/Components/PlaidLink";
import LinkedAccountDeleteModal from "../../Components/LinkedAccountCard/LinkedAccountDeleteModal.component";
import GlobalPaymentsQuoter from "../../Components/GlobalPaymentsQuoter";
import PendingRequestBanner from "../../Components/PendingRequestBanner";

// Own styles
import {
  HomeContainer,
  HomeLinkAccount,
  RowMargin,
  TextNotification,
  TextNotificationPaid,
} from "./HomePage.styles";

// Own assets
import SendolaContigo from "../../Assets/Img/sendola_contigo.png";
import SendolaCel from "../../Assets/Img/sendola_cel.png";
import SendolaAdelanto from "../../Assets/Img/sendola_adelanto.png";
import PuertoRicoAlertImage from "../../Assets/Img/puertoRicoAlert.png";
import VerifyIdentityImage from "../../Assets/Img/identity-verify.png"; 

const HomePage = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const benefitsRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShowLinkedAccountDeleteModal, setShowLinkedAccountDeleteModal] =
    useState<boolean>(false);
  const [isAccountsLoading, setAccountsIsLoading] = useState<boolean>(true);
  const [isLinkedAccountsLoading, setLinkedAccountsIsLoading] =
    useState<boolean>(true);
  const [accounts, setAccount] = useState<AccountObject[]>();
  const [linkedAccounts, setLinkedAccounts] = useState<PlaidAccountsResponse>();
  const [openPueroRicoAlert, setOpenPueroRicoAlert] = useState<boolean>(false);
  const [, setShowDirectDeposit] = useState<boolean>(false); // It is left like this for the moment since the Share direct deposit functionality is being hidden showDirectDeposit
  {
    /* Temporary disabled */
  }
  // const [hasPinwheelAccount, setHasPinwheelAccount] = useState<boolean>(false);
  const [resubmissionStatus, setResubmissionStatus] = useState<boolean>(false);
  const [urlResubmissionstatus, setUrlResubmissionstatus] = useState<string>("");
  const [currentLinkedAccount, setCurrentLinkedAccount] = useState<string>();
  const [isOpenPlaid, setIsOpenPlaid] = useState<boolean>(false);
  const [isUpdatedBannerBankAccount, setUpdateBannerBankAccount] =
    useState<boolean>(false);

  /** This cases are features **/
  const [CashInFeature, setCashInFeature] = useState<boolean>(false);
  const [BuyPhoneFeature, setBuyPhoneFeature] = useState<boolean>(false);
  const [RepatriationFeature, setRepatriationFeature] =
    useState<boolean>(false);
  const [DirectDepositFeature, setDirectDepositFeature] =
    useState<boolean>(true);
  const [isOpenDirectDepositModal, setIsOpenDirectDepositModal] =
    useState<boolean>(false);
  const [CoppelAccessAccountFeature, setCoppelAccessAccountFeature] =
    useState<boolean>(false);
  {
    /* Temporary disabled */
  }
  // const [PayrollFeature, setPayrollFeature] = useState<boolean>(false);
  const [PromotionSoccerFeature, setPromotionSoccerFeature] =
    useState<boolean>(false);
  const [PlaidGlobalPaymentsFeature, setPlaidGlobalPaymentsFeature] =
    useState<boolean>(false);
  const [PayrollAdvanceFeature, setPayrollAdvanceFeature] =
    useState<boolean>(false);
  const [CoppelAccessState, setCoppelAccessState] = useState("");
  const [accountsItems, setAccountsItems] = useState<Array<ReactNode>>([]);
  const [showCellphone, setShowCellphone] = useState(true);
  const [dataCellphone, setDataCellphone] = useState<CellphoneData | null>();
  const [VerifyIdentity, setVerifyIdentity] = useState<boolean>(false);
  const [pathIdvIsRequired, setPathIdvIsRequired] = useState<string>("");

  const ByUser = useSelector(ByUserSliceSelector);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const kycStatusSelector = useSelector(getKycStatusSelector);

  const notificationsItemsTotal: number = 1;

  const lang = useSelector(selectorLanguage);
  const urlRepatriation =
    lang.language === EN
      ? RepatriationExternalServicesEn
      : RepatriationExternalServices;
  const urlMyCelular =
    lang.language === EN
      ? MyCelularExternalServicesEn
      : MyCelularExternalServices;
  const urlCoppelAccessCelular =
    lang.language === EN
      ? CoppelAccessCelularExternalServicesEn
      : CoppelAccessCelularExternalServices;
  const urlCashIn = lang.language === EN ? CashInLinkEn : CashInLink;
  const urlSoccerBanner =
    lang.language === EN ? SoccerBannerEN : SoccerBannerES;
  const urlWhatsAppSupport =
    lang.language === EN ? WhatsAppLinkEn : WhatsAppLinkEs;
  const urlSeeAccount =
    lang.language === EN ? CoppelAccessLinkEN : CoppelAccessLinkES;
  const urlPayrollAdvance =
    lang.language === EN ? PayrollAdvanceLinkEN : PayrollAdvanceLinkES;
  const locLang = lang.language === "en" ? "en-US" : "es-ES";

  const kycStatus = useMemo(
    () => kycStatusSelector.data?.status || "",
    [kycStatusSelector]
  );

  const kycLevel = useMemo(
    () => kycStatusSelector.data?.level || 0,
    [kycStatusSelector]
  );

  const pepStatus = useMemo(
    () => kycStatusSelector.data?.results?.pep?.status || "",
    [kycStatusSelector]
  );

  const kycResults = useMemo(
    () => kycStatusSelector.data?.results || Initial_Results,
    [kycStatusSelector]
  );

  const userPhoneNumber = useMemo(
    () => PrefilledData.data?.phone,
    [PrefilledData]
  );

  const personId = useMemo(() => ByUser.data?.personId, [ByUser]);

  const userId = useMemo(() => ByUser.data?.userId || "", [ByUser]);

  const nationality = useMemo(
    () => PrefilledData.data?.nationality || "MEX",
    [PrefilledData]
  );

  const subdivision = useMemo(
    () => PrefilledData.data?.address?.countrySubdivision || "",
    [PrefilledData]
  );

  const customerId = useMemo(() => ByUser.data?.customerId, [ByUser]);

  const handleOpenIframeVeriff = () => {
    createVeriffFrame({
      url: urlResubmissionstatus,
      onEvent: function (msg: string) {
        switch (msg) {
          case MESSAGES.FINISHED:
            window.location.href = "/submit-kyc";
            break;
          case MESSAGES.CANCELED:
            window.location.href = "/";
            break;
        }
      },
    });
  };

  const handleResubmissionStatus = (results: KYC_Data_Results) => {
    const data: KYC_Data_Results = filterObject(
      results,
      "status",
      KYC_RESUBMISSION || KYC_RESUBMISSION_REQUESTED
    );

    if (data.fullAuto && data.fullAuto?.hasAttemps) {
      setResubmissionStatus(true);
      setUrlResubmissionstatus(data.fullAuto?.url || "");
    }
  };

  const selectTypeAccount = (type: string, idAccount: string) => {
    const payload = {
      type: type,
      id: idAccount,
    };
    dispatch(changeTypeAccount(payload));
    navigate("/manage-card-account");
  };

  const getAllAccountsDispatch = async (personId: string) => {
    const response = await dispatch(getAllAccounts(personId));

    if (response?.type === GET_ALL_ACCOUNTS_FULFILLED) {
      const sortedAccounts = [...response.payload].sort((a, b) => {
        if (
          a.sponsorBank === "Community Federal Savings Bank" &&
          b.sponsorBank !== "Community Federal Savings Bank"
        ) {
          return 1;
        } else if (
          a.sponsorBank !== "Community Federal Savings Bank" &&
          b.sponsorBank === "Community Federal Savings Bank"
        ) {
          return -1;
        } else {
          return 0;
        }
      });

      setAccount(sortedAccounts);
    }

    setAccountsIsLoading(false);
    setIsLoading(false);
  };

  const getManualDirectDeposit = async () => {
    const payload = {
      personId: personId,
      customerId: customerId,
    };

    const response = await dispatch(GetDirectDeposit(payload));

    if (response?.type === GET_DIRECT_DEPOSIT_FULFILLED) {
      setShowDirectDeposit(response.payload.isSent);
    }
  };

  {
    /* Temporary disabled */
  }
  // const getAutomaticDirectDeposit = async () => {
  //   const response = await dispatch(getPayrollByUserAction());

  //   if (response?.type === GET_PAYROLL_BYUSER_FULFILLED) {
  //     setHasPinwheelAccount(response.payload?.hasPhinwheelAcount);
  //   }
  // };

  const GetUserFeature = async (
    userId: string,
    featureCode: string,
    setUserFeature: (active: boolean) => void
  ) => {
    const request = {
      userId: userId,
      featureCode: featureCode,
    };
    const response = await dispatch(getUserFeature(request));

    if (response?.type === GET_USER_FEATURE_FULFILLED) {
      setUserFeature(response?.payload?.isActive);
    }
  };

  const sendToCashIn = async () => {
    const payload = {
      userId: userId,
      personId: personId,
      code: CASH_IN_BANNER,
    };

    await dispatch(UploadCashIn(payload));
  };

  const getStatusCoppelAccess = async (personId: string) => {
    const response = await dispatch(getCoppelAccess(personId));

    if (response?.type === GET_COPPEL_STATUS_FULFILLED) {
      setCoppelAccessState(response.payload.status);
    }
  };

  const onOpenCoppelAccount = async () => {
    if (personId) {
      window.open(OpenCoppelAccess, "_blank", "noopener,noreferrer");
      return await dispatch(updateCoppelAccess(personId));
    }
  };

  const getLinkedAccounts = async () => {
    setLinkedAccountsIsLoading(true);

    const response = await dispatch(PlaidAccountAction(lang.language));

    if (response?.type === GET_PLAID_ACCOUNT_FULFILLED) {
      setLinkedAccounts(response.payload);
    }

    setLinkedAccountsIsLoading(false);
  };

  const getAccountsCards = (
    isLoading: boolean,
    accounts: Array<AccountObject> | undefined,
    linkedAccounts: PlaidAccountsResponse | undefined,
    hasFeature: boolean,
    status: string
  ) => {
    const carouselItems = [];

    if (accounts && accounts?.length > 0) {
      accounts.map((account: AccountObject) => {
        if (
          hasFeature &&
          status === "accountOpened" &&
          account.sponsorBank === "Community Federal Savings Bank"
        ) {
          carouselItems.push(
            <CoppelCardAccount
              key={account.id}
              status="active"
              message={t("CoppelAccess.activeMessage")}
              amount={account.availableBalance}
              showBalance={account?.availableBalance ? true : false}
              accountNumber={account.accountNumber}
              routingNumber={account.routingNumber}
              logoUrlBank={account.logoUrlBank}
              onClickBtn={() =>
                window.open(urlSeeAccount, "_blank", "noopener,noreferrer")
              }
            />
          );
        } else if (
          kycStatus === KYC_APPROVED &&
          account.sponsorBank !== "Banner Bank"
        ) {
          carouselItems.push(
            <BalanceCard
              key={account.id}
              title={
                account.type === "personalChecking"
                  ? t("balanceCard.checkAccount")
                  : t("balanceCard.savingsAccount")
              }
              accountNumber={account.accountNumber}
              amount={account.availableBalance}
              subtitle={t("balanceCard.availableBalance")}
              showAlert={false}
              showButton={false}
              buttonText={t("balanceCard.btnAddFunds")}
              buttonDisabled={false}
              active={account.status === "active" ? true : false}
              selectTypeAccount={() =>
                selectTypeAccount(account.type, account.id)
              }
              sponsorBank={account.sponsorBank}
              logoUrlBank={account.logoUrlBank}
              type={account.type}
              countrySubdivision={subdivision}
              handleShowNotification={showNotificationAlert}
            />
          );
        } else if (
          kycStatus === KYC_APPROVED &&
          account.sponsorBank === "Banner Bank" &&
          PlaidGlobalPaymentsFeature
        ) {
          let unlinkedLedgerAccount: PlaidAccounts | null = null;

          if (linkedAccounts) {
            unlinkedLedgerAccount = getUnLinkedLedgerAccount(
              account.sendolaPlaidAccountId || "",
              linkedAccounts?.accountsLedger
            );
          }

          carouselItems.push(
            <BannerBankBalanceCard
              key={account.id}
              isLinkedAccount={account?.isLinkedPlaid}
              isRelinkedAccount={!!unlinkedLedgerAccount}
              isUpdatedAccount={isUpdatedBannerBankAccount}
              title={
                account.type === "personalChecking"
                  ? t("balanceCard.checkAccount")
                  : t("balanceCard.savingsAccount")
              }
              accountNumber={account.accountNumber}
              amount={account.availableBalance}
              subtitle={t("balanceCard.availableBalance")}
              showAlert={false}
              showButton={false}
              buttonText={t("balanceCard.btnAddFunds")}
              buttonDisabled={false}
              active={false}
              selectTypeAccount={() =>
                selectTypeAccount(account.type, account.id)
              }
              sponsorBank={account.sponsorBank}
              logoUrlBank={account.logoUrlBank}
              type={account.type}
              hasDirectDeposit={DirectDepositFeature}
              onDirectDeposit={() => {
                setIsLoading(false);
                setIsOpenDirectDepositModal(true);
              }}
              onOpenPlaid={() => {
                if (unlinkedLedgerAccount) {
                  setCurrentLinkedAccount(account.sendolaPlaidAccountId);
                  return setIsOpenPlaid(true);
                }
              }}
              onLinkAccount={() =>
                navigate("/funds-capture-check-instructions", {
                  state: {
                    firstAccount: true,
                    homePage: true,
                    linkCustomName:
                      lang.language === "en"
                        ? "bannerbank"
                        : "bannerbankspanish",
                    accountId: account.id,
                  },
                })
              }
              countrySubdivision={subdivision}
              handleShowNotification={showNotificationAlert}
            />
          );
        }
      });
    }

    if (
      (!accounts || accounts?.length === 0) &&
      linkedAccounts &&
      !linkedAccounts?.hasAccounts &&
      PlaidGlobalPaymentsFeature
    ) {
      carouselItems.push(<LinkingCard key={`empty-0`} />);
    }

    if (linkedAccounts && linkedAccounts?.hasAccounts) {
      linkedAccounts?.accounts.map((account) => {
        carouselItems.push(
          <LinkedAccountCard
            key={account.id}
            id={account.id}
            amount={account?.available}
            isUpdatedAccount={isUpdatedBannerBankAccount}
            bankName={account.bankName}
            accountNumber={account.accountLastFour}
            routingNumber={account.routingNumber}
            bgColor={account.backgroundColor}
            logoUrl={account.logoUrl}
            isActive={account.activeToken}
            isApproved={kycStatus === KYC_APPROVED}
            type={account.subtype}
            onShowDeleteModal={() =>
              setShowLinkedAccountDeleteModal(!isShowLinkedAccountDeleteModal)
            }
            onOpenPlaid={() => setIsOpenPlaid(true)}
            selectAccount={setCurrentLinkedAccount}
            countrySubdivision={subdivision}
            handleShowNotification={showNotificationAlert}
          />
        );
      });
    }

    if (hasFeature) {
      if (status === null || status === "onboardingStarted") {
        carouselItems.push(
          <CoppelCard
            isUsaPhoneNumber={
              userPhoneNumber && userPhoneNumber.startsWith("+1") ? true : false
            }
            key={"status-0"}
            status="open"
            message={
              userPhoneNumber && userPhoneNumber.startsWith("+1")
                ? t("CoppelAccess.openMessageUS")
                : t("CoppelAccess.openMessage")
            }
            {...(userPhoneNumber && userPhoneNumber.startsWith("+1")
              ? {}
              : { link: urlCoppelAccessCelular })}
            onClickBtn={() => onOpenCoppelAccount()}
          />
        );
      } else if (
        status === "accountPending" ||
        (!accounts && !isLoading && status === "accountOpened")
      ) {
        carouselItems.push(
          <CoppelCard
            key={"status-1"}
            status="inProcess"
            message={t("CoppelAccess.processMessage")}
          />
        );
      }
    }

    setAccountsItems(carouselItems);
  };

  const getUnLinkedLedgerAccount = (
    currentLedgerAccountId: string,
    plaidAccounts: Array<PlaidAccounts>
  ): PlaidAccounts | null => {
    let unlikedLedgerAccount = undefined;

    if (plaidAccounts?.length > 0 && !isEmpty(currentLedgerAccountId)) {
      unlikedLedgerAccount = plaidAccounts.filter(
        (account) =>
          account.id === currentLedgerAccountId && !account.activeToken
      )[0];
      if (unlikedLedgerAccount) {
        setCurrentLinkedAccount(currentLedgerAccountId);
      }

      return unlikedLedgerAccount;
    }
    return null;
  };

  const getCreditCellphone = async (personId: string) => {
    const response = await dispatch(getCellphoneAction(personId));

    if (response?.type === GET_CELLPHONE_FULFILLED) {
      if (response.payload === null) {
        setShowCellphone(false);
      }

      if (response.payload?.id) {
        setDataCellphone(response.payload);
        dispatch(selectCreditCellphoneId(response.payload?.id));
        setShowCellphone(true);
      }
    } else {
      setShowCellphone(false);
    }
  };

  const showNotificationAlert = () => {
    setOpenPueroRicoAlert(true);
  };

  const GetIdvISRequired = async (personId:string) => {
    const response = await dispatch(GetIdvIsRequiredAction(personId));

    if (response?.type === IDV_IS_REQUIRED_FULFILLED) {
      if(response?.payload?.hasIdvVeriff === false) {
        setPathIdvIsRequired("/kyc-identity-veriff");
        return
      }

      if(response?.payload?.hasAddress === false) {
        setPathIdvIsRequired("/kyc-personal-address");
        return
      }
    }
  };

  useEffect(() => {
    if (personId && userId) {
      // Temporary disabled
      // GetUserFeature(userId, PAYROLL_BANNER, setPayrollFeature);
      GetUserFeature(userId, CASH_IN_BANNER, setCashInFeature);
      GetUserFeature(userId, BUY_PHONE_BANNER, setBuyPhoneFeature);
      GetUserFeature(userId, REPATRIATION_BANNER, setRepatriationFeature);
      GetUserFeature(
        userId,
        DIRECT_DEPOSIT_REQUEST_FEATURE,
        (active: boolean) => {
          setDirectDepositFeature(active);
          setIsOpenDirectDepositModal(active);
        }
      );
      GetUserFeature(
        userId,
        COPPEL_ACCESS_ACCOUNT_FEATURE,
        setCoppelAccessAccountFeature
      );
      GetUserFeature(
        userId,
        PROMOTION_SOCCER_BANNER,
        setPromotionSoccerFeature
      );
      GetUserFeature(
        userId,
        PLAID_GLOBAL_PAYMENTS_FEATURE,
        setPlaidGlobalPaymentsFeature
      );
      GetUserFeature(userId, PAYROLL_ADVANCE_BANNER, setPayrollAdvanceFeature);
      // getAutomaticDirectDeposit();
      getAllAccountsDispatch(personId);
      handleResubmissionStatus(kycResults);
      getManualDirectDeposit();
      getStatusCoppelAccess(personId);
      getCreditCellphone(personId);
      GetIdvISRequired(personId);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getLinkedAccounts();
  }, [lang]);

  useEffect(() => {
    getAccountsCards(
      isAccountsLoading || isLinkedAccountsLoading,
      accounts,
      linkedAccounts,
      CoppelAccessAccountFeature,
      CoppelAccessState
    );
  }, [lang, accounts, linkedAccounts, CoppelAccessState]);

  return (
    <ThemeProvider theme={theme}>
      {/* Layout of loading */}
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <PayrollSetting
        show={!isLoading && DirectDepositFeature && isOpenDirectDepositModal}
        onClose={() => setDirectDepositFeature(false)}
        onCloseModal={() => setIsOpenDirectDepositModal(false)}
      />
      {/* Layout - home page */}
      <Layout
        show={!isLoading && kycStatus !== KYC_DECLINED || pepStatus === KYC_APPROVED && !isOpenDirectDepositModal}
        refToScroll={benefitsRef}
      >
        <Container as={HomeContainer}>
          { kycStatus === KYC_IN_REVIEW && (
            <Row>
              <Column span={12} mt={2}>
                <PendingRequestBanner
                  title={t("banners.bannerInReview.title")}
                  message={t("banners.bannerInReview.message")}
                  heightAuto
                />
              </Column>
            </Row>
          )}
          <Row>
            {/* Notification Banners Carousel */}
            {PromotionSoccerFeature && (
              <Column span={12} my={1}>
                <Carousel
                  itemSize={notificationsItemsTotal === 1 ? "100%" : "336px"}
                  totalItems={notificationsItemsTotal}
                  gap={"8px"}
                >
                  {/* Temporary disabled */}
                  {/* {kycStatus === KYC_APPROVED &&
                    accounts &&
                    accounts.length > 0 &&
                    hasPersonalChecking(accounts) &&
                    personalCheckingStatus === "active" &&
                    !hasPinwheelAccount &&
                    PayrollFeature && (
                      <PayrollStatusBanner
                        hasPinwheelAccount={hasPinwheelAccount}
                      />
                  )} */}

                  {/* Promotions Banners Carousel */}
                  <ZelleBanner
                    title={t("banners.Sweepstakes.title")}
                    message={t("banners.Sweepstakes.message")}
                    textButton={t("banners.Sweepstakes.btn")}
                    urlLink={urlSoccerBanner}
                  />
                </Carousel>
              </Column>
            )}
          </Row>
          {customerId !== BLDSpecialityContractor && !isAccountsLoading && (
            <GlobalPaymentsQuoter
              title={t("HomePage.GlobalPaymentsQuoter.title")}
              amount={500}
              countryDestination={nationality}
              countrySubdivision={subdivision}
              kycLevel={kycLevel}
              kycStatus={kycStatus}
              pepStatus={pepStatus}
              sponsorBank={accounts?.[0]?.sponsorBank}
              handleShowNotification={() => showNotificationAlert()}
              setVerifyIdentity={setVerifyIdentity}
            />
          )}
          {
            <Row>
              <Column span={12} my={1}>
                <Row>
                  <Column span={6}>
                    <Text
                      color="black"
                      align="left"
                      weight="500"
                      margin="0"
                      size={1}
                    >
                      {t("HomePage.accountTitle")}
                    </Text>
                  </Column>
                  <Column span={6} justifyContent={JustifyContent.flexEnd}>
                    {PlaidGlobalPaymentsFeature && (
                      <HomeLinkAccount
                        to="/funds-capture-check-instructions"
                        state={{ firstAccount: true, homePage: true }}
                        replace
                        variant="none"
                        size={0.5}
                      >
                        <Icon icon="plus" size="small" color="Primary01" />
                        <span>{t("LinkedAccounts.linkAccounts")}</span>
                      </HomeLinkAccount>
                    )}
                  </Column>
                </Row>
                <Row>
                  {/* Carousel Accounts */}
                  <Column span={12}>
                    {accountsItems?.length === 0 &&
                      isAccountsLoading &&
                      isLinkedAccountsLoading && <BalanceCardEmpty />}
                    {accountsItems?.length > 0 && (
                      <>
                        <Carousel
                          itemSize={
                            accountsItems?.length === 1 ? "100%" : "310px"
                          }
                          totalItems={accountsItems?.length}
                        >
                          {accountsItems}
                        </Carousel>
                      </>
                    )}
                  </Column>
                </Row>
              </Column>
            </Row>
          }
          
          <Row ref={benefitsRef}>
            <Column span={12}>
              <Text color="black" align="left" weight="500" size={1} margin="0">
                {t("banners.title")}
              </Text>
            </Column>
          </Row>
          {/* Banners of Services */}
          <Row as={RowMargin}>
            {BuyPhoneFeature && !showCellphone && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_02"
                  icon="deviceMobile"
                  title={t("banners.bannerPhone.title")}
                  message={t("banners.bannerPhone.message")}
                  link={urlMyCelular}
                  target={true}
                  imageTitle={SendolaCel}
                  widthImageTitle={95}
                />
              </Column>
            )}
            {BuyPhoneFeature &&
              showCellphone &&
              dataCellphone?.status !== "Paid" && (
                <Column span={6}>
                  <BannerServicesCard
                    bgColor="Gradient_02"
                    icon="deviceMobile"
                    title={t("banners.bannerPhone.title")}
                    message={t("banners.bannerPhone.message")}
                    onClickBtn={() => navigate("/manage-payments")}
                    link=""
                    imageTitle={SendolaCel}
                    widthImageTitle={70}
                    heightImageTitle={32}
                    imgSubtitle={dataCellphone?.image}
                    notification={
                      <>
                        <TextNotification $margin="14px 0 0 6px">
                          {t("banners.bannerPhone.pay")}
                        </TextNotification>
                        <TextNotification $weight={600} $margin="0 0 0 6px;">
                          {dataCellphone?.nextPayment
                            ? formatDate(dataCellphone?.nextPayment, locLang)
                            : ""}
                        </TextNotification>
                      </>
                    }
                    buttonText={t("banners.bannerPhone.buttonManage")}
                    check
                  />
                </Column>
              )}
            {BuyPhoneFeature &&
              showCellphone &&
              dataCellphone?.status === "Paid" && (
                <Column span={6}>
                  <BannerServicesCard
                    bgColor="Gradient_02"
                    icon="deviceMobile"
                    title={t("banners.bannerPhone.title")}
                    message={t("banners.bannerPhone.message")}
                    onClickBtn={() => navigate("/manage-payments")}
                    link=""
                    imageTitle={SendolaCel}
                    widthImageTitle={70}
                    heightImageTitle={32}
                    imgSubtitle={dataCellphone?.image}
                    buttonIcon="star"
                    notification={
                      <>
                        <TextNotificationPaid $margin="14px 0 0 6px">
                          {t("banners.bannerPhone.fullPayment")}
                        </TextNotificationPaid>
                      </>
                    }
                    buttonText={t("banners.bannerPhone.buttonFullPayment")}
                    check
                    sizeTextBtn="10px"
                  />
                </Column>
              )}
            {PayrollAdvanceFeature && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_11"
                  icon="deviceMobile"
                  title={t("banners.bannerPayrollAdvance.title")}
                  message={t("banners.bannerPayrollAdvance.message")}
                  link={urlPayrollAdvance}
                  target={true}
                  imageTitle={SendolaAdelanto}
                  widthImageTitle={95}
                />
              </Column>
            )}
            {CashInFeature && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_07"
                  icon="cash"
                  title={t("banners.bannerCashIn.title")}
                  message={t("banners.bannerCashIn.message")}
                  link={urlCashIn}
                  target={true}
                  onClickBtn={sendToCashIn}
                />
              </Column>
            )}
            {RepatriationFeature && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_09"
                  icon=""
                  imageTitle={SendolaContigo}
                  title={t("banners.bannerRepatriation.title")}
                  message={t("banners.bannerRepatriation.message")}
                  link={urlRepatriation}
                  target={true}
                />
              </Column>
            )}
          </Row>
        </Container>
        {/* Linked Account - Open Plaid to Re-link */}
        {isOpenPlaid && (
          <PlaidLink
            requiredUpdate={true}
            updateData={{
              redirectUri: "https://prueba.example.com",
              accountId: currentLinkedAccount || "",
            }}
            isOpenAutomatic={isOpenPlaid}
            btnVariant="secondary"
            btnText={t("LinkedAccounts.link")}
            onClose={() => {
              setIsOpenPlaid(false);
              setCurrentLinkedAccount("");
            }}
            onCompleted={(_, isUpdated) => {
              setIsOpenPlaid(false);
              getLinkedAccounts();
              setCurrentLinkedAccount("");

              if (isUpdated) {
                setUpdateBannerBankAccount(true);
              }
            }}
          />
        )}
        {/* Linked Account Delete Modal */}
        <LinkedAccountDeleteModal
          accountId={currentLinkedAccount || ""}
          isShowModal={isShowLinkedAccountDeleteModal}
          onShowModal={() =>
            setShowLinkedAccountDeleteModal(!isShowLinkedAccountDeleteModal)
          }
          onDeleteCompleted={() => getLinkedAccounts()}
        />
        {/* Open Puerto Rico */}
        <NotificationModal
          show={openPueroRicoAlert}
          modalName="modal-open-puerto-rico"
          image={PuertoRicoAlertImage}
          hasSupport
          hideSubmit
          urlSupport={urlWhatsAppSupport}
          title={t("HomePage.GlobalPaymentsQuoter.notificationModal.title")}
          paragraph1={t(
            "HomePage.GlobalPaymentsQuoter.notificationModal.subtitle"
          )}
          btnText={t("buttons.submit")}
          handleActionButton={() => ""}
          handleClose={() => setOpenPueroRicoAlert(false)}
          textAlignTitle="center"
          textAlignparagraph1="center"
        />
        {/* Verify Indentity for KYC LVL 1 */}
        <NotificationModal
          show={VerifyIdentity}
          modalName="modal-verify-identity"
          image={VerifyIdentityImage}
          textAlignTitle="center"
          title={t("notificationModal.verifyIdentity.title")}
          paragraph1={t("notificationModal.verifyIdentity.paragraph1")}
          btnText={t("notificationModal.verifyIdentity.btnText")}
          handleActionButton={() => navigate(pathIdvIsRequired)}
          handleClose={() => setVerifyIdentity(false)}
        >
          <Row>
            <Column span={2} my={1}>
              <Icon icon="passport" color="primary" size="medium"/>
            </Column>
            <Column span={10} alignItems={AlignItems.center}>
              <Text color="black" align="left" weight="400" margin="0" size={1}>
                {t("notificationModal.verifyIdentity.listItems.passport")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column span={2} my={1}>
              <Icon icon="licenseCardAmerican" color="primary" size="medium"/>
            </Column>
            <Column span={10} alignItems={AlignItems.center}>
              <Text color="black" align="left" weight="400" margin="0" size={1}>
                {t("notificationModal.verifyIdentity.listItems.lincese")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column span={2} my={1}>
              <Icon icon="iDCardAmerican" color="primary" size="medium"/>
            </Column>
            <Column span={10} alignItems={AlignItems.center}>
              <Text color="black" align="left" weight="400" margin="0" size={1}>
                {t("notificationModal.verifyIdentity.listItems.idCard")}
              </Text>
            </Column>
          </Row>
        </NotificationModal>
        {/* Resumibssion KYC */}
        <NotificationModal
          show={resubmissionStatus}
          modalName="modal-resubmission-fullAuto"
          image={VerifyIdentityImage}
          hasSupport
          textAlignTitle="center"
          title={t("notificationModal.Resubmission.title")}
          paragraph1={t("notificationModal.Resubmission.paragraph1")}
          btnText={t("notificationModal.Resubmission.btnText")}
          handleActionButton={() => handleOpenIframeVeriff()}
          handleClose={() => setResubmissionStatus(false)}
        />
      </Layout>
      {/* Layout of KYC declined status */}
      <Layout show={!isLoading && (kycStatus === KYC_DECLINED || pepStatus === KYC_DECLINED || pepStatus === KYC_DECLINED) && !resubmissionStatus}>
        <Container>
          <Row>
            <Column span={12} my={2}>
              <WarningMessage
                title={t("ApplicationDenied.title2")}
                message={t("ApplicationDenied.message2")}
                icon="circleXRed"
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default HomePage;
