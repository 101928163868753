import styled, { css } from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const Container = styled.div`
  background-color: ${(props) => props.theme.Neutral_900};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 64px;
  max-width: 1500px;
  margin: 0 auto;

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
  }
`;

export const Linear = styled.div`
  background: linear-gradient(
    225deg,
    ${(props) => props.theme.Primary_03} 0%,
    ${(props) => props.theme.Primary_01} 100%
  );
  height: 45px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
  }
`;

export const Logocontent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 40px;

  img {
    max-height: 122px;
    max-width: 320px;
    width: 100%;
    height: 85%;
    object-fit: cover;
    object-position: 50% 50%;

    ${mediaQuery[Screen.mobile]} {
      display: block;
      margin: 0 auto;
      max-width: 180px;
      height: 73px;
      width: 100%;
    }
  }

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
    padding-right: 0;
    margin-top: 32px;
  }
`;

const stylesList = css`
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin: 15px;
    margin-right: 60px;
    font-weight: 500;
    line-height: normal;

    a {
      font-size: 18px;
      font-weight: 500;
    }

    ${mediaQuery[Screen.mobile]} {
      margin: 20px;
      text-align: left;
    }
  }
`;

export const ListMobile = styled.ul`
  ${stylesList}
  display: none;

  ${mediaQuery[Screen.mobile]} {
    display: block;
  }
`;

export const List = styled.ul`
  display: block;
  ${stylesList}

  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;

export const CompanyLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${mediaQuery[Screen.mobile]} {
    order: 1;
  }
`;

export const SendolaLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    width: 140px;

    ${mediaQuery[Screen.mobile]} {
      font-size: 12px;
    }
  }

  ${mediaQuery[Screen.mobile]} {
    order: 2;
  }
`;

export const ContainerDisclaimer = styled.div`
  background-color: ${theme("Neutral_900")};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;

  a {
    color: ${theme('BackgroundBlue')}
  }

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
  }
`;