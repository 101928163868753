import { Color, RowBold, tTableInfo } from "../../Models/TableInfo";
import {
  TableInfoColumn,
  TableInfoRow,
  TableInfoStyled,
  Text,
  Title,
} from "./TableInfo.styled";

const TableInfo = (props: tTableInfo) => {
  const { data } = props;

  return (
    <TableInfoStyled>
      {data.map((row, index) => {
        const { bold, color } = row;

        let fontWeightLeft = 400;
        let fontWeightRight = 400;

        switch (bold) {
          case RowBold.LEFT:
            fontWeightLeft = 600;
            fontWeightRight = 400;
            break;
          case RowBold.RIGHT:
            fontWeightLeft = 400;
            fontWeightRight = 600;
            break;
          case RowBold.ROW:
            fontWeightLeft = 600;
            fontWeightRight = 600;
            break;
          case RowBold.NONE:
            fontWeightLeft = 400;
            fontWeightRight = 400;
            break;
          default:
            fontWeightLeft = 400;
            fontWeightRight = 400;
        }

        let colorText = "black";

        switch (color) {
          case Color.WHITE:
            colorText = "black";
            break;
          case Color.SUCCESS:
            colorText = "success";
            break;
          case Color.SOLID:
            colorText = "solid_light";
            break;
          default:
            colorText = "black";
        }

        return (
          <TableInfoRow key={index}>
            {row.type === "title" ? (
              <>
                <Title>
                  {row.label}
                  <br /> {row.label2}
                </Title>
              </>
            ) : (row.value || row.value2) &&  (
              <>
                <TableInfoColumn fontWeight={fontWeightLeft}>
                  {row.label}
                </TableInfoColumn>
                <TableInfoColumn
                  textAlign="right"
                  fontWeight={fontWeightRight}
                  color={colorText}
                >
                  {row.value2 ? <Text> {row.value2}</Text> : ""}
                  {row.value}
                </TableInfoColumn>
              </>
            )}
          </TableInfoRow>
        );
      })}
    </TableInfoStyled>
  );
};

export default TableInfo;
