import styled from "styled-components";
import { theme } from "styled-tools";
import Text from "../../Components/Text";
export const QuoterCard = styled.div`
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  font-family: "Poppins";
  height: auto;
  max-width: 100%;
  padding: 24px;
  width: 100%;
  margin: 12px 0;

  button {
    margin: 0;
    margin-top: 16px;
  }
`;

export const TitleCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;

  span {
    color: ${theme("Background_02")};
    font-weight: 600;
    font-family: "Poppins";
    font-size: 22px;
    background: ${theme("Gradient_08")};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span.text-primary {
    color: ${theme("Foreground_Purple_01")};
  }
`;
export const SectionRecipientGets = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const SectionPrincing = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SectionFlag = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
  gap: 12px;
  margin-right: 22px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SectionFlagWithSelect = styled.div`
  display: flex;
  justify-content: end;
  align-items: baseline;
  flex-direction: row;
  margin-left: 13px;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
`;

export const DivisorLine = styled.div`
  background-color: ${theme("Background_02")};
  height: 1px;
  margin: 3px 0;
  width: 100%;
`;

export const HeadingOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const GlobalPaymentIconRounded = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  svg {
    border-radius: 50%;
  }
`;

export const GlobalPaymentFlagImageRounded = styled.div`
  margin-top: auto;
  img {
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }
`;

export const NotEmptyOptions = styled.div`
  font-weight: 500;
  font-family: "Poppins";
  font-size: 16px;
  text-align: center;
  margin: 8px 0;
`;

export const exchangeRateContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;
  div {
    height: 25px;
    padding: 0px 10px;
    background: rgba(110, 102, 255, 0.25);
  }

  p {
    font-size: 11px;
    font-weight: 600;
     margin-top: auto;
  margin-bottom: auto;
  }
`;

export const marginBottomContainer = styled.div`
margin-bottom: 16px;
`;

export const marginVerticalContainer = styled.div`
margin-top: 16px;
margin-bottom: 16px;
`;

export const CustomText = styled(Text)`
  font-family: Poppins;
  font-size: 10px;
`;

export const InfoMessage = styled.div`
  display: flex;
  align-item: flex-start;
  padding: 10px 5px;
  margin-top: 12px;
  color: ${theme("Primary_01")};
`;