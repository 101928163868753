import { ReactNode } from "react";

// Own Components
import { Column, Row } from "../../../../Components/Grid";
import Text from "../../../../Components/Text";
import Icon from "../../../../Components/Icon";

// Own Models
import { JustifyContent } from "../../../../Models/Column";

// Styles
import {
  LinkedAccountCardAmount,
  LinkedAccountRadioCardStyled,
  LinkedAccountContent,
  CursorPointerStyled
} from "../LinkedAccounts.styles";
import AccountRadio from "../../Components/AccountRadio";

type Props = {
  title: string;
  description: string;
  helperText: string;
  extras?: ReactNode;
  value: string;
  radioRef?: React.Ref<HTMLInputElement> | null;
  getValue: (value: React.ChangeEvent<HTMLInputElement>) => void;
  deleteAccount?: () => void;
};

const LinkedAccountCardRadio = ({
  title,
  description,
  helperText,
  value,
  radioRef,
  getValue,
  deleteAccount,
  extras,
}: Props) => (
  <Column span={12} as={LinkedAccountRadioCardStyled}>
    <Row>
      <Column span={1} p={2} pr={1}>
        <AccountRadio
          id={value}
          forwardRef={radioRef}
          name="paymentType"
          onChangeEvent={getValue}
        />
      </Column>
      <Column span={10} pt={2} pb={2}>
        <LinkedAccountContent>
          <div>
            <Text
              color="black"
              size={1}
              align="left"
              weight={600}
              margin="5px 0"
            >
              {title}
            </Text>
            <Text
              color="black"
              size={1}
              align="left"
              weight={400}
              margin="5px 0"
            >
              {description}
            </Text>
            <LinkedAccountCardAmount>
              <Text
                color="grey"
                size={1}
                align="left"
                weight={400}
                margin="5px 0"
              >
                {helperText}
              </Text>
            </LinkedAccountCardAmount>
            {extras}
          </div>
        </LinkedAccountContent>
      </Column>
      { deleteAccount && (
        <Column span={1} p={2} justifyContent={JustifyContent.center}>
          <CursorPointerStyled onClick={deleteAccount}>
            <Icon icon="trashGray" size="medium" color="gray" />
          </CursorPointerStyled>
        </Column>
      )}
    </Row>
  </Column>
);

export default LinkedAccountCardRadio;
