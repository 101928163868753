import { components, ValueContainerProps } from "react-select";
import { SelectOption } from "../../Models/Select";
import { TextOptionImageAvatar } from "./SearchSelect.styled";
import { FlexItem } from "../Flex/Flex.styled";

export const CustomValueContainer = ({
  children,
  ...props
}: ValueContainerProps<SelectOption | null>) => {
  return (
    <components.ValueContainer {...props}>
      <FlexItem alignItems="center">
        {!props.selectProps.menuIsOpen && (
          <>
            {props.hasValue && props?.getValue()?.[0]?.image && (
              <TextOptionImageAvatar
                width={props?.getValue()?.[0]?.avatarSize?.width}
                height={props?.getValue()?.[0]?.avatarSize?.height}
                src={props?.getValue()?.[0]?.image}
              />
            )}
          </>
        )}
        {children}
      </FlexItem>
    </components.ValueContainer>
  );
};
