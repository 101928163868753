import { Link } from "react-router-dom";
import styled from "styled-components";
import { handleSizeType } from "../../Utilities/Sizes";

export const LinkStyled = styled(Link)<{ variant: string; size: number }>`
  color: ${(props) =>
    props.variant === "white"
      ? props.theme.Background_01
      : props.theme.Background_02};
  cursor: pointer;
  font-family: Poppins;
  padding: 0 3px;
  font-size: ${(props) => (props.size ? handleSizeType(props.size) : "12px")};
  text-decoration: ${(props) =>
    props.variant === "white" || props.variant === "none" ? "none" : "underline"};

  &:hover {
    text-decoration: ${(props) =>
      props.variant === "white" ? "underline" : "none"};
  }
`;
