import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";

import { AppDispatch, State } from "../../Redux/Store";
import { selectorLanguage } from "../../Redux/Translate";
import { getCellphoneDetailsAction } from "../../Redux/CellphoneDetails/CellphoneDetails.actions";

import { CellphoneDataDetails, PayItems } from "../../Models/Cellphone";

import { formatDate, formatDateToDDMMYY } from "../../Utilities/FormatDate";
import CurrencyService from "../../Services/CurrencyService";

import { MyCelularExternalServices, MyCelularExternalServicesEn } from "../../Constants/LinksExternals";
import { CELLPHONE_NEXT, CELLPHONE_PAID, GET_CELLPHONE_DETAIL_FULFILLED } from "../../Constants/Cellphone";

import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Text from "../../Components/Text";
import { Flex } from "../../Components/Flex";
import StepsRoutes from "../../Components/StepsRoutes";
import Icon from "../../Components/Icon";
import Button from "../../Components/Button";
import ServicesCard from "../../Components/ServicesCard";
import PaymentItemComponent from "../../Components/PaymentItem";
import LoaderIcon from "../../Components/LoaderIcon";

import { BtnCollapse, ContainerManage, ManageCard, PaymentItem } from "./ManagePayments.styles";

import Star from "../../Assets/Img/start.png";
import SendolaCel from "../../Assets/Img/sendola_cel.png";
import { CellphoneWhatsAppLinkEn, CellphoneWhatsAppLinkEs } from "../../Constants/ConfigureApi";

const ManagePayments = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const lang = useSelector(selectorLanguage);
  const cellphoneId = useSelector((state: State) => state.getCellphone).creditCellphoneId

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);
  const [cellphoneData, setCellphoneData] = useState<CellphoneDataDetails>();
  const [showMore, setShowMore] = useState(false);
  const [filterInstallments, setFilterInstallments] = useState<[PayItems]>();

  const targetRef = useRef<HTMLDivElement>(null);

  const urlMyCelular = lang.language === "en"
    ? MyCelularExternalServicesEn
    : MyCelularExternalServices;
  
  const urlCellphoneWhatsApp = lang.language === "en"
    ? CellphoneWhatsAppLinkEn
    : CellphoneWhatsAppLinkEs;
  
  const locLang = lang.language === "en"
    ? "en-US"
    : "es-ES";

  const getCellphoneDetailsData = async(creditId: string) => {
    setIsLoading(true);
    const response = await dispatch(getCellphoneDetailsAction(creditId));

    if (response?.type === GET_CELLPHONE_DETAIL_FULFILLED) {
      setCellphoneData(response.payload)
      setIsLoading(false);

      const payIndex = response.payload.installments.findIndex((item:  { status: string; }) => item.status === CELLPHONE_NEXT);

      const filterPayments = payIndex !== -1 ? response.payload.installments.slice(Math.max(0, payIndex - 1), payIndex + 2) : [];

      setFilterInstallments(filterPayments);
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  }

  const showAllDetails = () => {
    targetRef.current?.scrollIntoView({ behavior: 'smooth' });
    setShowMore(!showMore);
  }

  useEffect(() => {
    if (cellphoneId) {
      getCellphoneDetailsData(cellphoneId);
    }
  }, [cellphoneId])

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout textLink={t("buttons.back")} path="/" show={!isLoading}>
        <Container as={ContainerManage}>
          <Row>
            {isError && (
              <Column span={12} mb={2}>
                <Text size={0.5} align="center" weight={600} color="error">
                  {t("404.subtitle")}
                </Text>
              </Column>
            )}
            <Column span={12}>
              <Text size={2} weight={600} color="Dark_02" align="left">
                {t("ManagePayments.title")}
              </Text>
            </Column>
            <Column span={12}>
              <ManageCard>
                <Row>
                  <Column span={12}>
                    <Text size={1} weight={500} color="Dark_02" margin={0}>
                      {cellphoneData?.phoneModel}
                    </Text>
                    <Text size={0} color="grey" margin={"0"}>
                      {t("ManagePayments.acquisition")} {cellphoneData?.adquisitionDate ? formatDateToDDMMYY(cellphoneData?.adquisitionDate) : ""}
                    </Text>
                  </Column>
                  <Column span={12} my={1}>
                    <img src={cellphoneData?.image} alt={cellphoneData?.phoneModel} height={200} width={172} />
                  </Column>
                  <Column span={12}>
                    <Flex justifyContent="center">
                      <Text size={0} color="grey" margin={"0 3px"}>
                        {t("ManagePayments.total")}
                      </Text>
                      <Text size={0} color="black_highlight" margin={"0 3px"} weight={600}>
                        {`$ ${CurrencyService.formatNumberString(cellphoneData?.totalPrice.toString() || "")}`}
                      </Text>
                      <Text size={0} color="grey" margin={"0 3px"}>
                        {t("ManagePayments.usd")}
                      </Text>
                    </Flex>
                  </Column>
                  <Column span={12} ml={2} mr={1}>
                    <StepsRoutes totalSteps={cellphoneData?.totalCollected ? cellphoneData?.totalCollected : 0} stepIndex={cellphoneData?.payments ? cellphoneData?.payments : 0} color="Success_Green" />
                  </Column>
                  <Column span={12} mx={1} mt={2} ref={targetRef}>
                    <Flex justifyContent="space-between">
                      <div>
                        <Text size={0} color="grey" margin={"0"} align="left">
                          {t("ManagePayments.paid")}
                        </Text>
                        <Text size={1} color="black_highlight" margin={"0"} weight={600} align="left">
                          {`$ ${CurrencyService.formatNumberString(cellphoneData?.paid.toString() || "")}`}
                        </Text>
                        <Text size={0} color="grey" margin={"0"} align="left">
                          {t("ManagePayments.usd")}
                        </Text>
                      </div>
                      <div>
                        <Text size={0} color="grey" margin={"0"} align="right">
                          {t("ManagePayments.remaining")}
                        </Text>
                        <Text size={1} color="black_highlight" margin={"0"} weight={600} align="right">
                          {`$ ${CurrencyService.formatNumberString(cellphoneData?.remaining.toString() || "")}`}
                        </Text>
                        <Text size={0} color="grey" margin={"0"} align="right">
                          {t("ManagePayments.usd")}
                        </Text>
                      </div>
                    </Flex>
                  </Column>
                  <Column span={12} mt={3} mb={2}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text size={1} color="black_highlight" margin={"0"} align="left" weight={600}>
                        {t("ManagePayments.schedule")}
                      </Text>
                      <Flex>
                        <Text size={0} color="grey" margin={"0 3px"} align="right">
                          {`${cellphoneData?.payments}/${cellphoneData?.totalCollected}`}
                        </Text>
                        <Text size={0} color="grey" margin={"0"} align="right">
                          {t("ManagePayments.pay")}
                        </Text>
                      </Flex>
                    </Flex>
                  </Column>
                  {cellphoneData?.payments === cellphoneData?.totalCollected &&
                    <Column span={12} my={2}>
                      <Text size={2} color="Green_06" margin={"0"} weight={600}>
                        {t("ManagePayments.congratulations")}
                      </Text>
                      <Text size={0.5} color="Dark_03" margin={"0"}>
                        {t("ManagePayments.PayComplete")}
                      </Text>
                    </Column>
                  }
                  <Column span={12}>
                    {!showMore && <PaymentItem $type={"success"} />}
                    {cellphoneData?.installments && showMore &&
                      cellphoneData.installments.map(itemPay => {
                        return (
                          <PaymentItemComponent
                            key={itemPay.paymentNumber}
                            title={itemPay.date ? formatDate(itemPay.date, locLang) : ""}
                            subtitle={`${itemPay.paymentNumber} ${t("ManagePayments.of")} ${cellphoneData.totalCollected}`}
                            text={itemPay.status === CELLPHONE_PAID ? t("ManagePayments.paid1") : itemPay.status === CELLPHONE_NEXT ? t("ManagePayments.nextPay") : t("ManagePayments.pending")}
                            pay={`$ ${CurrencyService.formatNumberString(itemPay.payment.toString() || "")}`}
                            type={itemPay.status === CELLPHONE_PAID ? "success" : itemPay.status === CELLPHONE_NEXT ? "primary" : "disabled"}
                          />
                        )
                      })
                    }
                    {!showMore && filterInstallments && (cellphoneData?.payments !== cellphoneData?.totalCollected) &&
                      filterInstallments.map(itemPay => {
                        return (
                          <PaymentItemComponent
                            key={itemPay.paymentNumber}
                            title={itemPay.date ? formatDate(itemPay.date, locLang) : ""}
                            subtitle={`${itemPay.paymentNumber} ${t("ManagePayments.of")} ${cellphoneData?.totalCollected}`}
                            text={itemPay.status === CELLPHONE_PAID ? t("ManagePayments.paid1") : itemPay.status === CELLPHONE_NEXT ? t("ManagePayments.nextPay") : t("ManagePayments.pending")}
                            pay={`$ ${CurrencyService.formatNumberString(itemPay.payment.toString() || "")}`}
                            type={itemPay.status === CELLPHONE_PAID ? "success" : itemPay.status === CELLPHONE_NEXT ? "primary" : "disabled"}
                          />
                        )
                      })
                    }
                    {!showMore && cellphoneData?.payments !== cellphoneData?.totalCollected && <PaymentItem $type={"disabled"} />}
                  </Column>
                  <Column span={12}>
                    <BtnCollapse onClick={showAllDetails}>
                      <Icon icon={showMore ? "chevronUp" : "chevronDown"} size="medium" color="Primary01" />
                      <Text size={0.5} color="Primary01" margin={"0"} align="right" weight={600}>
                        {showMore ? t("ManagePayments.collapse") : t("ManagePayments.more")}
                      </Text>
                    </BtnCollapse>
                  </Column>
                </Row>
              </ManageCard>
            </Column>
            {cellphoneData?.payments === cellphoneData?.totalCollected &&
              <Column span={12} mt={2}>
                <ServicesCard
                  bgColor="Gradient_02"
                  icon="deviceMobile"
                  title={t("banners.bannerPhone.title")}
                  message={t("banners.bannerPhone.message")}
                  onClickBtn={() => (window.location.href = urlMyCelular)}
                  imageTitle={SendolaCel}
                  widthImageTitle={134}
                  heightImageTitle={59}
                  imgSubtitle={Star}
                  notification={
                    <>
                      <Text size={1} color="white" margin={"0"} align="left" weight={500}>
                        {t("ManagePayments.bannerPhoneText")}
                      </Text>
                    </>
                  }
                  buttonText={t("ManagePayments.bannerButton")}
                />
              </Column>
            }
            <Column span={12} mt={2} mb={6}>
              <Button
                text={t("ManagePayments.contact")}
                variant="primary"
                sizeButton="xlarge"
                sizeText="small"
                onClick={() => (window.location.href = urlCellphoneWhatsApp)}
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default ManagePayments
