import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";
import { MESSAGES, createVeriffFrame } from "@veriff/incontext-sdk";

// own states of redux
import { AppDispatch, State } from "../../../Redux/Store";
import { VeriffIDV } from "../../../Redux/Veriff/Veriff.actions";
import { OnboardingSliceSelector, setSteps } from "../../../Redux/Onboarding/Onboarding.slice";

// Own models
import { JustifyContent } from "../../../Models/Column";
import { Step } from "../../../Models/Onboarding";

// Own constants
import { VERIFF_IDV_URL_FULFILLED, VERIFF_IDV_URL_REJECTED } from "../../../Constants/Veriff";

// Own styles
import { StepsContainer } from "../LegalName/LegalName.styled";

// OWn components
import Layout from "../../../Components/Layout";
import { BottomFixedContainer, Column, FillerContainer, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import Icon from "../../../Components/Icon";
import Title from "../../../Components/Title";
import isEmpty from "lodash.isempty";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

const VeriffPage = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const OnboardingSelector = useSelector(OnboardingSliceSelector);

  const requiredOnboarding = useMemo(() =>
    OnboardingSelector.data?.requiredOnboarding
  ,[OnboardingSelector]);

  const handleOpenIframeVeriff = (urlVeriff:string) => {
    createVeriffFrame({
      url: urlVeriff,
      onEvent: function (msg: string) {
        switch (msg) {
          case MESSAGES.FINISHED:
            dispatch(setSteps({
              lastStep: Step.idv,
              nextStep: null
            }));
            navigate("/kyc-submit-kyc");
            break;
          case MESSAGES.CANCELED:
            dispatch(setSteps({
              lastStep: Step.personEmail,
              nextStep: Step.selectIdvFlow
            }));
            window.location.href = "/kyc-ssn";
            break;
        }
      },
    });
  };

  const GetVeriffURLIDV = async () => {
    const response = await dispatch(VeriffIDV());

    if(response?.type === VERIFF_IDV_URL_FULFILLED) {
      if (!isEmpty(response?.payload?.url)) {
        handleOpenIframeVeriff(response?.payload?.url); 
      } else {
        if (["approved", "submitted"].includes(response.payload?.status)) {
          dispatch(setSteps({
            lastStep: Step.idv,
            nextStep: null
          }));
          navigate("/kyc-submit-kyc");
          return
        }

        setIsError(true);
      }
    }

    if(response?.type === VERIFF_IDV_URL_REJECTED) {
      if (requiredOnboarding) {
        setIsError(true);
      } else {
        window.location.href = "/";
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    GetVeriffURLIDV();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout show={!isLoading && isError}>
        <FillerContainer as={StepsContainer}>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} mt={5}>
              <Title
                size={4}
                text={t("visaIdentity.titleMessage")}
                color="black"
                align="center"
                tagName="h1"
              />
            </Column>
            <Column span={12} justifyContent={JustifyContent.center} my={2}>
              <Icon icon="alertWarning" size="xlarge"/>
            </Column>
            <Column span={12} mb={5}>
              <Text size={18} align="center" weight={600} color="grey">
                {t("visaIdentity.messageInvalidDocuments")}
              </Text>
            </Column>

            <BottomFixedContainer>
              <Row>
                <Column span={12}>
                  <Button
                    type="submit"
                    variant="primary"
                    text={t("buttons.tryAgain")}
                    onClick={() => navigate("/kyc-ssn")}
                    sizeText="medium"
                  />
                </Column>
                <Column span={12}>
                  <Button
                    type="submit"
                    variant="outline"
                    text={t("buttons.contactSupport")}
                    onClick={() => navigate("/contact-us")}
                    sizeText="medium"
                  />
                </Column>
              </Row>
            </BottomFixedContainer>
          </Row>
        </FillerContainer>
      </Layout>
    </ThemeProvider>
  );
};

export default VeriffPage;
