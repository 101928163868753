import { roundButtonProps } from "../../Models/buttonInterface";
import Icon from "../Icon";
import Text from "../Text";

import {
  ContainerButton,
  RoundBtn
} from "./RoundButton.styled";

type RoundedButtonProps = roundButtonProps & {
  isDisabled?: boolean;
};

const RoundButton = ({
  icon,
  colorIcon,
  background,
  text,
  onClick,
  fillIcon= false,
  padding,
  sizeButton,
  sizeIcon,
  colorLabel,
  fillTransparent,
  isDisabled
}: RoundedButtonProps) => {

  return (
    <ContainerButton>
      <RoundBtn 
        $type={background} 
        onClick={onClick} 
        $fillIcon={fillIcon} 
        $padding={padding} 
        $sizeButton={sizeButton}
        disabled={isDisabled}
      >
        <Icon
          icon={icon}
          color={colorIcon}
          size={sizeIcon ? sizeIcon : "large"}
          fillTransparent={fillTransparent}
          fillIcon={fillIcon}
        />
      </RoundBtn>
      {text &&
        <Text
          weight={600}
          size={0}
          margin={0}
          color={colorLabel ? colorLabel : "black"}
        >
          {text}
        </Text>
      }
    </ContainerButton>
  )
}

export default RoundButton