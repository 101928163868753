import styled, { css, keyframes } from "styled-components";
import { ifProp } from "styled-tools";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled.div<{ full?: boolean }>`
  position: ${ifProp("full", "fixed", "relative")};
  text-align: center;
  ${ifProp("full",
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1099;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.5);
    `
  )}
`;

export const LoaderStyled = styled.div`
  width: 60px;
  height: 60px;
  border: 0.5em solid ${(props) => props.theme.Primary_04_highlight};
  border-left-color: ${(props) => props.theme.Primary_01};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const Percent = styled.span<{ $msg: boolean }>`
  position: absolute;
  top: ${(props) => (props.$msg ? "30%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
`;
