export interface KYC_Data_Results_Object {
  status: string | null;
  url: string | null;
  hasAttemps: boolean | null;
}

export interface KYC_Data_Results {
  idv?: KYC_Data_Results_Object;
  passport?: KYC_Data_Results_Object;
  pep?: KYC_Data_Results_Object;
  ssn?: KYC_Data_Results_Object;
  visa?: KYC_Data_Results_Object;
  fullAuto?: KYC_Data_Results_Object;
}

export interface KYC_Data {
  status: string;
  results?: KYC_Data_Results | null;
  level: number | null;
}

export interface KYCState {
  loading: boolean;
  error: string | null;
  data: KYC_Data | null;
}

export interface KYC_Status_Interface {
  personId: string;
  path?: string;
}

export const Initial_Results = {
  idv: {
    status: null,
    url: null,
    hasAttemps: null
  },
  passport: {
    status: null,
    url: null,
    hasAttemps: null
  },
  pep: {
    status: null,
    url: null,
    hasAttemps: null
  },
  ssn: {
    status: null,
    url: null,
    hasAttemps: null
  },
  visa: {
    status: null,
    url: null,
    hasAttemps: null
  },
  fullAuto: {
    status: null,
    url: null,
    hasAttemps: null
  }
};