import { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";

// Styles
import {
  CompanyLogoContainer,
  Container,
  Content,
  Linear,
  List,
  ListMobile,
  Logocontent,
  ContainerDisclaimer,
} from "./Footer.styled";

// Own Components
import ConstructionPopUp from "../ConstructionPopUp";
import Link from "../Link";
import Text from "../Text";
import { Column } from "../Grid";

// Own Assets
import Logofooter from "../../Assets/Img/logo-sendola.png";

const Footer = () => {
  const [t] = useTranslation("global");
  const [isShowPopup, setIsShowPopup] = useState(true);
  const handlePopUp = () => setIsShowPopup(!isShowPopup);

  return (
    <>
      <Container>
        <Content>
          <List>
            <li>
              <Link href="/disclosures" variant="white" size={1}>
                {t("footer.Section1.0")}
              </Link>
            </li>
            <li>
              <Link href="/disclosures" variant="white" size={1}>
                {t("footer.Section1.1")}
              </Link>
            </li>
          </List>
          <List>
            <li>
              <Link href="" onClick={handlePopUp} variant="white" size={1}>
                {t("footer.Section2.0")}
              </Link>
            </li>
            <li>
              <Link href="/contact-us" variant="white" size={1}>
                {t("footer.Section2.2")}
              </Link>
            </li>
          </List>
          <ListMobile>
            <li>
              <Link href="/disclosures" variant="white" size={1}>
                {t("footer.Section1.0")}
              </Link>
            </li>
            <li>
              <Link href="/disclosures" variant="white" size={1}>
                {t("footer.Section2.0")}
              </Link>
            </li>
            <li>
              <Link href="" onClick={handlePopUp} variant="white" size={1}>
                {t("footer.Section1.1")}
              </Link>
            </li>
            <li>
              <Link href="/contact-us" variant="white" size={1}>
                {t("footer.Section2.2")}
              </Link>
            </li>
          </ListMobile>
        </Content>
        <Logocontent>
          <CompanyLogoContainer>
            <img src={Logofooter} alt="Sendola-2023" />
          </CompanyLogoContainer>
        </Logocontent>
      </Container>
      <ContainerDisclaimer>
        <Column hiddenMobile>
          <Text 
            size={0.5} 
            color="white" 
            weight={500} 
            align="justify"
            margin="16px 20px 64px 20px"
          >
            <span dangerouslySetInnerHTML={{ __html: t("footer.disclaimer") }} />
          </Text>
        </Column>
        <Column hiddenDesktop>
          <Text 
            size={0} 
            color="white" 
            weight={500} 
            align="justify"
            margin="0 24px 64px 24px"
          >
            <span dangerouslySetInnerHTML={{ __html: t("footer.disclaimer") }} />
          </Text>
        </Column>
      </ContainerDisclaimer>
      <ConstructionPopUp handlePopUp={handlePopUp} isPopout={isShowPopup} />
      <Linear />
    </>
  );
};

export default withTranslation("ns")(Footer);
