import {
  BaseSyntheticEvent,
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Controller, useForm } from "react-hook-form";
// Own states
import { AppDispatch, State } from "../../Redux/Store";
import { ByUserSliceSelector } from "../../Redux/ByUser/ByUser.slice";
import { UpdateDirectDepositFeature } from "../../Redux/CheckDirectDeposit/CheckDirectDeposit.actions";
import {
  postRequestDirectDepositAction,
} from "../../Redux/Payroll/PayrollData.actions";

// Own styles
import {
  AuthorizationCard,
  AuthorizationRow,
  CustomText,
  InfoCard,
  InfoMessage,
  LinkText,
  PayrollCheckbox,
  StepsContainer,
} from "./PayrollSetting.styled";

// Own models
import { AlignItems } from "../../Models/Column";
import {
  IdTypeOptions,
  PaymentTypeOptions,
  PayrollAmountOptions,
  PayrollForm,
} from "../../Models/PayrollData";
// Own components
import { Row, Column, FillerContainer } from "../Grid";
import Layout from "../Layout";
import Button from "../Button";
import Icon from "../Icon";
import { Flex } from "../Flex";
import Title from "../Title";
import StepsRoutes from "../StepsRoutes";
import Text from "../../Components/Text";
import ReactSelect from "../../Components/ReactSelect";
import Input from "../Input";
import ContactSupportModal from "../../Pages/ConfigUser/AccountDetails/ContactSupportModal.component";

// Constants
import { DIRECT_DEPOSIT_REQUEST_FEATURE } from "../../Constants/Features";
import {
  POST_REQUEST_DIRECT_DEPOSIT_FULFILLED,
  POST_REQUEST_DIRECT_DEPOSIT_REJECTED,
  UPDATE_DIRECT_DEPOSIT_FEATURE_FULFILLED,
  UPDATE_DIRECT_DEPOSIT_FEATURE_REJECTED,
} from "../../Constants/Payroll";
import {
  allowedEmail,
  allowedNumbers,
  allowedSsnItin,
} from "../../Constants/Regexp";
//Utils
import { PrefilledDataSelector } from "../../Redux/PrefilledData/PrefilledData.slice";
import { maskSsn } from "../../Utilities/String";
import RadioCustom from "../RadioCustom";
import CurrencyInput from "../CurrencyInput";
export enum VerificationPayrollPercentage {
  Full = "payrollFull",
  Percentage = "payrollPercentage",
}

type Props = {
  show?: boolean;
  onClose: () => void;
  onCloseModal: () => void;
};

function PayrollSetting({ show, onClose, onCloseModal }: Props) {
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: State) => state.theme);
  const [isAcceptPayroll, setAcceptPayroll] = useState(false);
  const [isAcceptPayrollSign, setAcceptPayrollSign] = useState(false);
  const [isAcceptMaxPercentage, setAcceptMaxPercentage] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const minAmount = 100;
  const [paymentTypeSelected, setPaymentTypeSelected] = useState<
    string | undefined
  >();
  const [idTypeSelected, setIdTypeSelected] = useState<string | undefined>();
  const [amountTyped, setAmountTyped] = useState<number | undefined>();
  const [payrollAmountOption, setPayrollAmountOption] = useState<
    PayrollAmountOptions | undefined
  >();

  const ByUser = useSelector(ByUserSliceSelector);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const userId = useMemo(() => ByUser.data?.userId || "", [ByUser]);
  const personId = useMemo(() => ByUser.data?.personId || "", [ByUser]);

  const paymentTypes = [
    {
      value: PaymentTypeOptions.check,
      label: t("Payroll.paymentType.check"),
    },
    {
      value: PaymentTypeOptions.depositToAccount,
      label: t("Payroll.paymentType.account"),
    },
  ];

  const idTypes = [
    {
      value: IdTypeOptions.ssn,
      label: t("Payroll.idType.ssn"),
    },
    {
      value: IdTypeOptions.employeeNumber,
      label: t("Payroll.idType.employeeNumber"),
    },
  ];

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<PayrollForm>({
    mode: "onChange",
  });

  useEffect(() => {
    fillSsn();
  }, []);

  const fillSsn = () => {
    if (PrefilledData && PrefilledData.data) {
      setValue("email", PrefilledData.data.email);
      const inputSSN = PrefilledData.data.socialSecurityNumber?.replace(
        /\D/g,
        ""
      );
      if (inputSSN) {
        const [formattedSSN] = maskSsn(inputSSN, null);
        setValue("ssn", formattedSSN);
      }
    }
  };

  const handleChangeAuth = (event: BaseSyntheticEvent) => {
    setAcceptPayroll(event.target.checked);
  };

  const handleChangeAuthSign = (event: BaseSyntheticEvent) => {
    setAcceptPayrollSign(event.target.checked);
  };

  const handleChangeMaxPercentage = (event: BaseSyntheticEvent) => {
    setAcceptMaxPercentage(event.target.checked);
  };

  const updateDirectDeposit = async () => {
    const payload = {
      userId,
      featureCode: DIRECT_DEPOSIT_REQUEST_FEATURE,
      isActive: false,
    };

    const response = await dispatch(UpdateDirectDepositFeature(payload));

    if (response?.type === UPDATE_DIRECT_DEPOSIT_FEATURE_FULFILLED) {
      window.location.href = "/";
    }
    if (response?.type === UPDATE_DIRECT_DEPOSIT_FEATURE_REJECTED) {
      onClose();
      onCloseModal();
    }
  };

  const onSubmit = async (data: PayrollForm) => {
    setIsLoading(true);
    const payload = {
      personId,
      percentage:
        data.paymentType === PaymentTypeOptions.check ||
        payrollAmountOption === PayrollAmountOptions.allPercentage
          ? "100"
          : data.percentage,
      isAccepted: true,
      employeeNumber: data.employeeNumber,
      email: data.email,
      paymentType: data.paymentType,
      amount: data.amount,
      socialSecurityNumber: !data?.ssn ? null : data.ssn.replace(/-/g, "")
    };
    const response = await dispatch(postRequestDirectDepositAction(payload));

    if (response?.type === POST_REQUEST_DIRECT_DEPOSIT_FULFILLED) {
      updateDirectDeposit();
    }
    if (response?.type === POST_REQUEST_DIRECT_DEPOSIT_REJECTED) {
      setShowError(true);
    }
    setIsLoading(false);
  };

  const handleSSNChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputSSN = event.target.value.replace(/\D/g, "");
    const cursorPosition = event.target.selectionStart;
    const [formattedSSN, newCursorPosition] = maskSsn(inputSSN, cursorPosition);
    setValue("ssn", formattedSSN);
    if (newCursorPosition && event?.target?.setSelectionRange)
      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
  };

  const handleChangeRadio = (event: BaseSyntheticEvent) => {
    const selectedOption = event.target.value;
    if (selectedOption === PayrollAmountOptions.allPercentage) {
      clearAmount();
    }
    setPayrollAmountOption(selectedOption);
  };

  const handleChangeAmount = (value: number) => {
    clearErrors("amount");
    trigger("amount");
    setAmountTyped(value);
    setValue("amount", value.toString());
    if (Number(value) < minAmount) {
      setError("amount", {
        type: "custom",
        message: t("Payroll.settings.minimumAmount"),
      });
      return;
    }
  };

  const clearAmount = () => {
    setAmountTyped(undefined);
    setValue("amount", undefined);
    clearErrors("amount");
  };

  const clearId = (value: string) => {
    setValue("ssn", undefined);
    setValue("employeeNumber", undefined);
    if (value === IdTypeOptions.ssn) {
      fillSsn();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout show={show}>
        <FillerContainer as={StepsContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Column span={12} mt={4}>
                <StepsRoutes totalSteps={8} stepIndex={7} />
              </Column>

              <Column span={12} mt={4} mb={2}>
                <Title
                  tagName="h2"
                  color="black"
                  align="left"
                  size={3}
                  text={t("Payroll.settings.title")}
                />
              </Column>

              <Column span={12}>
                <InfoCard>
                  <Icon icon="coinDocument" size="medium" />
                  <div>
                    <CustomText
                      tagName="p"
                      color={"black"}
                      align="left"
                      size={0.5}
                      margin="0 0 0 10px"
                      weight={600}
                    >
                      {t("Payroll.settings.subtitle")}
                    </CustomText>
                    <CustomText
                      tagName="p"
                      color={"grey"}
                      align="left"
                      size={0}
                      margin="0 0 5px 10px"
                      weight={400}
                    >
                      {t("Payroll.settings.description")}
                    </CustomText>
                  </div>
                </InfoCard>
              </Column>

              <Column span={12}>
                <Text
                  tagName="p"
                  color="grey"
                  align="left"
                  size={0.5}
                  margin="24px 0"
                >
                  {t("Payroll.settings.instruction")}
                </Text>
              </Column>
              <Column span={12}>
                <Controller
                  control={control}
                  name="idType"
                  rules={{
                    required: t("Forms.required"),
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <ReactSelect
                      inputRef={ref}
                      nameSelect="idType"
                      placeholder={t("Payroll.settings.selectIdType")}
                      textLabel=""
                      options={idTypes}
                      value={idTypes.find((c) => c.value === value)}
                      onChange={(val: { value: string; label: string }) => {
                        setIdTypeSelected(val?.value);
                        clearId(val?.value);
                        onChange(val?.value);
                      }}
                      errors={errors}
                    />
                  )}
                />
              </Column>
              {idTypeSelected &&
                idTypeSelected === IdTypeOptions.employeeNumber && (
                  <Column span={12} mt={2}>
                    <Input
                      label="employeeNumber"
                      typeInput="text"
                      textLabel={t(
                        "Payroll.settings.placeholderEmployeerNumber"
                      )}
                      maxLengthInput={11}
                      register={register}
                      errors={errors}
                      rules={{
                        required: t("Forms.required"),
                        pattern: {
                          value: allowedNumbers,
                          message: t("Forms.inputNumber"),
                        },
                      }}
                    />
                  </Column>
                )}
              {idTypeSelected && idTypeSelected === IdTypeOptions.ssn && (
                <Column span={12} mt={2}>
                  <Input
                    label={IdTypeOptions.ssn}
                    placeholderInput={t("personalData.ssn.placeholders.ssnUSA")}
                    typeInput="text"
                    inputMode="numeric"
                    textLabel={t("personalData.ssn.labelUSA")}
                    maxLengthInput={11}
                    disabledInput={
                      PrefilledData &&
                      PrefilledData.data !== null &&
                      PrefilledData.data.socialSecurityNumber !== undefined
                    }
                    register={register}
                    onChange={handleSSNChange}
                    errors={errors}
                    rules={{
                      required: t("Forms.required"),
                      pattern: {
                        value: allowedSsnItin,
                        message: t("Forms.inputSsn"),
                      },
                    }}
                  />
                </Column>
              )}
              <Column span={12}>
                <Text
                  tagName="p"
                  weight={600}
                  color="black"
                  align="left"
                  size={0.5}
                  margin="24px 0"
                >
                  {t("Payroll.settings.instructionPaymentType")}
                </Text>
              </Column>
              <Column span={12}>
                <Controller
                  control={control}
                  name="paymentType"
                  rules={{
                    required: t("Forms.required"),
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <ReactSelect
                      inputRef={ref}
                      nameSelect="paymentType"
                      placeholder={t("Payroll.settings.selectPaymentType")}
                      textLabel=""
                      options={paymentTypes}
                      value={paymentTypes.find((c) => c.value === value)}
                      onChange={(val: { value: string; label: string }) => {
                        setPaymentTypeSelected(val?.value);
                        clearAmount();
                        onChange(val?.value);
                      }}
                      errors={errors}
                    />
                  )}
                />
              </Column>
              {paymentTypeSelected &&
                paymentTypeSelected === PaymentTypeOptions.depositToAccount && (
                  <Column span={12} mt={3}>
                    <Row>
                      <Column span={12}>
                        <Text
                          tagName="p"
                          weight={600}
                          color="black"
                          align="left"
                          size={0.5}
                          margin="24px 0"
                        >
                          {t("Payroll.settings.howMuchReceive")}
                        </Text>
                      </Column>
                      <Column span={8}></Column>
                      <Column span={4}></Column>
                      <Column span={12}>
                        <RadioCustom
                          id={PayrollAmountOptions.allPercentage}
                          name="amountType"
                          selected={
                            payrollAmountOption ===
                            PayrollAmountOptions.allPercentage
                          }
                          onChangeEvent={handleChangeRadio}
                          text={t("Payroll.settings.allPercentage")}
                        />
                      </Column>
                      {payrollAmountOption &&
                        payrollAmountOption ===
                          PayrollAmountOptions.allPercentage && (
                          <Column span={12} ml={4}>
                            <CustomText
                              size={0.5}
                              color="black_highlight"
                              align="left"
                              weight={400}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t(
                                    "Payroll.settings.confirmMaxPercentageHtml"
                                  ),
                                }}
                              />
                            </CustomText>
                          </Column>
                        )}
                      <Column span={4} pt={2}>
                        <RadioCustom
                          id={PayrollAmountOptions.amount}
                          name="amountType"
                          selected={
                            payrollAmountOption === PayrollAmountOptions.amount
                          }
                          onChangeEvent={handleChangeRadio}
                          text={t("Payroll.settings.otherPercentage")}
                        />
                      </Column>
                      <Column span={8}>
                        {payrollAmountOption ===
                          PayrollAmountOptions.amount && (
                          <CurrencyInput
                            id="amount"
                            label="amount"
                            name="amount"
                            placeholder="$ 0.00"
                            prefix="$"
                            allowDecimals={false}
                            errors={errors}
                            onValueChange={handleChangeAmount}
                          />
                        )}
                      </Column>
                      {amountTyped && amountTyped > 100 && (
                        <Column span={12} pl={3}>
                          <InfoMessage>
                            <Icon
                              icon="circleInfoBlack"
                              size="medium"
                              color="Primary01"
                            />
                            <CustomText
                              tagName="p"
                              color={"Primary01"}
                              align="left"
                              size={0}
                              margin="0 0 5px 10px"
                              weight={500}
                            >
                              {t("Payroll.settings.sendolaInfoAmount")}
                            </CustomText>
                          </InfoMessage>
                        </Column>
                      )}
                    </Row>
                  </Column>
                )}
              {paymentTypeSelected &&
                paymentTypeSelected === PaymentTypeOptions.check && (
                  <Column span={12} mt={3} as={AuthorizationCard}>
                    <Row as={AuthorizationRow}>
                      <Column span={1}>
                        <PayrollCheckbox
                          id="payroll-all-percentage"
                          name="payrollAllPercentage"
                          value="acceptAllPercentage"
                          type="checkbox"
                          onChange={handleChangeMaxPercentage}
                        />
                      </Column>
                      <Column span={11} alignItems={AlignItems.flexStart}>
                        <label htmlFor="payroll-all-percentage">
                          <CustomText
                            tagName="span"
                            color={"black_highlight"}
                            align="left"
                            size={0.5}
                            margin="0"
                            weight={400}
                          >
                            {t("Payroll.settings.confirmMaxPercentage")}
                          </CustomText>
                        </label>
                      </Column>
                    </Row>
                  </Column>
                )}
              <Column span={12} mt={1} as={AuthorizationCard}>
                <Row as={AuthorizationRow}>
                  <Column span={1}>
                    <PayrollCheckbox
                      id="payroll-authorization"
                      name="payrollAuthorization"
                      value="acceptPayroll"
                      type="checkbox"
                      onChange={handleChangeAuth}
                    />
                  </Column>
                  <Column span={11} alignItems={AlignItems.flexStart}>
                    <label htmlFor="payroll-authorization">
                      <CustomText
                        tagName="span"
                        color={"black_highlight"}
                        align="left"
                        size={0.5}
                        margin="0"
                        weight={400}
                      >
                        {t("Payroll.settings.authorization")}
                      </CustomText>
                    </label>
                  </Column>
                </Row>
              </Column>
              <Column span={12} as={AuthorizationCard}>
                <Row as={AuthorizationRow}>
                  <Column span={1}>
                    <PayrollCheckbox
                      id="payroll-authorization-sign"
                      name="payrollAuthorization"
                      value="acceptPayrollSign"
                      type="checkbox"
                      onChange={handleChangeAuthSign}
                    />
                  </Column>
                  <Column span={11} alignItems={AlignItems.flexStart}>
                    <label htmlFor="payroll-authorization-sign">
                      <CustomText
                        tagName="span"
                        color={"black_highlight"}
                        align="left"
                        size={0.5}
                        margin="0"
                        weight={400}
                      >
                        {t("Payroll.settings.authorizationSign")}
                      </CustomText>
                    </label>
                  </Column>
                </Row>
              </Column>
              <Column span={12}>
                <Row>
                  <Column span={2} mt={3}>
                    <Icon
                      icon="squareMail"
                      size="xlarge"
                      height={55}
                      width={50}
                    />
                  </Column>
                  <Column span={10} mt={2}>
                    <Input
                      label="email"
                      typeInput="text"
                      textLabel={t("Payroll.settings.placeholderEmail")}
                      maxLengthInput={50}
                      register={register}
                      errors={errors}
                      rules={{
                        required: t("Forms.required"),
                        pattern: {
                          value: allowedEmail,
                          message: t("Forms.inputEmail"),
                        },
                      }}
                    />
                  </Column>
                </Row>
              </Column>

              <Column span={12} my={6} alignItems={AlignItems.stretch}>
                <Flex width="100%" flexDirection="column" alignItems="center">
                  <Button
                    variant={
                      !isValid ||
                      isLoading ||
                      !isAcceptPayroll ||
                      !isAcceptPayrollSign ||
                      (paymentTypeSelected === PaymentTypeOptions.check
                        ? !isAcceptMaxPercentage
                        : payrollAmountOption &&
                            payrollAmountOption ===
                              PayrollAmountOptions.allPercentage
                          ? false
                          : payrollAmountOption &&
                              payrollAmountOption ===
                                PayrollAmountOptions.amount
                            ? amountTyped === undefined ||
                              (amountTyped !== undefined &&
                                amountTyped < minAmount)
                            : true)
                        ? undefined
                        : "primary"
                    }
                    text={t("Payroll.settings.buttonText")}
                    loading={isLoading ? 1 : 0}
                    sizeText="medium"
                    sizeButton="xlarge"
                    disabled={
                      !isValid ||
                      isLoading ||
                      !isAcceptPayroll ||
                      !isAcceptPayrollSign ||
                      (paymentTypeSelected === PaymentTypeOptions.check
                        ? !isAcceptMaxPercentage
                        : payrollAmountOption &&
                            payrollAmountOption ===
                              PayrollAmountOptions.allPercentage
                          ? false
                          : payrollAmountOption &&
                              payrollAmountOption ===
                                PayrollAmountOptions.amount
                            ? amountTyped === undefined ||
                              (amountTyped !== undefined &&
                                amountTyped < minAmount)
                            : true)
                    }
                  />
                  <LinkText
                    onClick={() => onCloseModal()}
                  >
                    {t("Payroll.settings.linkText")}
                  </LinkText>
                </Flex>
              </Column>
            </Row>
          </form>
        </FillerContainer>
        <ContactSupportModal
          showModal={showError}
          titleText={t("Payroll.error.title")}
          infoText={t("Payroll.error.description")}
          closeModal={() => setShowError(false)}
        />
      </Layout>
    </ThemeProvider>
  );
}

export default PayrollSetting;
