export const handleSizeType = (size: number) => {
  switch (size) {
    case 12:
      return "72px";
    case 11:
      return "64px";
    case 10:
      return "56px";
    case 9:
      return "48px";
    case 8:
      return "44px";
    case 7:
      return "40px";
    case 6:
      return "36px";
    case 5:
      return "32px";
    case 4:
      return "28px";
    case 3:
      return "24px";
    case 2:
      return "20px";
    case 1.5:
      return "18px";
    case 1:
      return "16px";
    case 0.5:
      return "14px";
    case 0:
      return "12px";
    default:
      return "18px";
  }
};
