import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// Own states
import { State } from "../../../Redux/Store";
import { AlignItems, JustifyContent } from "../../../Models/Column";

// Own components
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Title from "../../../Components/Title";

// Own styles
import { StepsContainer } from "./Settings.styled";
import LinksList from "../../../Components/LinksList";

const Settings = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");

  const LegalLinks = [
    {
      "icon": "circleUser",
      "name": t("Settings.links.AccountDetails"),
      "url": "/account-details"
    },
    {
      "icon": "headset",
      "name": t("Settings.links.HelpAndSupport"),
      "url" : "/contact-us"
    },
    {
      "icon": "message",
      "name": t("Settings.links.Disclosures"),
      "url" : "/disclosures"
    },
    {
      "icon": "circleUser",
      "name": t("Settings.links.CloseAccount"),
      "url" : "/"
    },
  ];

  useEffect(() => {
    document.body.classList.remove('modal');
  },[]);

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/" textLink={t("buttons.back")}>
        <Container as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12}>
                  <Row>
                    <Column span={12}>
                      <Title
                        size={5}
                        text={t("Settings.title")}
                        color="black"
                        align="left"
                        tagName="h1"
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column span={12} xs={12} md={12} lg={12} xl={12}>
                      <LinksList data={LegalLinks}/>
                    </Column>
                    <Column span={12} xs={12} md={12} lg={12} xl={12} justifyContent={JustifyContent.flexEnd} alignItems={AlignItems.center} hiddenMobile>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default Settings;
