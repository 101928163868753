import styled from "styled-components";
import { theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

// Own components
import Link from "../../Components/Link";

export const ContainerBalanceCard = styled.div<{ $hasOverflow: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  background: ${theme("Background_01")};
  justify-content: space-between;
  gap: 8px;
  overflow-x: ${(props) => (props.$hasOverflow ? "auto" : "show")};
  padding-left: ${(props) => (props.$hasOverflow ? "5px" : "0")};
`;

export const BlockedView = styled.div`
  background-color: rgba(${theme("RGBA_01")}, 0.8);
  height: 2400px;
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  z-index: 8;
`;

export const HomeContainer = styled.div`
  max-width: 556px;
  min-width: auto;
  margin: auto;

  ${mediaQuery[Screen.tablet]} {
    padding-inline: 0;
  }

  ${mediaQuery[Screen.xlDesktop]} {
    padding-inline: 0;
  }
`;

export const RowMargin = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const HomeLinkAccount = styled(Link)`
  color: ${theme('Primary_01')};
  font-weight: 600;

  div {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
  }

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: ${theme('Primary_01')};
      stroke: ${theme('Primary_01')};
    }
  }
  
  span {
    margin-left: 2px;
  }
`;

export const TextNotification = styled.p<{$weight?: number, $margin?: string}>`
  color: ${theme('Content_UX_03')};
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: ${props => props.$weight ? props.$weight : 400};
  line-height: 10px;
  letter-spacing: -0.1px;
  text-align: left;
  margin: ${props => props.$margin ? props.$margin : 0};
`;

export const TextNotificationPaid = styled.p<{$weight?: number, $margin?: string}>`
  color: ${theme('Content_UX_03')};
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: ${props => props.$weight ? props.$weight : 600};
  line-height: 10px;
  letter-spacing: -0.1px;
  text-align: left;
  margin: ${props => props.$margin ? props.$margin : 0};
`;