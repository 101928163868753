/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import isEmpty from "lodash.isempty";
import Select, {
  GroupBase,
  SelectComponentsConfig,
  SelectInstance,
  StylesConfig
} from "react-select";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
//Own components
import Text from "../Text";
import { ErrorMessage } from "./ErrorMessage.component";
import { CustomOption } from "./CustomOptions.component";
import { CustomControl } from "./CustomControl.component";
import { CustomValueContainer } from "./CustomValueContainer.component";
import { CustomInput } from "./CustomInput.component";
//Theme
import { selectStyles, selectTheme } from "./SearchSelectTheme";
//Own styles
import { FlexContainer } from "../Flex/Flex.styled";
import { ContainerSelect } from "./SearchSelect.styled";
import { Placeholder } from "../Input/Input.styled";
//Own models
import { SearchSelectProps, SelectOption } from "../../Models/Select";

export const SearchSelect = ({
  selectRef,
  options,
  name,
  value,
  label,
  search,
  errors,
  labelProps = { color: "grey", size: 0 },
  async,
  cacheOptions = true,
  defaultOptions,
  showAvatar,
  customStyles,
  showAvatarOnValue,
  m = "0 0 10px 0",
  loadOptions,
  onBlur,
  onChange,
  defaultValueInput,
  placeholderInput,
  avatarSize,
}: SearchSelectProps) => {
  const { t } = useTranslation("global");
  const { getValues } = useFormContext() ?? {};
  const [focusInput, setFocusInput] = useState(false);
  const innerSelectRef = selectRef || useRef<SelectInstance<SelectOption | null> | null>(null);

  const components: Partial<
    SelectComponentsConfig<
      SelectOption<string> | null,
      boolean,
      GroupBase<SelectOption | null>
    >
  > = {
    Option: CustomOption,
    Control: CustomControl,
    Input: CustomInput,
    ...(showAvatarOnValue ? { ValueContainer: CustomValueContainer } : {}),
  };
  options = options.map((opt: SelectOption) => ({
    ...opt,
    showAvatar,
    avatarSize,
  })) as SelectOption[];

  const optionsAsync = options.map((opt: SelectOption) => ({
    ...opt,
    showAvatar,
    avatarSize,
  })) as SelectOption[];

  useEffect(() => {
    if (!value && getValues && name && !getValues(name)) {
      innerSelectRef.current?.clearValue();
    }
  }, [value, name, getValues]);

  return (
    <FlexContainer m={m} direction="column">
      {isEmpty(placeholderInput) && label && (
        <Text align="left" {...labelProps} weight={600} margin="0 0 10px 2px">
          {label}
        </Text>
      )}
      <ContainerSelect>
        
        {placeholderInput && (
          <Placeholder
            active={
              focusInput ||
              Boolean(value) ||
              Boolean(defaultValueInput) ||
              Boolean(getValues(label || "") || false)
            }
          >
            {placeholderInput}
          </Placeholder>
          
        )}
        {async && (
          <AsyncSelect
            ref={innerSelectRef as React.Ref<SelectInstance<SelectOption | null, boolean, GroupBase<SelectOption | null>>>}
            name={name}
            styles={
              { ...selectStyles, ...customStyles } as StylesConfig<SelectOption | null>
            }
            cacheOptions={cacheOptions}
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            components={components}
            onBlur={() => {
              onBlur;
              setFocusInput(false);
            }}
            value={value}
            onFocus={() => setFocusInput(true)}
            onChange={(newValue) => onChange(newValue as SelectOption)}
          />
        )}
        {!async && (
          <Select
            isSearchable={search}
            ref={innerSelectRef as React.Ref<SelectInstance<SelectOption | null, boolean, GroupBase<SelectOption | null>>>}
            styles={
              { ...selectStyles, ...customStyles } as StylesConfig<SelectOption | null>
            }
            theme={selectTheme}
            options={optionsAsync}
            name={name}
            placeholder={!placeholderInput && t("HomePage.GlobalPaymentsQuoter.selectPlaceholder")}
            onBlur={() => {
              onBlur;
              setFocusInput(false);
            }}
            onFocus={() => setFocusInput(true)}
            onChange={(newValue) => onChange(newValue as SelectOption)}
            value={value}
            components={components}
          />
        )}
      </ContainerSelect>
      {name && errors?.[name] && (
        <ErrorMessage>
          <>{errors?.[name]?.message}</>
        </ErrorMessage>
      )}
    </FlexContainer>
  );
};
