import styled, { css, keyframes } from 'styled-components';
import { theme } from "styled-tools";

interface StyledProps {
  circle?: boolean;
  height?: number;
  width?: number;
  defaultBaseColor?: string;
  defaultHighlightColor?: string;
  duration?: number;
}

export const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`

const Skeleton = styled.div<StyledProps>`
  border-radius: 4px;
  width: 100%;
  ${p => p.circle && css`
    border-radius: 50%;
  `}
  ${p => p.height && css`
    height: ${p.height}px;
  `}
  ${p => p.width && css`
    width: ${p.width}px;
  `}
  background-color: ${theme("Background_05")};
  background-image: linear-gradient(
    90deg,
    ${theme("Background_05")},
    ${theme("Background_06")},
    ${theme("Background_05")}
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: block;
  line-height: 1;
  animation: ${skeletonKeyframes} ${p => p.duration}s ease-in-out infinite;
  margin-bottom: 8px;
`

Skeleton.defaultProps = {
  width: undefined,
  height: undefined,
  duration: 1.2,
  circle: false,
}

export default Skeleton