import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const OverLay = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: ${(props) => (props.hidden ? "none" : "initial")};
  z-index: 9;
`;

export const ModalWrap = styled.div`
  margin: 70px auto;
  padding: 30px;
  background: ${(props) => props.theme.Background_01};
  border: 1px solid ${(props) => props.theme.Content_State_UX_Hover};
  width: 350px;
  border-radius: 45px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery[Screen.sm]} {
    width: 75%;
    max-width: 374px;
    padding: 45px 10px;
  }

  ${mediaQuery[Screen.mobile]} {
    width: 85%;
    max-width: 374px;
    padding: 45px 10px;
  }

  h1 {
    ${mediaQuery[Screen.mobile]} {
      width: 100%;
    }
  }

  p {
    ${mediaQuery[Screen.mobile]} {
      width: 100%;
    }
  }

  button {
    max-width: 263px;
  }
`;

export const HeadModal = styled.div`
  width: 100%;
  text-align: right;
  justify-content: end;
  display: flex;

  ${mediaQuery[Screen.sm]} {
    display: none;
  }

  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;
