import { useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isAndroid, isIOS } from "react-device-detect";

import { selectorLanguage } from "../../Redux/Translate";

// Models
import { BalanceCardProps } from "../../Models/BalanceCard";
import { JustifyContent } from "../../Models/Column";

// Utilities
import { FormatNumberToThousands } from "../../Utilities/FormatNumberToThousands";
import { CopyTextToClipboard } from "../../Utilities/CopyTextToClipboard";

// Constants
import { ZelleBannerEN, ZelleBannerES } from "../../Constants/LinksExternals";
import { urlExternalServices } from "../../Constants/ConfigureApi";
import { CHECKING } from "../../Constants/Account";

// Store
import { State } from "../../Redux/Store";

// Components
import Text from "../Text";
import { Flex } from "../Flex";
import Button from "../Button";
import RoundButton from "../RoundButton";
import { Column, Row } from "../Grid";
import Icon from "../Icon";

import {
  AlertBackground,
  AlertContainer,
  BalanceLink,
  Subtitle,
  TextInfo,
  BannerBankCardContainer,
  BannerBankCardRoundedBtn,
  BannerBankCardContainerBalance,
  BannerBankCardButton,
  BannerBankSendModeyButton,
  BannerBankIconCheck,
  BannerBankPayrollButton,
  BannerBankUnlinkedCard,
  BannerBankUnlinkedCardContent,
  UnlinkedBannerBankCardInfoLocked,
} from "./BalanceCard.styled";

import BannerBankSrc from "../../Assets/Img/banner_bank_logo.png";
import Popover from "../Popover";
import { WashingtonFruit, WashingtonFruitWh } from "../../Constants/Customers";

type BannerBankCardProps = BalanceCardProps & {
  isLinkedAccount: boolean | undefined;
  hasDirectDeposit?: boolean;
  isRelinkedAccount?: boolean | undefined;
  isUpdatedAccount?: boolean | undefined;
  onDirectDeposit: () => void;
  onLinkAccount: () => void;
  onOpenPlaid?: () => void | undefined;
  countrySubdivision?: string;
  handleShowNotification?: (confirm?: boolean) => void;
};

const BannerBankBalanceCard = ({
  title,
  amount,
  subtitle,
  href,
  showAlert = false,
  alertText = "",
  showButton,
  buttonText,
  buttonDisabled = false,
  onClick,
  alertClick,
  selectTypeAccount,
  active,
  accountNumber,
  sponsorBank,
  type,
  isLinkedAccount,
  isRelinkedAccount = false,
  isUpdatedAccount = false,
  hasDirectDeposit = false,
  onDirectDeposit,
  onLinkAccount,
  onOpenPlaid,
  countrySubdivision,
  handleShowNotification,
}: BannerBankCardProps) => {
  const [roundBalance, centsBalance]: string[] = useMemo(() => {
    if (amount) {
      const stringAmount = amount.toFixed(2);
      return stringAmount.split(".");
    } else {
      return ["0", "00"];
    }
  }, [amount]);

  const [t] = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const urlZelleBanner = lang.language === "en" ? ZelleBannerEN : ZelleBannerES;

  const ByUser = useSelector((state: State) => state.ByUser);

  const userId = useMemo(() => ByUser.data?.userId || "", [ByUser]);

  const handleClick = () => {
    if (href && active) location.href = href;
  };

  const handleAlertClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (alertClick) alertClick();
  };

  const handleButtonZelle = () => {
    const androidDeepLink =
      "intent://#Intent;scheme=zelle;package=com.zellepay.zelle;end";
    const iosDeepLink = "zelle://";

    if (isAndroid) {
      window.location.href = androidDeepLink;
    } else if (isIOS) {
      window.location.href = iosDeepLink;
    } else {
      window.open(urlZelleBanner, "_blank");
    }
  };

  const onSendMoney = () => {
    if (countrySubdivision === "PR") {
      return handleShowNotification && handleShowNotification();
    }

    () => (window.location.href = urlExternalServices);
  };

  return (
    <BannerBankCardContainer
      onClick={handleClick}
      $active={active ? true : false}
    >
      {isRelinkedAccount && (
        <BannerBankUnlinkedCard>
          <BannerBankUnlinkedCardContent>
            {!isUpdatedAccount ? (
              <>
                <Button
                  text={t("LinkedAccounts.link")}
                  variant="secondary"
                  sizeText={"small"}
                  sizeButton="small"
                  colorIcon="white"
                  onClick={() => {
                    if (onOpenPlaid) {
                      onOpenPlaid();
                    }
                  }}
                />
                <Text
                  size={0}
                  weight={500}
                  align="center"
                  color="white"
                  margin="0 0 5px 0"
                >
                  {t("LinkedAccounts.unlinkedAccount")}
                </Text>
              </>
            ) : (
              <Text
                size={0}
                weight={500}
                align="center"
                color="white"
                margin="0 0 5px 0"
              >
                {t("LinkedAccounts.updatedAccount")}
              </Text>
            )}
          </BannerBankUnlinkedCardContent>
        </BannerBankUnlinkedCard>
      )}

      <Flex justifyContent="space-between">
        <div>
          <img
            alt={sponsorBank}
            src={BannerBankSrc}
            height={33}
            style={{ maxWidth: "121px" }}
          />
        </div>
        <Icon
          icon={`${!isLinkedAccount || isRelinkedAccount ? "unLinkedAccount" : "linkedAccount"}`}
          size="medium"
          color="white"
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        as={BannerBankCardContainerBalance}
      >
        <>
          {!isLinkedAccount ? (
            <Button
              as={BannerBankCardButton}
              type="button"
              sizeButton="large"
              sizeText="small"
              text={t("Card.btnBannerBank")}
              variant="primary"
              onClick={() => onLinkAccount()}
              viewIcon={false}
              iconButtonLeft="linkedAccount"
              colorIcon="bannerBank"
            />
          ) : (
            <>
              {isRelinkedAccount ? (
                <UnlinkedBannerBankCardInfoLocked
                  $width="100px"
                  $margin="24px 0"
                />
              ) : (
                <>
                  <BalanceLink
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    onClick={active ? selectTypeAccount : () => {}}
                  >
                    <Flex>
                      <Text weight={500} size={5} margin={0} color="white">
                        ${FormatNumberToThousands(roundBalance)}
                      </Text>
                      <Text
                        weight={600}
                        size={roundBalance.length > 5 ? 0 : 1}
                        margin={"4px 0 0 0"}
                        color="white"
                      >
                        .{centsBalance}
                      </Text>
                    </Flex>
                  </BalanceLink>

                  <Flex justifyContent="center">
                    <Subtitle>{subtitle}</Subtitle>
                  </Flex>
                </>
              )}
            </>
          )}

          <Flex justifyContent="center" as={TextInfo}>
            <Text size={0} align="left" margin={0} color="white">
              {title}
            </Text>
            <Flex alignItems="center">
              <Text size={0} align="left" margin={"0 6px"} color="white">
                {accountNumber ? `***${accountNumber.slice(-4)}` : ""}
              </Text>
              <Popover content={t("Card.btnCopy")}>
                <Icon
                  icon="newCopy"
                  color="white"
                  fillIcon={false}
                  size="small"
                  onClick={() => CopyTextToClipboard(accountNumber)}
                />
              </Popover>
            </Flex>
          </Flex>
        </>
      </Flex>

      {type === CHECKING ? (
        <Flex as={BannerBankCardRoundedBtn} justifyContent="center">
          <BannerBankSendModeyButton>
            <Popover
              content={t("Card.disabledTextBannerBank")}
              contentSize={200}
              trigger="hover"
              isDisabled={!isLinkedAccount}
              isHidden={isLinkedAccount}
            >
              <RoundButton
                isDisabled={!isLinkedAccount}
                icon="send"
                colorIcon="bannerBank"
                fillIcon={false}
                background="light_gradient"
                text={t("buttons.sendMoney")}
                padding="8px"
                sizeButton="small"
                sizeIcon="medium"
                colorLabel="white"
                onClick={onSendMoney}
              />
            </Popover>
          </BannerBankSendModeyButton>
          <RoundButton
            isDisabled={!isLinkedAccount}
            icon="rightLeft"
            background="outline_light"
            text={t("Card.zelle")}
            onClick={handleButtonZelle}
            sizeButton="small"
            sizeIcon="medium"
            padding="6px"
          />
          {(WashingtonFruit === userId || WashingtonFruitWh === userId) && (
            <BannerBankPayrollButton>
              <Popover
                content={
                  <Flex alignItems="center">
                    <Icon
                      icon="checkCircleGreen2"
                      fillIcon={false}
                      size="medium"
                    />
                    <Text
                      size={0}
                      align="left"
                      margin="0 0 0 10px"
                      color="white"
                    >
                      {t("Card.popoverPayroll")}
                    </Text>
                  </Flex>
                }
                contentSize={135}
                trigger="hover"
                isDisabled={hasDirectDeposit}
                color="light"
                align="right"
              >
                <RoundButton
                  icon="payroll"
                  colorIcon="white"
                  background="outline_light"
                  text={t("Card.payroll")}
                  padding="6px"
                  sizeButton="small"
                  sizeIcon="medium"
                  colorLabel="white"
                  onClick={() => onDirectDeposit()}
                />
                {hasDirectDeposit && (
                  <BannerBankIconCheck>
                    <Icon
                      icon="checkCircleGreen2"
                      fillIcon={false}
                      size="small"
                    />
                  </BannerBankIconCheck>
                )}
              </Popover>
            </BannerBankPayrollButton>
          )}
        </Flex>
      ) : (
        <Row>
          <Column span={12} justifyContent={JustifyContent.center} mt={4}>
            <Button
              text={"Ver detalles"}
              variant="primary"
              sizeButton="medium"
              sizeText="small"
              onClick={active ? selectTypeAccount : () => {}}
            />
          </Column>
        </Row>
      )}

      <Flex justifyContent="center" width="100%">
        {showButton && buttonText && (
          <Button
            variant="outline"
            text={buttonText}
            disabled={buttonDisabled}
            sizeText="small"
            sizeButton="medium"
            onClick={onClick}
          />
        )}
      </Flex>

      {showAlert && (
        <>
          <AlertBackground />
          <AlertContainer onClick={handleAlertClick}>
            <Text size={1} weight={600} color="white">
              {alertText}
            </Text>
          </AlertContainer>
        </>
      )}
    </BannerBankCardContainer>
  );
};

export default BannerBankBalanceCard;
