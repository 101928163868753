import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";
import { MESSAGES, createVeriffFrame } from "@veriff/incontext-sdk";
import isEmpty from "lodash.isempty";

// own states of redux
import { AppDispatch, State } from "../../../Redux/Store";
import { VeriffIDV } from "../../../Redux/Veriff/Veriff.actions";
import { GetIdvIsRequiredAction } from "../../../Redux/IDVRequired/IDVRequired.actions";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { selectorLanguage } from "../../../Redux/Translate";

// Own models
import { JustifyContent } from "../../../Models/Column";

// Own constants
import { VERIFF_IDV_URL_FULFILLED, VERIFF_IDV_URL_REJECTED } from "../../../Constants/Veriff";
import { IDV_IS_REQUIRED_FULFILLED } from "../../../Constants/IDV";
import { WhatsAppLinkEn, WhatsAppLinkEs } from "../../../Constants/ConfigureApi";

// Own styles
import { StepsContainer } from "../../KYC/LegalName/LegalName.styled";

// OWn components
import Layout from "../../../Components/Layout";
import { BottomFixedContainer, Column, FillerContainer, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import Icon from "../../../Components/Icon";
import Title from "../../../Components/Title";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

const IdentityVerifyPage = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const lang = useSelector(selectorLanguage);
  const WhatsAppLink = lang.language === "en" ? WhatsAppLinkEn : WhatsAppLinkEs;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const ByUser = useSelector(ByUserSliceSelector);
  const personId = useMemo(() => ByUser.data?.personId || "", [ByUser]);

  const GetIdvISRequired = async (personId:string) => {
    const response = await dispatch(GetIdvIsRequiredAction(personId));

    if (response?.type === IDV_IS_REQUIRED_FULFILLED) {
      if(response?.payload?.hasAddress === false) {
        navigate("/kyc-personal-address");
      } else {
        window.location.href = "/submit-kyc";
      }
    }
  };

  const handleOpenIframeVeriff = (urlVeriff:string) => {
    createVeriffFrame({
      url: urlVeriff,
      onEvent: function (msg: string) {
        switch (msg) {
          case MESSAGES.FINISHED:
            GetIdvISRequired(personId);
            break;
          case MESSAGES.CANCELED:
            window.location.href = "/";
            break;
        }
      },
    });
  };

  const GetVeriffURLIDV = async () => {
    const response = await dispatch(VeriffIDV("fullauto"));

    if(response?.type === VERIFF_IDV_URL_FULFILLED) {
      if(response?.payload?.status === "approved") {
        GetIdvISRequired(personId);
      }

      if(!isEmpty(response?.payload?.url)) {
        handleOpenIframeVeriff(response?.payload?.url);
      } else {
        setIsError(true);
      }
    }

    if(response?.type === VERIFF_IDV_URL_REJECTED) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    GetVeriffURLIDV();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.titleOnboarding")}
          description={t("loaderText.subTitleOnboarding")}
        />
      </Layout>
      <Layout show={!isLoading && isError}>
        <FillerContainer as={StepsContainer}>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} mt={5}>
              <Title
                size={4}
                text={t("visaIdentity.titleMessage")}
                color="black"
                align="center"
                tagName="h1"
              />
            </Column>
            <Column span={12} justifyContent={JustifyContent.center} my={2}>
              <Icon icon="alertWarning" size="xlarge"/>
            </Column>
            <Column span={12} mb={5}>
              <Text size={18} align="center" weight={600} color="grey">
                {t("visaIdentity.messageInvalidDocuments")}
              </Text>
            </Column>

            <BottomFixedContainer>
              <Row>
                <Column span={12}>
                  <Button
                    type="submit"
                    variant="primary"
                    text={t("buttons.tryAgain")}
                    onClick={() => window.location.href = "/kyc-identity-veriff"}
                    sizeText="medium"
                    sizeButton="large"
                  />
                </Column>
                <Column span={12}>
                  <Button
                    type="submit"
                    variant="outline"
                    text={t("buttons.contactSupport")}
                    onClick={() => window.location.href = WhatsAppLink}
                    sizeText="medium"
                    sizeButton="large"
                  />
                </Column>
              </Row>
            </BottomFixedContainer>
          </Row>
        </FillerContainer>
      </Layout>
    </ThemeProvider>
  );
};

export default IdentityVerifyPage;
