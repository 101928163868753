
import { ThemeProvider } from 'styled-components';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { State } from "../../../Redux/Store";

import Button from '../../../Components/Button';
import Text from "../../../Components/Text";
import Layout from '../../../Components/Layout';
import { Container, Column, Row } from '../../../Components/Grid';

import ErrorIconSrc from "../../../Assets/Icons/error.svg";
import { PayrollCardButtons } from '../Components/PayrollCard/PayrollCard.styled';

export const PayrollDirectDepositError = () => {
  const theme = useSelector((state: State) => state.theme);
  const {t} = useTranslation("global");

  return (
    <ThemeProvider theme={theme}> 
      <Layout>
        <Container>
          <Row>
            <Column span={12} pt={6} pb={6}>
              <Row>
                <Column span={12} lg={7} xl={7} pb={1}>
                  <img src={ErrorIconSrc} />
                </Column>
                <Column span={12} lg={7} xl={7} pb={1} pt={2}>
                  <Text size={-1} color="black" weight="600" margin="0 0" align="center">
                    {t("Payroll.error.title")}
                  </Text>
                  <Text size={-1} color="grey" align="center">
                    {t("Payroll.error.description")}
                  </Text>
                </Column>
                <Column span={12} lg={7} xl={7} as={PayrollCardButtons}>
                  <Button
                    variant="primary"
                    sizeButton="xlarge"
                    sizeText="large"
                    text={t("Payroll.error.btnAccept")}
                  />
                  <Button
                    variant="outline"
                    sizeButton="xlarge"
                    sizeText="large"
                    text={t("Payroll.error.btnSupport")}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}
