import { useState, useMemo, BaseSyntheticEvent, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useForm } from "react-hook-form";
import { MaskSimpleSSN, maskSsn } from "../../../Utilities/String";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";

// Own states
import { AppDispatch, State } from "../../../Redux/Store";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { UpdateSsnSliceAction } from "../../../Redux/SocialSecurityNumber/SocialSecurityNumber.actions";
import { PersonalDataSliceSelector } from "../../../Redux/PersonalData/PersonalData.slice";
import { OnboardingSliceSelector, setSteps } from "../../../Redux/Onboarding/Onboarding.slice";
import { PrefilledDataSelector } from "../../../Redux/PrefilledData/PrefilledData.slice";
import { NationalitySelector } from "../../../Redux/Nationality";

// Own Styles
import { StepsContainer } from "../../KYC/LegalName/LegalName.styled";

// Own constants
import { allowedSsn, allowedSsnItin } from "../../../Constants/Regexp";
import { UPDATE_USER_SSN_FULFILLED, UPDATE_USER_SSN_REJECTED } from "../../../Constants/user";
import { DOCUMENT_CONFLICT } from "../../../Constants/SsnError";

// Own Models
import { VerificationMethodOptions } from "../../../Models/ShippingAddress";
import { Step } from "../../../Models/Onboarding";
import { SsnForm } from "../../../Models/SocialSecurityNumber";

// Own components
import { FillerContainer, Row, Column } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Layout from "../../../Components/Layout";
import Text from "../../../Components/Text";
import Input from "../../../Components/Input";
import Button from "../../../Components/Button";
import StepsRoutes from "../../../Components/StepsRoutes";
import Icon from "../../../Components/Icon";
import RadioCard from "../../../Components/RadioCard";

import { 
  CustomText, 
  SocialSecurityButtonsContainer, 
  SocialSecurityNumberInfoCard, 
  SocialSecurityNumberOpenMarketInfo, 
  SocialSecurityNumberOptional 
} from "./SocialSecurityNumber.styled";

// Assets
import { AlignItems, JustifyContent } from "../../../Models/Column";

export default function SocialSecurityNumber() {
  const [t] = useTranslation("global")
  const navigate = useNavigate();
  const theme = useSelector((state: State) => state.theme);
  const ByUser = useSelector(ByUserSliceSelector);
  const PersonalData = useSelector(PersonalDataSliceSelector);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const NationalityData = useSelector(NationalitySelector);
  const onboardingSteps = useSelector(OnboardingSliceSelector);
  const [nationalityUSA, setNationalityUSA] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const [authorizeWithSsn, setAuthorizeWithSsn] = useState<boolean>(onboardingSteps?.data?.nextStep === Step.ssn ? true : false);
  const [isRequiredSsn, setIsRequiredSsn] = useState<boolean>(false);
  const [verificationMethod, setVerificationMethod] = useState<VerificationMethodOptions>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isValid,
    }
  } = useForm<SsnForm>({
    mode: 'onChange'
  });

  const personId = useMemo(() =>
    PersonalData.data?.personId || ByUser.data?.personId,
  [PersonalData, ByUser]);

  const customerId = useMemo(() =>
    ByUser.data?.customerId || null,
  [ByUser]);

  const nationalityForm = useMemo(() =>
    NationalityData.nationality || null,
  [NationalityData]);

  const handleChangeRadio = (event: BaseSyntheticEvent) => {
    const itinRegex = /^9/g;

    if (
      (event.target.value === VerificationMethodOptions.VisaAndPassport) &&
      PrefilledData.data?.socialSecurityNumber && 
      itinRegex.test(PrefilledData.data?.socialSecurityNumber)
    ) {
      setValue("ssn", "", { shouldValidate: true });
    } else {
      getPreload();
    }

    setVerificationMethod(event.target.value)
  }

  const handleClick = async (data: SsnForm) => {
    let responseSSN = null;

    if (authorizeWithSsn) {

      if (!personId) {
        setIsError(true);
        return
      }

      setIsError(false);
      setIsLoading(true);

      responseSSN = await dispatch(
        UpdateSsnSliceAction({
          personId,
          ssn: !data?.ssn ? null : data.ssn.replace(/-/g, "")
        })
      )

      if (responseSSN?.type === UPDATE_USER_SSN_FULFILLED ) {
        setIsLoading(false);

        if (isRequiredSsn) {
          dispatch(setSteps({
            lastStep: Step.ssn,
            nextStep: Step.idv
          }));
          navigate("/kyc-veriff");
        } else {
          dispatch(setSteps({
            lastStep: Step.ssn,
            nextStep: Step.passport
          }));
          navigate("/kyc-veriff-passport");
        }
      }

      if (responseSSN?.type === UPDATE_USER_SSN_REJECTED) {
        if (responseSSN.payload === DOCUMENT_CONFLICT) {
          setErrorMsg(t("409.errorSSN"));
        } else {
          setErrorMsg(t("404.subtitle"));
        }

        setIsLoading(false);
      }

      return;
    }

    setAuthorizeWithSsn(true);

    if (verificationMethod === VerificationMethodOptions.SocialSecurityNumber) {
      setIsRequiredSsn(true);
    } else {
      setIsRequiredSsn(false);
    }
  }
  
  const handleBackClick = () => {
    if (nationalityUSA || customerId !== null) {
      dispatch(setSteps({
        lastStep: Step.personShippingAddress,
        nextStep: Step.personEmail
      }))
      navigate("/kyc-email")
    } else if(authorizeWithSsn) {
      setAuthorizeWithSsn(false);
    }
  }

  const handleSSNChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputSSN = event.target.value.replace(/\D/g, '');
    const cursorPosition = event.target.selectionStart;

    setErrorMsg("");

    const [formattedSSN, newCursorPosition] = maskSsn(inputSSN, cursorPosition);

    setValue("ssn", formattedSSN);

    if (newCursorPosition && event?.target?.setSelectionRange)
      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
  };

  const validateNationalityFlow = () => {
    const nationality = nationalityForm || PrefilledData.data?.nationality || "";

    if(nationality === "USA") {
      setNationalityUSA(true);
      setAuthorizeWithSsn(true);
      setIsRequiredSsn(true);
    }

    if (nationality !== "USA") {
      setNationalityUSA(false);
      setAuthorizeWithSsn(false);
      setIsRequiredSsn(false);
    }
  }

  const getPreload = () => {
    const MaskSSN = MaskSimpleSSN(PrefilledData.data?.socialSecurityNumber || "");

    setValue("ssn", MaskSSN, { shouldValidate: true, shouldDirty: true });
  }

  useEffect(() => {
    if(!isEmpty(PrefilledData?.data)) {
      getPreload();
    }
    validateNationalityFlow();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <FillerContainer as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12}>
                  <StepsRoutes totalSteps={8} stepIndex={7} />
                </Column>

                <Column span={12} my={2}>
                  {(nationalityUSA ||
                    !authorizeWithSsn ||
                    customerId !== null) && (
                    <Title
                      tagName="h1"
                      color="black"
                      align="left"
                      size={3}
                      text={
                        authorizeWithSsn && nationalityUSA
                          ? t("personalData.ssn.titleSSN")
                          : t("personalData.ssn.title")
                      }
                    />
                  )}

                  {!nationalityUSA &&
                    customerId === null &&
                    authorizeWithSsn && (
                      <Row>
                        <Column span={12} mb={1}>
                          <Title
                            tagName="h1"
                            color="black"
                            align="left"
                            size={3}
                            text={
                              isRequiredSsn
                                ? t("personalData.ssn.subtitleSSNUSA")
                                : t("personalData.ssn.subtitleSSN")
                            }
                          />
                        </Column>
                      </Row>
                    )}

                  {authorizeWithSsn && (
                    <>
                      {(nationalityUSA || customerId) && (
                        <SocialSecurityNumberInfoCard>
                          <Icon icon="checkCircleGreen" size="medium" />
                          <div>
                            <CustomText
                              tagName="p"
                              color={"black"}
                              align="left"
                              size={0.5}
                              margin="0 0 0 10px"
                              weight={600}
                            >
                              {t("personalData.ssn.titleInfoCard")}
                            </CustomText>
                            <CustomText
                              tagName="p"
                              color={"grey"}
                              align="left"
                              size={0}
                              margin="0 0 5px 10px"
                              weight={400}
                            >
                              {nationalityUSA
                                ? t("personalData.ssn.descriptionInfoCard")
                                : t(
                                    "personalData.ssn.descriptionClientsInfoCard"
                                  )}
                            </CustomText>
                          </div>
                        </SocialSecurityNumberInfoCard>
                      )}

                      <Row>
                        <Column span={8}>
                          { (nationalityUSA || customerId !== null) && (
                            <CustomText
                              tagName="p"
                              color={"black"}
                              align="left"
                              size={1}
                              {...(nationalityUSA
                                ? { margin: "24px 0 10px 0" }
                                : { margin: "24px 0 5px 0" })}
                              weight={600}
                            >
                              {!isRequiredSsn
                                ? t("personalData.ssn.subtitleSSN")
                                : t("personalData.ssn.subtitleSSNUSA")}
                            </CustomText>
                          )}
                        </Column>
                        <Column
                          span={4}
                          alignItems={AlignItems.center}
                          justifyContent={JustifyContent.flexEnd}
                        >
                          {!isRequiredSsn && (
                            <SocialSecurityNumberOptional>
                              {t("personalData.ssn.optional")}
                            </SocialSecurityNumberOptional>
                          )}
                        </Column>
                      </Row>
                    </>
                  )}

                  {(isRequiredSsn || nationalityUSA) && authorizeWithSsn && (
                    <CustomText
                      tagName="p"
                      color="grey"
                      align="left"
                      size={0.5}
                      margin="5px 0"
                      weight={400}
                    >
                      {t("personalData.ssn.paragraph")}
                    </CustomText>
                  )}

                  {!nationalityUSA &&
                    !authorizeWithSsn &&
                    (customerId === null || customerId === undefined) && (
                      <>
                        <CustomText
                          tagName="p"
                          color="grey"
                          align="left"
                          size={0}
                          margin="5px 0"
                          weight={400}
                        >
                          {t("personalData.ssn.descriptionOpenMarket")}
                        </CustomText>
                        <CustomText
                          tagName="p"
                          color="black"
                          align="left"
                          size={0.5}
                          margin="24px 0 0 0"
                          weight={500}
                        >
                          {t("personalData.ssn.paragraphOpenMarket")}
                        </CustomText>
                      </>
                    )}
                </Column>

                {authorizeWithSsn ? (
                  <Column span={12}>
                    <form onSubmit={handleSubmit(handleClick)}>
                      <Input
                        label="ssn"
                        placeholderInput={
                          nationalityUSA || isRequiredSsn
                            ? t("personalData.ssn.placeholders.ssnUSA")
                            : t("personalData.ssn.placeholders.ssn")
                        }
                        typeInput="text"
                        inputMode="numeric"
                        textLabel={
                          nationalityUSA || isRequiredSsn
                            ? t("personalData.ssn.labelUSA")
                            : t("personalData.ssn.label")
                        }
                        maxLengthInput={11}
                        register={register}
                        onChange={handleSSNChange}
                        errors={errors}
                        rules={{
                          required: isRequiredSsn ? t("Forms.required") : false,
                          pattern: {
                            value: isRequiredSsn ? allowedSsnItin : allowedSsn,
                            message: t("Forms.inputSsn"),
                          },
                        }}
                        customError={errorMsg}
                      />
                    </form>
                    {!nationalityUSA && !isRequiredSsn && (
                      <Row>
                        <Column span={12} mt={3}>
                          <SocialSecurityNumberOpenMarketInfo>
                            <Icon icon="circleInfoBlack" size="medium" />
                            <CustomText
                              tagName="p"
                              color={"black"}
                              align="left"
                              size={0}
                              margin="0 0 5px 10px"
                              weight={500}
                            >
                              {t("personalData.ssn.infoOpenMarket")}
                            </CustomText>
                          </SocialSecurityNumberOpenMarketInfo>
                        </Column>
                      </Row>
                    )}
                  </Column>
                ) : (
                  <Column span={12} my={2}>
                    <RadioCard
                      id={VerificationMethodOptions.SocialSecurityNumber}
                      name="verificationMethod"
                      selected={
                        verificationMethod ===
                        VerificationMethodOptions.SocialSecurityNumber
                      }
                      imageElement={
                        <div className="id-card-icon">
                          <Icon icon="idCard" size="medium" />
                        </div>
                      }
                      onChangeEvent={handleChangeRadio}
                      text={t("personalData.ssn.driverLicenseTitle")}
                      legends={[
                        t("personalData.ssn.driverLicenseCardOption1"),
                        t("personalData.ssn.driverLicenseCardOption2"),
                      ]}
                    />
                    <RadioCard
                      id={VerificationMethodOptions.VisaAndPassport}
                      name="verificationMethod"
                      selected={
                        verificationMethod ===
                        VerificationMethodOptions.VisaAndPassport
                      }
                      imageElement={
                        <div className="visa-passport-icon">
                          <Icon icon="visaPassport" size="large" />
                        </div>
                      }
                      onChangeEvent={handleChangeRadio}
                      text={t("personalData.ssn.jobVisaTitle")}
                      legends={[
                        t("personalData.ssn.jobVisaOption1"),
                        t("personalData.ssn.jobVisaOption2"),
                      ]}
                    />
                  </Column>
                )}

                {isError && (
                  <Column span={12} mb={2}>
                    <Text size={0.5} align="center" weight={600} color="error">
                      {t("404.subtitle")}
                    </Text>
                  </Column>
                )}

                <Column 
                  span={12} 
                  my={6} 
                  alignItems={AlignItems.stretch} 
                  as={SocialSecurityButtonsContainer}
                >
                  {authorizeWithSsn && (
                    <Button
                      variant={"secondary"}
                      text={t("personalData.ssn.backButton")}
                      sizeText="medium"
                      sizeButton="medium"
                      onClick={handleBackClick}
                    />
                  )}

                  <Button
                    type="submit"
                    variant={
                      (authorizeWithSsn && !isValid) || 
                      isLoading || 
                      (!verificationMethod && !authorizeWithSsn)
                        ? undefined
                        : "primary"
                    }
                    text={t("personalData.ssn.nextButton")}
                    sizeText="medium"
                    sizeButton="medium"
                    onClick={handleSubmit(handleClick)}
                    loading={isLoading ? 1 : 0}
                    disabled={
                      (authorizeWithSsn && !isValid) || 
                      (!verificationMethod && !authorizeWithSsn)
                    }
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </FillerContainer>
      </Layout>
    </ThemeProvider>
  );
}
