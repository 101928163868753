import { createSlice } from "@reduxjs/toolkit";
import { QuotationState } from "../../Models/QuoteState";
import { QUOTATION } from "../../Constants/Quote";
import { getQuoteAction } from "./Quotation.actions";
import { State } from "../Store";

const initialState: QuotationState = {
  loading: false,
  error: null,
  data: null,
};

export const QuotationSlice = createSlice({
  name: QUOTATION,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuoteAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQuoteAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getQuoteAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default QuotationSlice.reducer;

export const QuotationSelector = (state: State) => state.quotation;
