import styled from "styled-components";
import {
  AlignItems,
  JustifyContent,
  Flex as FlexType,
  FlexDirection,
} from "../../Models/Flex";
import { ifProp, prop } from "styled-tools";
import { FlexProps } from "../../Models/FlexContainer";

export const Flex = styled.div<{
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  gap?: number | string;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  flex?: FlexType;
  flexDirection?: FlexDirection;
  flexFlow?: string;
}>`
  display: flex;
  width: ${prop("width")};
  height: ${prop("height")};
  min-width: ${prop("minWidth")};
  max-width: ${prop("maxWidth")};
  min-height: ${prop("minHeight")};
  max-height: ${prop("maxHeight")};
  gap: ${prop("gap")};
  flex: ${prop("flex.grow")} ${prop("flex.shrink")} ${prop("flex.basis")};
  flex-direction: ${prop("flexDirection")};
  flex-flow: ${prop("flexFlow")};
  justify-content: ${prop("justifyContent")};
  align-items: ${prop("alignItems")};
`;


export const FlexItem = styled.div<FlexProps>`
  box-sizing: border-box;
  display: flex;
  width: ${ifProp("w", prop("w"), "auto")};
  height: ${ifProp("h", prop("h"), "auto")};
  max-width: ${ifProp("mw", prop("mw"), "unset")};
  padding: ${ifProp("p", prop("p"), "0px")};
  order: ${ifProp("order", prop("order"), "0")};
  flex-grow: ${ifProp("grow", prop("grow"), "0")};
  align-self: ${ifProp("alignSelf", prop("alignSelf"), "unset")};
  align-items: ${ifProp("alignItems", prop("alignItems"), "unset")};
  justify-content: ${ifProp("justify", prop("justify"), "start")};
  flex: ${ifProp("flex", prop("flex"), "")};
  flex-direction: ${ifProp("direction", prop("direction"), "row")};
  gap: ${ifProp("gap", prop("gap"), "0")};
  margin: ${ifProp("m", prop("m"), "0px")};
`;

export const FlexContainer = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${ifProp("direction", prop("direction"), "row")};
  justify-content: ${ifProp("justify", prop("justify"), "start")};
  align-items: ${ifProp("alignItems", prop("alignItems"), "start")};
  width: ${ifProp("w", prop("w"), "100%")};
  max-width: ${ifProp("mw", prop("mw"), "unset")};
  height: ${ifProp("h", prop("h"), "auto")};
  padding: ${ifProp("p", prop("p"), "0px")};
  margin: ${ifProp("m", prop("m"), "0px")};
  flex-wrap: ${ifProp("flexWrap", "wrap", "nowrap")};
  gap: ${ifProp("gap", prop("gap"), "0")};
  box-sizing: border-box;
`;