import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { ThemeProvider } from "styled-components";
import isEmpty from "lodash.isempty";

// own states of redux
import { AppDispatch, State } from "../../../Redux/Store";
import { PersonalDataAction } from "../../../Redux/PersonalData/PersonalData.actions";
import { PrefilledDataSelector } from "../../../Redux/PrefilledData/PrefilledData.slice";
import { setSteps } from "../../../Redux/Onboarding/Onboarding.slice";
import { updateNationality } from "../../../Redux/Nationality";

// Own Models
import { JustifyContent } from "../../../Models/Column";
import { ValueProp } from "../../../Models/Select";
import { PersonalDataFormValues } from "../../../Models/PersonalData";
import { Step } from "../../../Models/Onboarding";

// Own styles
import { StepsContainer } from "./LegalName.styled";

// Own constants
import { UPDATE_PERSONAL_DATA_FULFILLED, UPDATE_PERSONAL_DATA_REJECTED } from "../../../Constants/PersonalData";
import { MonthsEn, MonthsEs } from "../../../Constants/Date";
import { allowedEmail, allowedNames, allowenNumbers, noLeadingSpaces } from "../../../Constants/Regexp";

// OWn components
import { Container, Row, Column } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Layout from "../../../Components/Layout";
import Input from "../../../Components/Input";
import ReactSelect from "../../../Components/ReactSelect";
import StepsRoutes from "../../../Components/StepsRoutes";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";

const LegalName = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const lang = useSelector((state: State) => state.language);
  const PrefilledData = useSelector(PrefilledDataSelector);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const months = lang.language === "es" ? MonthsEs : MonthsEn;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const onSubmit: SubmitHandler<PersonalDataFormValues> = async (data) => {
    setIsLoading(true);
    setIsError(false);
    const dateOfBirth = `${data.year}-${data.month}-${data.day}`;

    const payload = {
      firstName: data.firstName?.trim() || "",
      middleName: data.middleName?.trim() || "",
      lastName: data.lastName?.trim() || "",
      dateOfBirth: dateOfBirth || "",
      email: data.email || ""
    }

    await dispatch(updateNationality(data.nationality));

    const response = await dispatch(PersonalDataAction(payload));

    if (response?.type === UPDATE_PERSONAL_DATA_FULFILLED) {
      dispatch(setSteps({
        lastStep: Step.personInformation,
        nextStep: Step.submitPep
      }))
      window.location.href = "/complete-onboarding";
    }

    if (response?.type === UPDATE_PERSONAL_DATA_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const getPreload = () => {
    const dateOfBirth = PrefilledData.data?.dateOfBirth || "";
    const dateOfBirthArray = dateOfBirth.split("-");

    setValue("firstName", PrefilledData.data?.firstName, { shouldValidate: true, shouldDirty: true });
    setValue("middleName", PrefilledData.data?.middleName, { shouldValidate: true });
    setValue("lastName", PrefilledData.data?.lastName, { shouldValidate: true });
    setValue("day", dateOfBirthArray[2]?.padStart(2, "0"), { shouldValidate: true });
    setValue("month", dateOfBirthArray[1]?.padStart(2, "0"), { shouldValidate: true });
    setValue("year",dateOfBirthArray[0], { shouldValidate: true });
    setValue("email",PrefilledData.data?.email, { shouldValidate: true });
  }

  useEffect(() => {
    if(!isEmpty(PrefilledData?.data)) {
      getPreload();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Container as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12}>
                  <Row>
                    <Column span={12}>
                      <StepsRoutes totalSteps={8} stepIndex={3} />
                    </Column>
                  </Row>
                  <Row>
                    <Column span={12} justifyContent={JustifyContent.flexStart} mt={3}>
                      <Text size={3} align="left" weight={600} color="black" margin={0}>
                        {t("personalData.legalName.title")}
                      </Text>
                    </Column>
                    <Column span={12} justifyContent={JustifyContent.flexStart} my={1}>
                      <Text size={0.5} align="left" weight={400} color="grey" margin={0}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("personalData.legalName.subtitle"),
                          }}
                        />
                      </Text>
                    </Column>
                  </Row>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Column span={12} justifyContent={JustifyContent.flexStart} my={1}>
                        <Title
                          tagName="h1"
                          color="black"
                          size={2}
                          text={t("personalData.legalName.subtitleFirstSection")}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column span={12}>
                        <Input
                          typeInput="text"
                          textLabel={t("personalData.legalName.forms.inputName")}
                          label="firstName"
                          maxLengthInput={30}
                          register={register}
                          onBlur={() => trigger("firstName")}
                          rules={{
                            required: t("Forms.required"),
                            pattern: {
                              value: noLeadingSpaces,
                              message: t("Forms.validateSpaces"),
                            },
                            validate: {
                              allowedNames: value => allowedNames.test(value) || t("Forms.inputAlphabets")
                            }
                          }}
                          errors={errors}
                        />
                      </Column>
                      <Column span={12} my={2}>
                        <Input
                          typeInput="text"
                          textLabel={t("personalData.legalName.forms.lastName")}
                          label="lastName"
                          maxLengthInput={30}
                          register={register}
                          onBlur={() => trigger("lastName")}
                          rules={{
                            required: t("Forms.required"),
                            pattern: {
                              value: noLeadingSpaces,
                              message: t("Forms.validateSpaces"),
                            },
                            validate: {
                              allowedNames: value => allowedNames.test(value) || t("Forms.inputAlphabets")
                            }
                          }}
                          errors={errors}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column span={12} justifyContent={JustifyContent.flexStart} mt={2}>
                        <Title
                          tagName="h1"
                          color="black"
                          size={2}
                          text={t("personalData.legalName.subtitleSecondSection")}
                        />
                      </Column>
                      <Column span={4} py={1}>
                        <Controller
                          control={control}
                          name="month"
                          rules={{
                            required: t("Forms.required"),
                          }}
                          render={({ field: { onChange, value, ref } }) => (
                            <ReactSelect
                              inputRef={ref}
                              nameSelect="accountType"
                              placeholder={t("personalData.legalName.forms.inputMonth")}
                              options={months}
                              value={months.find(c => c.value === value)}
                              onChange={(val:ValueProp) => onChange(val?.value)}
                              errors={errors}
                            />
                          )}
                        />
                      </Column>
                      <Column span={4} p={1}>
                        <Input
                          typeInput="number"
                          textLabel={""}
                          label="day"
                          min="1"
                          max="31"
                          step="1"
                          maxLengthInput={2}
                          placeholderInput={t("personalData.legalName.forms.inputDay")}
                          register={register}
                          onBlur={() => trigger("day")}
                          rules={{
                            required: t("Forms.required"),
                            pattern: {
                              value: allowenNumbers,
                              message: t("Forms.inputNumber"),
                            }
                          }}
                          errors={errors}
                        />
                      </Column>
                      <Column span={4} py={1}>
                        <Input
                          typeInput="number"
                          textLabel={""}
                          label="year"
                          min="1900"
                          max="2024"
                          step="1"
                          maxLengthInput={4}
                          placeholderInput={t("personalData.legalName.forms.inputYear")}
                          register={register}
                          onBlur={() => trigger("year")}
                          rules={{
                            required: t("Forms.required"),
                            pattern: {
                              value: allowenNumbers,
                              message: t("Forms.inputNumber"),
                            }
                          }}
                          errors={errors}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column span={12} justifyContent={JustifyContent.flexStart} mt={2}>
                        <Title
                          tagName="h1"
                          color="black"
                          size={2}
                          text={t("personalData.email.placeholders.email")}
                        />
                      </Column>
                      <Column span={12} mt={1} mb={3}>
                        <Input
                          label="email"
                          typeInput="text"
                          textLabel={t("personalData.email.placeholders.email")}
                          maxLengthInput={50}
                          register={register}
                          errors={errors}
                          rules={{
                            required: t("Forms.required"),
                            pattern: {
                              value: allowedEmail,
                              message: t("Forms.inputEmail"),
                            },
                          }}
                        />
                      </Column>
                    </Row>
                    <Row>
                      {isError && (
                        <Column span={12} mb={2}>
                          <Text size={0.5} align="center" weight={600} color="error">
                            {t("404.subtitle")}
                          </Text>
                        </Column>
                      )}
                      <Column span={12} mb={2}>
                        <Button
                          type="submit"
                          variant={
                            !isDirty || !isValid || isLoading ? "default" : "primary"
                          }
                          text={t("personalData.legalName.forms.button")}
                          sizeText="medium"
                          sizeButton="large"
                          loading={isLoading ? 1 : 0}
                          disabled={!isValid}
                        />
                      </Column>
                    </Row>
                  </form>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default LegalName;
