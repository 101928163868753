import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";

// own states of redux
import { AppDispatch, State } from "../../../Redux/Store";
import { SubmitCompleteOnboardingAction } from "../../../Redux/SubmitCompleteOnboarding/SubmitCompleteOnboarding.actions";
import { setSteps } from "../../../Redux/Onboarding/Onboarding.slice";
import { getKCYStatusAction } from "../../../Redux/GetKYCStatus/GetKYCStatus.actions";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";

// Own models
import { JustifyContent } from "../../../Models/Column";
import { Step } from "../../../Models/Onboarding";

// Own constants
import {
  GET_KYC_STATUS_FULFILLED,
  GET_KYC_STATUS_REJECTED,
  SUBMIT_COMPLETE_ONBOARDING_FULFILLED,
  SUBMIT_COMPLETE_ONBOARDING_REJECTED,
} from "../../../Constants/KYCStatus";

// Own styles
import { MarginContainer } from "./SubmitCompleteOnboarding.styled";

// OWn components
import Layout from "../../../Components/Layout";
import {
  BottomFixedContainer,
  Column,
  Container,
  FillerContainer,
  Row,
} from "../../../Components/Grid";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import Icon from "../../../Components/Icon";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

const SubmitCompleteOnboardingPage = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isDeclined, setIiDeclined] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const ByUser = useSelector(ByUserSliceSelector);

  const personId = useMemo(() => ByUser.data?.personId || "", [ByUser]);

  const GetKYCStatus = async (personId: string) => {
    const payload = {
      personId: personId
    };

    const response = await dispatch(getKCYStatusAction(payload));

    if (response?.type === GET_KYC_STATUS_FULFILLED) {
      if (
        response?.payload?.results?.pep?.status === "approved" ||
        response?.payload?.results?.pep?.status === "subbmitted" ||
        response?.payload?.results?.pep?.status === "inReview"
      ) {
        window.location.href = "/";
      }

      if (response?.payload.data?.results?.pep?.status === "declined") {
        setIiDeclined(true);
      }
    }

    if (response?.type === GET_KYC_STATUS_REJECTED) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const SubmitCompleteOnborading = async () => {
    const response = await dispatch(SubmitCompleteOnboardingAction());

    if (response?.type === SUBMIT_COMPLETE_ONBOARDING_FULFILLED) {
      GetKYCStatus(personId);
    }

    if (response?.type === SUBMIT_COMPLETE_ONBOARDING_REJECTED) {
      setIsError(true);
      setIsLoading(false);
    }

    dispatch(
      setSteps({
        lastStep: Step.submitPep,
        nextStep: null,
        requiredOnboarding: false,
      })
    );
  };

  useEffect(() => {
    SubmitCompleteOnborading();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.titleOnboarding2")}
          description={t("loaderText.description2")}
        />
      </Layout>
      <Layout show={!isLoading && isError}>
        <FillerContainer>
          <Row>
            <Column
              span={12}
              justifyContent={JustifyContent.center}
              mt={6}
              mb={2}
            >
              <Icon icon="alertWarning" size="xlarge" />
            </Column>
            <Column span={12} mb={5}>
              <Text size={18} align="center" weight={600} color="red">
                {t("404.subtitle")}
              </Text>
            </Column>

            <Column span={12}>
              <BottomFixedContainer>
                <Button
                  type="submit"
                  variant="primary"
                  text={t("buttons.tryAgain")}
                  loading={isLoading ? 1 : 0}
                  onClick={() => SubmitCompleteOnborading()}
                  sizeButton="xlarge"
                  sizeText="medium"
                />
              </BottomFixedContainer>
            </Column>
          </Row>
        </FillerContainer>
      </Layout>
      <Layout show={!isLoading && !isError && isDeclined}>
        <Container as={MarginContainer}>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} my={2}>
              <Icon icon="circleXRed" size="xlarge" />
            </Column>
            <Column span={12} my={1}>
              <Text
                size={2}
                align="center"
                weight={600}
                color="black"
                margin="0"
              >
                {t("404.titleOnboardingDeclined")}
              </Text>
            </Column>

            <Column span={12} my={1}>
              <Text
                size={1}
                align="center"
                weight={400}
                color="grey"
                margin="0"
              >
                {t("404.subTitleOnboardingDeclined")}
              </Text>
            </Column>

            <Column span={12} my={1}>
              <Button
                type="submit"
                variant="outline"
                text={t("buttons.contactSupport")}
                onClick={() => navigate("/contact-us")}
                sizeButton="xlarge"
                sizeText="medium"
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default SubmitCompleteOnboardingPage;
