export const handleSizeButton = (size: string) => {
  switch (size) {
    case "xsmall":
      return 30;
    case "small":
      return 125;
    case "medium":
      return 160;
    case "large":
      return 240;
    case "xlarge":
      return 330;
    default:
      return 200;
  }
};

export const handleText = (size: string) => {
  switch (size) {
    case "small":
      return 12;
    case "medium":
      return 16;
    case "large":
      return 20;
    default:
      return 14;
  }
};
