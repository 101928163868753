import { ReactNode, useEffect, useRef, useState } from "react";
// Styles
import { PopoverContainer, PopoverContent, PopoverWrapper } from "./Popover.styled";
// Components
import Text from "../Text"
type Props = {
  children: ReactNode;
  color?: "default" | "light";
  align?: "left" | "right";
  content: string | ReactNode;
  contentSize?: number;
  trigger?: string;
  isDisabled?: boolean
  isHidden?: boolean
};
const Popover = ({ 
  children, 
  content, 
  trigger, 
  isDisabled = false, 
  isHidden = false,
  contentSize,
  color = "default",
  align = "left"
}: Props) => {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleMouseOver = () => {
    if (trigger === "hover" && !isDisabled) {
      setShow(true);
    }
  };
  const handleMouseLeft = () => {
    if (trigger === "hover" && !isDisabled) {
      setShow(false);
    }
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current && 
        event.target instanceof HTMLElement &&
        !wrapperRef.current.contains(event.target)
      ) {
        setShow(false);
      }
    }
    if (show) {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [show, wrapperRef]);
  if (isHidden) {
    return (
      <>
        {children}
      </>
    )
  }
  return (
    <PopoverContainer
      className="popover"
      ref={wrapperRef}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeft}
    >
      { isDisabled && !isHidden && <PopoverWrapper onClick={() => setShow(!show)} />}
      <div
        className="popover-children-content" 
        onClick={() => setShow(!show)}
      >
        {children}
      </div>
      <PopoverContent
        color={color}
        align={align}
        className="popover-content"
        sizew={contentSize}
        hidden={!show}
      >
        {typeof content === "string" ? (
          <Text size={0} align="left" margin="0" color="white">
            {content}
          </Text>
        ) : (
          <>{content}</>
        )}
      </PopoverContent>
    </PopoverContainer>
  );
}
export default Popover;