import { HeadModal, ModalWrap, OverLay } from "./Modal.styled";
import Icon from "../Icon";
import { ModalProps } from "../../Models/ModalInterface";

const Modal = (props: ModalProps) => {
  const { show, children, handleClose } = props;

  return (
    <OverLay hidden={!show} onClick={() => handleClose}>
      <ModalWrap onClick={(event) => event.stopPropagation()}>
        {handleClose &&
          <HeadModal>
            <Icon icon="circleX" size="large" color="black" onClick={() => handleClose} />
          </HeadModal>
        }
        {children}
      </ModalWrap>
    </OverLay>
  );
};

export default Modal;
