import { ControlProps, components } from "react-select";
import IconFont from "../IconFont";
import { SelectOption } from "../../Models/Select";

export const CustomControl = ({
  children,
  ...props
}: ControlProps<SelectOption | null>) => {
  return (
    <components.Control {...props}>
      {props.selectProps.menuIsOpen && <IconFont name="search" color="black" />}
      {children}
    </components.Control>
  );
};
