import Text from "../../../Components/Text";
import Icon from "../../../Components/Icon";

import {
  ContainerTitleDay,
} from "./Transactions.styled";

const ContainerTitle = (props: {title: string}) => {
  const { title } = props;

  return (
    <ContainerTitleDay>
      <Text size={0.5} align="left" color="solid_light" weight={600}>
        {title}
      </Text>
      <Icon icon="chevronDown" color="black" size="medium"  />
    </ContainerTitleDay>
  )
};

export default ContainerTitle;