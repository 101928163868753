// Models
import { JustifyContent } from '../../Models/Column';
import { Flex } from '../Flex';

// Components
import { Column, Row } from '../Grid';
import Skeleton from '../Skeleton';

import { BalanceContainer } from './BalanceCard.styled';

const BalanceCardEmpty= () => {
  return (
    <BalanceContainer $active={true}>
      <Row>
        <Column span={6}>
          <Skeleton width={50} height={15}/>
        </Column>
        <Column span={6} justifyContent={JustifyContent.flexEnd}>
          <Skeleton width={50} height={15}/>
        </Column>
      </Row>
      <Row>
        <Column span={12} justifyContent={JustifyContent.center} mt={2}>
          <Skeleton width={100} height={30}/>
        </Column>
        <Column span={12} justifyContent={JustifyContent.center}>
          <Skeleton width={120} height={12}/>
        </Column>
        <Column span={12} justifyContent={JustifyContent.center}>
          <Skeleton width={100} height={12}/>
        </Column>
      </Row>
      <Row>
        <Column span={12} mt={2}>
          <Flex alignItems='center' justifyContent='center' gap="30px">
            <Skeleton width={50} height={50} circle />
            <Skeleton width={50} height={50} circle />
            <Skeleton width={50} height={50} circle />
          </Flex>
        </Column>
      </Row>
    </BalanceContainer>
  )
}

export default BalanceCardEmpty;