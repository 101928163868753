import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";

import { State, AppDispatch } from "../../../Redux/Store";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { accountTransactionSelector } from "../../../Redux/AccountTransactions/AccountTransactions.slice";

import { handleAccountTransactions } from "../../../Redux/AccountTransactions/AccountTransactions.actions";
import { changeTransactionDetail } from "../../../Redux/TransactionDetail";

import { tTransactionByDay, tTransactionAccountArray, tTransactionAccount } from "../../../Models/tTransactionAccount";

import { formatDateTransaction } from "../../../Utilities/String";
import { FormatNumberToThousands } from "../../../Utilities/FormatNumberToThousands";
import { areArraysEqual } from "../../../Utilities/CompareArray";
import { validateEmptyObject } from "../../../Utilities/ValidateEmptyObject";

import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import ContainerTitle from "./ContainerTitle.component";
import ContainerItemTransaction from "./ContainerItemTransaction.component";
import ConstructionPopUp from "../../../Components/ConstructionPopUp";
import Icon from "../../../Components/Icon";
import Text from "../../../Components/Text";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

import {
  ContentColumn,
  BtnViewMore,
} from "./Transactions.styled";

const Transactions = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const lang = useSelector((state: State) => state.language);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isShowPopup, setIsShowPopup] = useState(true);
  const [page, setPage] = useState(1);
  const [showBtnViewMore, setShowBtnViewMore] = useState(false);
  const [offsetNumber, setOffsetNumber] = useState(100);

  const PersonalData = useSelector(ByUserSliceSelector);
  const personId = useMemo(() =>
    PersonalData.data?.personId,
  [PersonalData]);

  const typeAccountSelected = useSelector((state: State) => state.SelectTypeAccount);
  const accountId = typeAccountSelected.selectAccount.id;

  // Get User data

  // Get Transactions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const accountTransactionState: any = useSelector(accountTransactionSelector);
  const [accountTransactions, setTransactionsAccount] = useState<tTransactionAccount>();
  const [transactionsContainer, setTransactionsContainer] = useState<tTransactionByDay[]>();

  const handlePopUp = () => {
    setIsShowPopup(!isShowPopup);
  };

  const compareByCreatedAt = (a: tTransactionByDay, b: tTransactionByDay): number => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    return dateB.getTime() - dateA.getTime();
  };

  const getTransaction = async(offset: number) => {
    const accountTransaction = {
      personId: personId,
      accountId: accountId,
      startTime: "",
      endTime: "",
      limit: 100,
      offset: offset,
    };

    await dispatch(handleAccountTransactions(accountTransaction));
  };

  const viewMore = () => {
    if (page === accountTransactions?.pages) {
      setShowBtnViewMore(false);
    }

    setPage(page + 1);
    setOffsetNumber(offsetNumber + 100);
    getTransaction(offsetNumber);
  };

  const showTransactionDetail = (transferOrderId: tTransactionAccountArray) => {
    dispatch(changeTransactionDetail(transferOrderId));

    if (transferOrderId) {
      return navigate("/transactions-detail");
    }
  };

  useEffect(() => {
    if (page === 1 && accountId) {
      getTransaction(0);
    }

    if (!isEmpty(accountTransactionState)) {
      setTransactionsAccount(accountTransactionState?.data);
    }

    if(accountTransactions && accountTransactions.pages && accountTransactions.pages > 1) {
      setShowBtnViewMore(true);
    }
  }, [accountTransactions])

  useEffect(() => {
    if (page !== 1 && accountTransactions && !areArraysEqual(accountTransactions.transactionsByDate, accountTransactionState.data.transactionsByDate)) {
      const newArrayTransaction = accountTransactions?.transactionsByDate.concat(accountTransactionState.data.transactionsByDate);
      setTransactionsContainer(newArrayTransaction.slice().sort(compareByCreatedAt));

      if (page === accountTransactionState.data.pages) {
        setShowBtnViewMore(false);
      }
    } else if (accountTransactions && accountTransactions.transactionsByDate && accountTransactions.transactionsByDate.length > 0) {
      setTransactionsContainer(accountTransactions.transactionsByDate);
    } else if (accountTransactions && validateEmptyObject(accountTransactions)) {
      navigate('/transactions');
    }
  }, [accountTransactionState])

  return (
    <ThemeProvider theme={theme}>
      <Layout show={accountTransactionState?.loading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout show={!accountTransactionState?.loading} path="/manage-card-account" textLink={t("buttons.back")}>
        <Container as={ContentColumn}>
          <Row>
            <Column span={7} xs={12} md={12}>
              <Title
                size={5}
                text={t("Transactions.title")}
                color="black"
                align="left"
                tagName="h1"
              />
            </Column>
            <Column span={7} xs={12} sm={12} md={12}>
              {accountTransactions && transactionsContainer &&
                transactionsContainer.map((transactionDay: tTransactionByDay, index: number) => {
                  return (
                    <div key={index}>
                      <ContainerTitle
                        title={formatDateTransaction(transactionDay.createdAt, lang.language)}
                      />
                      {transactionDay.transactions &&
                        transactionDay.transactions.map((transaction: tTransactionAccountArray, indexT: number) => {
                          return (
                            <ContainerItemTransaction
                              key={indexT}
                              title={transaction.title}
                              currentBalance={`$${FormatNumberToThousands(transaction.amount)}`}
                              description={transaction.description}
                              transactionType={transaction.subType}
                              merchant={transaction.storeName}
                              status={transaction.status}
                              showDetails={() => showTransactionDetail(transaction)}
                            />
                          );
                        })
                      }
                    </div>
                  )
                })
              }
            </Column>
            {showBtnViewMore &&
              <Column span={7} xs={12} sm={12} md={12}>
                <BtnViewMore onClick={viewMore}>
                  <Icon
                    icon="plus"
                    color="black"
                    fillIcon={false}
                    size="medium"
                  />
                  <Text size={0.5} margin="0 8px" weight={700}>
                    {t("Dashboard.BtnViewMore")}
                  </Text>
                </BtnViewMore>
              </Column>
            }
          </Row>
        </Container>
        <ConstructionPopUp handlePopUp={handlePopUp} isPopout={isShowPopup} />
      </Layout>
    </ThemeProvider>
  )
}

export default Transactions;