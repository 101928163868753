import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { SUBMIT_KYC } from "../../Constants/KYCStatus";

/**
 * Method to submit KYC
 */
export const SubmitKYCAction = createAsyncThunk(
  SUBMIT_KYC,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post("/persons/kyc");
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);