import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

// Own states of redux
import { ThemeProvider } from "styled-components";
import { AppDispatch, State } from "../../../Redux/Store";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { PrefilledDataSelector } from "../../../Redux/PrefilledData/PrefilledData.slice";
import { PersonalDataSliceSelector } from "../../../Redux/PersonalData/PersonalData.slice";
import { setSteps } from "../../../Redux/Onboarding/Onboarding.slice";
import { UpdateShippingAddressSliceAction } from "../../../Redux/ShippingAddress/ShippingAddress.actions";
import { CustomerConfigSliceAction } from "../../../Redux/CustomerConfig/CustomerConfig.actions";
import { saveAddressSelector } from "../../../Redux/USAddress";
import { AddressOptionSelector } from "../../../Redux/AddressOption";

// Own models
import { ShippingAddressForm, ShippingAddressOptions, UsAddressOptions } from "../../../Models/ShippingAddress";
import { ValueProp } from "../../../Models/Select";
import { Step } from "../../../Models/Onboarding";
import { JustifyContent } from "../../../Models/Column";

// Own constants
import { noLeadingSpaces, allowenNumbers } from "../../../Constants/Regexp";
import { states } from "../../../Constants/StatesUS";
import {
  GET_CUSTOMER_CONFIG_FULFILLED,
  GET_CUSTOMER_CONFIG_REJECTED,
  UPDATE_USER_SHIPPING_ADDRESS_FULFILLED,
  UPDATE_USER_SHIPPING_ADDRESS_REJECTED
} from "../../../Constants/user";

// Own styles
import { StepsContainer } from "../LegalName/LegalName.styled";
import { AddressSection, BackgroundAddress, LinkChange, TextWithIcon } from "./ShippingAddress.styled";

// Own components
import { Row, Column, FillerContainer } from "../../../Components/Grid";
import Layout from "../../../Components/Layout";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import Input from "../../../Components/Input";
import ReactSelect from "../../../Components/ReactSelect";
import StepsRoutes from "../../../Components/StepsRoutes";
import Icon from "../../../Components/Icon";

export default function ShippingAddress() {
  const [t] = useTranslation("global")
  const theme = useSelector((state: State) => state.theme);
  const ByUser = useSelector(ByUserSliceSelector);
  const PersonalData = useSelector(PersonalDataSliceSelector);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const addressDataForm = useSelector(saveAddressSelector);
  const AddressOption = useSelector(AddressOptionSelector);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [disableOtherAddress, setDisableOtherAddress] = useState<boolean>(false);
  const [showChangeLinkAddress, setShowChangeLinkAddress] = useState<boolean>(false);
  
  const personId = useMemo(() =>
    PersonalData.data?.personId || ByUser.data?.personId,
  [PersonalData, ByUser]);

  const addressOptionSelected = useMemo(() => 
    AddressOption.AddressOption || "",
  [AddressOption]);

  const sameAddress = useMemo<string | null>(() => {
    const address = {
      line1: PrefilledData?.data?.address?.line1 || addressDataForm.address.line1,
      line2: PrefilledData?.data?.address?.line2 || addressDataForm.address.line2,
      locality: PrefilledData?.data?.address?.locality || addressDataForm.address.city,
      countrySubdivision: PrefilledData?.data?.address?.countrySubdivision || addressDataForm.address.country,
      zipCode: PrefilledData?.data?.address?.zipCode || addressDataForm.address.zipCode,
    };

    return `${address.line1}${address.line2 ? ' ' : ''}${address.line2 || ""}, ${address.locality}, ${address.countrySubdivision} ${address.zipCode}`;
  }, [PrefilledData]);

  const [addressOption, setAddressOption] = useState<ShippingAddressOptions>(ShippingAddressOptions.registeredAddress);

  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid }
  } = useForm<ShippingAddressForm>({
    mode: "onChange"
  })

  const handleShowNewAddressForm = () => {
    setAddressOption(ShippingAddressOptions.newAddress)
  }

  const onSubmit = async (data: ShippingAddressForm) => {
    if (!personId) {
      setIsError(true);
      return;
    }

    setIsError(false);
    setIsLoading(true);

    const preferredAddress =
      addressOption === ShippingAddressOptions.registeredAddress ? {
        line1: PrefilledData.data?.address?.line1 || addressDataForm.address.line1 || '',
        line2: PrefilledData.data?.address?.line2 || addressDataForm.address.line2 || '',
        city: PrefilledData.data?.address?.locality || addressDataForm.address.city || '',
        state: PrefilledData.data?.address?.countrySubdivision || addressDataForm.address.state || '',
        country: PrefilledData.data?.address?.country || addressDataForm.address.country || '',
        zipCode: PrefilledData.data?.address?.zipCode || addressDataForm.address.zipCode || '',
        addressType: PrefilledData.data?.address?.addressType,
      } : {
        ...data,
        country: "USA"
      }

    const response = await dispatch(UpdateShippingAddressSliceAction({
      personId,
      preferredShippingAddress: preferredAddress
    }))

    if (response?.type === UPDATE_USER_SHIPPING_ADDRESS_FULFILLED) {
      setIsLoading(false);
      dispatch(setSteps({
        lastStep: Step.personShippingAddress,
        nextStep: Step.personEmail
      }))
      navigate("/kyc-email");
    }
    
    if (response?.type === UPDATE_USER_SHIPPING_ADDRESS_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }
  }

  const getCustomerConfig = async () => {
    const response = await dispatch(CustomerConfigSliceAction())
    
    if (response.type === GET_CUSTOMER_CONFIG_FULFILLED) {
      setDisableOtherAddress(response.payload?.isConfigured || false);
    }

    if (response.type === GET_CUSTOMER_CONFIG_REJECTED) {
      setDisableOtherAddress(false);
    }
  }

  useEffect(() => {
    getCustomerConfig();

    if (addressOptionSelected === UsAddressOptions.CompanyAddress) {
      setShowChangeLinkAddress(false);
    }

    if (addressOptionSelected === UsAddressOptions.personalAddress) {
      setShowChangeLinkAddress(true);
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <FillerContainer as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12}>
                  <Row>
                    <Column span={12}>
                      <StepsRoutes totalSteps={8} stepIndex={5} />
                    </Column>
                  </Row>

                  <Row>
                    <Column span={12} justifyContent={JustifyContent.flexStart} mt={5} mb={2}>
                      <Text size={3} weight={600} color="black" align="left" margin="0">
                        {t("personalData.shippingAddress.title")}
                      </Text>
                    </Column>
                  </Row>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Column span={12}>
                        <TextWithIcon>
                          <Icon icon="creditCard" color="Primary01" fillIcon={false} size="medium" />
                          <Text size={18} weight={600} color="black" align="left">
                            {t("personalData.shippingAddress.subtitle")}
                          </Text>
                        </TextWithIcon>
                      </Column>

                      { addressOption === ShippingAddressOptions.registeredAddress && !isEmpty(sameAddress) && (
                        <Column span={12}>
                          <BackgroundAddress>
                            <AddressSection>
                              <Text size={1} weight={600} color="black" align="left">
                                {sameAddress}
                              </Text>
                            </AddressSection>
                            { (showChangeLinkAddress || disableOtherAddress) && (
                              <LinkChange onClick={handleShowNewAddressForm}>
                                <Icon icon="edit" color="Primary01" fillIcon={false} size="small" />
                                <Text size={1} weight={600} color="Primary01" align="left" margin={0}>
                                  {t("personalData.shippingAddress.linkChange")}
                                </Text>
                              </LinkChange>
                            )}
                          </BackgroundAddress>
                        </Column>
                      )}

                      { addressOption === ShippingAddressOptions.newAddress && (
                        <Column span={12}>
                          <Column span={12} my={1}>
                            <Text size={0.5} weight={400} color="black" align="left" margin={0}>
                              {t("personalData.shippingAddress.labelNewAddress")}
                            </Text>
                          </Column>
                          <Column span={12} my={1}>
                            <Input
                              label="line1"
                              typeInput="text"
                              maxLengthInput={30}
                              textLabel={t("personalData.shippingAddress.placeholders.address")}
                              register={register}
                              onBlur={() => trigger("line1")}
                              rules={{
                                required: t("Forms.required"),
                                pattern: {
                                  value: noLeadingSpaces,
                                  message: t("Forms.validateSpaces"),
                                }
                              }}
                              errors={errors}
                            />
                          </Column>
                          <Column span={12} my={1}>
                            <Input
                              label="line2"
                              typeInput="text"
                              maxLengthInput={30}
                              textLabel={t("personalData.shippingAddress.placeholders.number")}
                              register={register}
                              onBlur={() => trigger("line2")}
                              rules={{
                                pattern: {
                                  value: noLeadingSpaces,
                                  message: t("Forms.validateSpaces"),
                                }
                              }}
                              errors={errors}
                            />
                          </Column>
                          <Column span={12} my={1}>
                            <Input
                              label="city"
                              typeInput="text"
                              maxLengthInput={30}
                              textLabel={t("personalData.shippingAddress.placeholders.city")}
                              register={register}
                              onBlur={() => trigger("city")}
                              rules={{
                                required: t("Forms.required"),
                                pattern: {
                                  value: noLeadingSpaces,
                                  message: t("Forms.validateSpaces"),
                                }
                              }}
                              errors={errors}
                            />
                          </Column>
                          <Column span={12} my={1}>
                            <Controller
                              control={control}
                              name="state"
                              rules={{
                                required: t("Forms.required"),
                              }}
                              render={({ field: { onChange, value, ref } }) => (
                                <ReactSelect
                                  inputRef={ref}
                                  nameSelect="state"
                                  placeholder={t("personalData.usAddress.form.stateInput")}
                                  textLabel=""
                                  options={states}
                                  value={states.find(c => c.value === value)}
                                  onChange={(val:ValueProp) => onChange(val?.value)}
                                  selectedDefaultValue="US"
                                  errors={errors}
                                />
                              )}
                            />
                          </Column>
                          <Column span={12} my={1}>
                            <Input
                              label="zipCode"
                              typeInput="number"
                              inputMode="numeric"
                              maxLengthInput={5}
                              textLabel={t("personalData.shippingAddress.placeholders.zipCode")}
                              register={register}
                              onBlur={() => trigger("zipCode")}
                              rules={{
                                required: t("Forms.required"),
                                pattern: {
                                  value: allowenNumbers,
                                  message: t("Forms.inputNumber"),
                                },
                                validate: {
                                  noLeadingSpaces: value => noLeadingSpaces.test(value) || t("Forms.validateSpaces")
                                }
                              }}
                              errors={errors}
                            />
                          </Column>
                        </Column>
                      )}

                      {isError && (
                        <Column span={12}>
                          <Text size={0.5} align="center" weight={600} color="error">
                            {t("404.subtitle")}
                          </Text>
                        </Column>
                      )}
                      <Column span={12} mt={4}>
                        <Button
                          variant={(!isValid && addressOption === ShippingAddressOptions.newAddress) || isLoading ? undefined : "primary"}
                          text={t("personalData.shippingAddress.sendCard")}
                          sizeText="medium"
                          iconButton="arrowRight"
                          sizeIcon="large"
                          colorIcon="white"
                          sizeButton="large"
                          loading={isLoading ? 1 : 0}
                          onClick={handleSubmit(onSubmit)}
                          disabled={(!isValid && addressOption === ShippingAddressOptions.newAddress) || isLoading}
                        />
                      </Column>
                      {addressOption === ShippingAddressOptions.newAddress && (
                        <Column span={12} mt={1} mb={4}>
                          <Button
                            type="button"
                            variant={"secondary"}
                            text={t("personalData.usAddress.form.btnBack")}
                            sizeText="large"
                            onClick={() => {
                              setAddressOption(ShippingAddressOptions.registeredAddress)
                            }}
                            sizeButton="large"
                          />
                        </Column>
                      )}
                      </Row>
                  </form>
                </Column>
              </Row>
            </Column>
          </Row>
        </FillerContainer>
      </Layout>
    </ThemeProvider>
  )
}
