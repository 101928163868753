import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const ContentColumn = styled.div`
  padding: 40px 100px;
  margin-bottom: 200px;

  ${mediaQuery[Screen.mobile]} {
    padding: 10px 25px;
  }
`;

export const ContainerTitleDay = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0;
  font-size: 16px;
  justify-content: space-between;
  border-bottom: 1px solid  ${theme("Neutral_900")};
  padding-top: 20px;

  p {
    margin-bottom: 4px;
  }
`;

export const ContainerItem = styled.button`
  background: none;
  border: none;
  width: 100%;
  padding: 22px 0 4px 0;
  cursor: pointer;
  border-bottom: 1px solid  ${theme("Neutral_900")};

  p {
    margin-block: 0;
  }
`;

export const TitleItem = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const CurrentBalance = styled.div`
  display: inline-flex;
  margin-block: 4px;
  align-items: center;
`

export const BtnViewMore = styled.button`
  display: inline-block;
  margin-top: 40px;
  background: none;
  border: none;
  cursor: pointer;

  p {
    border-bottom: 1px solid ${theme("Background_02")};
  }

  div:first-child {
    margin-inline: auto;
  }

  ${mediaQuery[Screen.mobile]} {
    display: inline-flex;
    align-items: center;
  }
`