import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const BannerServicesContainer = styled.div<{ $bgColor: string }>`
  display: flex;
  border-radius: 12px;
  box-sizing: border-box;
  background: ${(props) => (theme(props.$bgColor))};
  height: 140px;
  padding: 10px 12px;
  position: relative;
  width: 100%;

  ${mediaQuery[Screen.tablet]} {
    height: 140px;
  }

  ${mediaQuery[Screen.xlDesktop]} {
    height: 180px;
  }
`;

export const Banner = styled.div`
  margin: 8px 8px;
  position: relative;
`;

export const TitleCard = styled.div`
  color: ${theme("Background_01")};
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin: 4px 0;

  ${mediaQuery[Screen.tablet]} {
    font-size: 10px;
  }

  ${mediaQuery[Screen.mobile]} {
    font-size: 10px;
  }
`;

export const TextCard = styled.div`
  color: ${theme("Background_01")};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin: 4px 0;

  ${mediaQuery[Screen.tablet]} {
    font-size: 10px;
  }

  ${mediaQuery[Screen.mobile]} {
    font-size: 10px;
  }
`;

export const ContainerTitle = styled.div`
  display: block;
`;

export const Btn = styled.button<{$sizeText?: string}>`
  display: inline-flex;
  height: 24px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid ${theme("BackgroundPurple3")};
  background: ${theme("Gradient_12")};
  margin-top: 18px;

  span {
    color: ${theme("Background_01")};
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;

    ${mediaQuery[Screen.xs]} {
      font-size: ${props => props.$sizeText ? props.$sizeText : "8px"};
    }
  }
`;

export const Check = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 3px 3px 2px 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: ${theme("Gradient_01")};
  position: absolute;
  top: -8px;
  left: 90%;
  z-index: 1;

  ${mediaQuery[Screen.tablet]} {
    left: 93%;
  }

  ${mediaQuery[Screen.desktop]} {
    left: 93%;
  }

  ${mediaQuery[Screen.xlDesktop]} {
    left: 93%;
  }
`;
