import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";

import { State } from "../../../Redux/Store";

import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import TableInfo from "../../../Components/TableInfo";

import { RowBold } from "../../../Models/TableInfo";
import { JustifyContent } from "../../../Models/Column";

import { CustomFormatDateTimeUSI } from "../../../Utilities/String";
import { capitalizeFirstLetter } from "../../../Utilities/CapitalizeFormat";

import {
  ContactSection,
  DetailSection,
} from "./TransactionDetail.styled";
import Button from "../../../Components/Button";

const TransactionDetail = () => {
  const theme = useSelector((state: State) => state.theme);
  const { t } = useTranslation("global");
  const lang = useSelector((state: State) => state.language);
  const navigate = useNavigate();

  const transactionDetail = useSelector((state: State) => state.transactionDetail);
  const transaction = transactionDetail.transactionDetail;

  const data = [
    {
      id: "Description",
      label: t("Transactions.TransactionDetail.Description"),
      value: transaction.description,
      bold: RowBold.LEFT,
    },
    {
      id: "Date",
      label: t("Transactions.TransactionDetail.Date"),
      value: CustomFormatDateTimeUSI(transaction.createdAt, lang.language),
      bold: RowBold.LEFT,
    },
    {
      id: "Status",
      label: t("Transactions.TransactionDetail.Status"),
      value: capitalizeFirstLetter(transaction.status),
      bold: RowBold.LEFT,
    },
    {
      id: "ProcessedDate",
      label: t("Transactions.TransactionDetail.ProcessedDate"),
      value: CustomFormatDateTimeUSI(transaction.createdAt, lang.language),
      bold: RowBold.LEFT,
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} path="/manage-card-account?transactions=true">
        <Container>
          <Row>
            <Column span={12}>
              <Text size={3} color="black" weight={600}>
                {t("Transactions.detailTransactionTitle")}
              </Text>
            </Column>
            <Column span={12}>
              <Text size={3} color={transaction.txnType === "credit" ? "success" : "error"} weight={600}>
                {transaction.txnType === "credit" ? "+" : "-"} {`$ ${transaction.amount}`}
              </Text>
            </Column>
            <Column span={12}>
              <Text size={1} color="black" weight={700} margin="4px 0">
                {transaction.title}
              </Text>
              {transaction.transferType &&
                <Text size={1} color="grey" weight={400} margin="4px 0">
                  {capitalizeFirstLetter(transaction.transferType)}
                </Text>
              }
            </Column>
          </Row>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center}>
              <DetailSection>
                <Row>
                  <Column span={12}>
                  </Column>
                  <Column
                    span={12}
                    justifyContent={JustifyContent.center}
                    my={0}
                  >
                    <TableInfo data={data} />
                  </Column>
                </Row>
              </DetailSection>
            </Column>
          </Row>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center}>
              <ContactSection>
                <Column span={12}>
                  <Text size={1} color="Primary01" weight={600} margin="4px 0" align="left">
                    {t("Transactions.titleSupport")}
                  </Text>
                </Column>
                <Column span={12}>
                  <Text size={1} color="grey" weight={400} margin="4px 0" align="left">
                    {t("Transactions.messageSupport")}
                  </Text>
                </Column>
                <Column span={12}>
                  <Button
                    type="button"
                    variant="outline"
                    text={t("Transactions.btnSupport")}
                    sizeText="medium"
                    sizeButton="large"
                    onClick={() => navigate("/contact-us")}
                  />
                </Column>
              </ContactSection>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default withTranslation("ns")(TransactionDetail);
