import USIcon from "../Assets/Flags/USIcon.png";
import MXIcon from "../Assets/Flags/MXIcon.png";
import GTMIcon from "../Assets/Flags/GTMIcon.png";
import BRAIcon from "../Assets/Flags/BRAIcon.png";
import ARGIcon from "../Assets/Flags/ARGIcon.png";
import BBIcon from "../Assets/Flags/BBIcon.png";
import CLIcon from "../Assets/Flags/CLIcon.png";
import COLIcon from "../Assets/Flags/COLIcon.png";
import CRIIcon from "../Assets/Flags/CRIIcon.png";
import ECUIcon from "../Assets/Flags/ECUIcon.png";
import SLVIcon from "../Assets/Flags/SLVIcon.png";
import HNDIcon from "../Assets/Flags/HNDIcon.png";
import HUNIcon from "../Assets/Flags/HUNIcon.png";
import JAMIcon from "../Assets/Flags/JAMIcon.png";
import MNGIcon from "../Assets/Flags/MNGIcon.png";
import PHLIcon from "../Assets/Flags/PHLIcon.png";
import THAIcon from "../Assets/Flags/THAIcon.png";
import TURIcon from "../Assets/Flags/TURIcon.png";
import ZAFIcon from "../Assets/Flags/ZAFIcon.png";
import BOBIcon from "../Assets/Flags/BOBIcon.png";
import SOLIcon from "../Assets/Flags/SOLIcon.png";
import UYUIcon from "../Assets/Flags/UYUIcon.png";
import NICIcon from "../Assets/Flags/NICIcon.png";
import CANIcon from "../Assets/Flags/CANIcon.png";
import INDIcon from "../Assets/Flags/INDIcon.png";

export const FlagIconPath = (countryId: string) => {
    switch (countryId) {
      case "US":
        return USIcon;
      case "MX":
        return MXIcon;
      case "GT":
        return GTMIcon;
      case "BR":
        return BRAIcon;
      case "AR":
        return ARGIcon;
      case "BB":
        return BBIcon;
      case "CL":
        return CLIcon;
      case "CO":
        return COLIcon;
      case "CR":
        return CRIIcon;
      case "EC":
        return ECUIcon;
      case "SV":
        return SLVIcon;
      case "HN":
        return HNDIcon;
      case "HU":
        return HUNIcon;
      case "JM":
        return JAMIcon;
      case "MN":
        return MNGIcon;
      case "PH":
        return PHLIcon;
      case "TH":
        return THAIcon;
      case "TR":
        return TURIcon;
      case "ZA":
        return ZAFIcon;
      case "BO":
        return BOBIcon;
      case "PE":
        return SOLIcon;
      case "UY":
        return UYUIcon;
      case "NI":
        return NICIcon;
      case "CA":
        return CANIcon;
      case "IN":
        return INDIcon;
    }
  };