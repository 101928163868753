import styled, { CSSProperties } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import {  handleSizeButton } from "../../Utilities/SizesButtons";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const SectionOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-size: 16px;
`;

export const TextOption = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

export const TextOptionNew = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${theme("Primary_09")};
  align-items: center;
  padding: 5px 10px;
  font-weight: 700;
  color: ${theme("Background_02")};
  margin-left: auto;
  font-size: 16px;
`;

export const TextOptionImageAvatar = styled.img<{
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
}>`
  width: ${prop("width", "24px")};
  height: ${prop("height", "24px")};
  border-radius: 50%;
  font-size: 16px;
  margin-right: 12px;
`;

export const ContainerSelect = styled.div<{
  $size?: string;
  mb?: string;
}>`
div div {
  border-width: 0px;
}
  position: relative;
  width: ${(props) =>
    props.$size ? `${handleSizeButton(props.$size)}px` : "100%"};
  margin-bottom: ${ifProp("mb", prop("mb"), "0")};
  text-align: left;
font-size: 16px;
  ${mediaQuery[Screen.mobile]} {
    width: 100%;
  }
`;
