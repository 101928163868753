import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ContactSupportModalProps } from "../../../Models/ContactSupportModalProps";

import { Column, Container, Row } from "../../../Components/Grid";
import Modal from "../../../Components/Modal";
import Title from "../../../Components/Title";
import Text from "../../../Components/Text";

import { ModalContent } from "./AccountDetails.styled";
import Button from "../../../Components/Button";

const ContactSupportModal = (props: ContactSupportModalProps) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  const { showModal, titleText, infoText, closeModal } = props;

  return (
    <Modal  show={showModal}>
      <Container>
        <Row>
          <Column span={12} as={ModalContent}>
            {titleText &&
              <Title
                tagName="h1"
                text={titleText}
                size={5}
                color="black"
              />
            }
            <Text size={18} >
              {infoText}
            </Text>
            <Button
              type="submit"
              variant="primary"
              sizeButton="xlarge"
              sizeText="large"
              text={t("AccountDetails.edit.buttonContact")}
              onClick={() => navigate("/contact-us")}
            />
            <Button
              type="button"
              variant={"outline"}
              sizeButton="xlarge"
              sizeText="large"
              text={t("AccountDetails.edit.buttonBack")}
              onClick={closeModal}
            />
          </Column>
        </Row>
      </Container>
    </Modal>
  );
};

export default ContactSupportModal;