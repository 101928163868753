import { TextStyled } from "./Text.styled";
import { PropsText } from "../../Models/TextInterface";

const Text = (props: PropsText) => {
  const { size, tagName, ...restProps } = props;
  return (
    <TextStyled $size={size} as={tagName} {...restProps}>
      {props.children}
    </TextStyled>
  );
};

export default Text;
