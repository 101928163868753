import styled from "styled-components";
import { theme } from "styled-tools";

export const CardStyled = styled.div<{
  $type?: string;
}>`
  padding: 24px;
  border-radius: 20px;
  background: ${theme('Background_01')};
  box-shadow: 2px 4px 10px 0px rgba(${theme('RGBA_04')});
`;
