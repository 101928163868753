import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";



export const TooltipBoxText = styled.div`
  color: ${theme("Background_01")};
  width: auto;
  padding: 15px 15px;
  text-align: left;
  font-size: 12px;
  z-index: 91;
  ${mediaQuery[Screen.mobile]} {
    right: 1px;
  }

  span {
    width: 90%;
    text-align: left;
    color: white;
    z-index: 91;
  }
`;

export const TooltipContainer = styled.div<{ $hasShadow?: boolean }>`
  position: absolute;
  background: ${theme("Background_02")};
  border-radius: 0.8em;
  z-index: 9;
  width: 200px;
  min-height: 50px;
  height: auto;
  left: 50%; 
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 80%;
    width: 0;
    height: 0;
    border: 31px solid ${theme("Transparent")};
    border-bottom-color: ${theme("Background_02")};
    border-top: 0;
    border-left: 0;
    margin-left: -1px;
    margin-top: -10px;
  }
`;
