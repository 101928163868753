import { useTranslation } from "react-i18next";

import { AlignItems, JustifyContent } from "../../Models/Column";
import { CoppelCardProps } from "../../Models/CoppelCard";

import { Column, Container, Row } from "../Grid";
import Icon from "../Icon";
import Text from "../Text";
import { Flex } from "../Flex";

import { ButtonContainer, CoppelAccess, LinkContainer } from "./CoppelCard.styled";

import CoppelAccessImg from "../../Assets/Img/coppel_access.png";
import BgCard from "../../Assets/Img/card_coppel.png";

const CoppelCard = ({
  status,
  message,
  isUsaPhoneNumber,
  link,
  onClickBtn
}: CoppelCardProps) => {
  const [t] = useTranslation("global");

  return (
    <CoppelAccess $isYellow={status === "active" ? true : false}>
      <Container>
        <Row>
          <Column span={7} justifyContent={JustifyContent.flexStart}>
            <Row>
              <Column span={12}>
                <img
                  src={CoppelAccessImg}
                  width={145}
                  height={19}
                  alt="Coppel access"
                />
              </Column>
              {status === "open" && (
                <Column span={12}>
                  <Text size={0.5} margin={0} color="Primary_16">
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                  </Text>
                </Column>
              )}
            </Row>
          </Column>
          {status === "open" && (
            <Column span={5} justifyContent={JustifyContent.flexEnd}>
              <img src={BgCard} width={110} height={154} alt="Coppel card" />
            </Column>
          )}
          {status === "inProcess" && (
            <Column
              span={5}
              justifyContent={JustifyContent.flexEnd}
              alignItems={AlignItems.flexStart}
            >
              <Flex alignItems="center">
                <Icon
                  icon={"clock"}
                  color="yellow"
                  size="medium"
                  fillIcon={false}
                />
                <Text size={0.5} weight={500} margin={"0 0 0 5px"}>
                  {t("CoppelAccess.processTitle")}
                </Text>
              </Flex>
            </Column>
          )}
          {status === "inProcess" && (
            <Column span={12} justifyContent={JustifyContent.center}>
              <Text
                size={0.5}
                margin="50px"
                color="Primary_16"
                weight={600}
              >
                {message}
              </Text>
            </Column>
          )}
          {status !== "inProcess" && (
            <Column span={12} justifyContent={JustifyContent.center} mt={1}>
              {!isUsaPhoneNumber && link && status === "open" ? (
                <LinkContainer href={link} target="_blank">
                  <Text size={0} color="white" weight={600} margin={0}>
                    {t("CoppelAccess.openAccount")}
                  </Text>
                </LinkContainer>
              ) : (
                <ButtonContainer onClick={onClickBtn}>
                  {status === "open" && (
                    <>
                      <Icon
                        icon={"plus"}
                        color="white"
                        size="medium"
                        fillIcon={true}
                      />
                      <Text size={0} color="white" weight={600} margin={0}>
                        {t("CoppelAccess.openAccountUS")}
                      </Text>
                    </>
                  )}
                  {status === "active" && (
                    <>
                      <Text size={0} color="white" weight={600} margin={0}>
                        {t("CoppelAccess.seeAccount")}
                      </Text>
                      <Icon
                        icon={"arrowRight"}
                        color="white"
                        size="medium"
                        fillIcon={true}
                      />
                    </>
                  )}
                </ButtonContainer>
              )}
            </Column>
          )}
        </Row>
      </Container>
    </CoppelAccess>
  );
}

export default CoppelCard
