import styled from "styled-components";
import { ifProp, theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const ContainerFluid = styled.div<{ $show: boolean }>`
  display: ${ifProp("$show", "flex", "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  min-width: min-content;

  .header {
    min-height: 65px;
    background: ${theme("Gradient_13")};
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  }

  .main-content {
    flex: 1;
    margin: 0 40px;
    text-align: center;
    max-width: 1500px;

    ${mediaQuery[Screen.mobile]} {
      margin-inline: 0;
    }
  }

  .footer {
    flex: 0;
    z-index: 9;
  }
`;

export const HeaderContainer = styled.div`
  background-color: ${(props) =>
    props.theme.Background_01
      ? props.theme.Background_01
      : props.theme.Background_03_light};
  box-sizing: border-box;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
`;

export const FooterContainer = styled.div`
  background-color: ${(props) =>
    props.theme.Neutral_900
      ? props.theme.Neutral_900
      : props.theme.Background_03_light};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-sizing: border-box;
  width: 100%;
`;

export const Return = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  height: 10%;
  padding-top: 40px;
  padding-bottom: 30px;

  text-decoration: underline;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  color: ${(props) => props.theme.Background_02};

  div {
    padding-left: 20px;
  }

  ${mediaQuery[Screen.mobile]} {
    padding-top: 24px;
    padding-bottom: 0;

    a {
      padding-top: 5px;
      font-size: 18px;
    }
  }
`;
