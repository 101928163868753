import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";

import { State } from "../../../Redux/Store";

import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import Title from "../../../Components/Title";
import Icon from "../../../Components/Icon";
import Button from "../../../Components/Button";

import {
  IconMessage,
  IconsContainer,
  Advantages,
  ContainerAdvantages,
  WidthContainer
} from "./PayrollUpdate.styled";

import EmployerIconSrc from "../../../Assets/Icons/chat-right.svg";
import DocumentLeft from "../../../Assets/Icons/document-left.svg";
import ArrowRight from "../../../Assets/Icons/down-arrow.svg";

const PayrollInstructions = () => {
  const theme = useSelector((state: State) => state.theme);
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} path="/">
        <Container as={WidthContainer}>
          <Row>
            <Column span={12}>
              <Text size={3} color="black" weight="600" margin="20px 0" align="left">
                {t("Payroll.instructions.title")}
              </Text>
            </Column>
            <Column span={12}>
              <Title
                text={t("Payroll.instructions.description")}
                tagName="h3"
                color="grey"
                size={0.5}
              />
            </Column>
            <Column span={12} as={IconsContainer} my={3}>
              <IconMessage>
                <img src={DocumentLeft} alt={t("Payroll.instructions.message1")} />
                <Title text={t("Payroll.instructions.message1")} tagName={"h3"} color={"black"} size={0} />
              </IconMessage>
              <IconMessage>
                <img src={ArrowRight} />
              </IconMessage>
              <IconMessage>
                <img src={EmployerIconSrc} alt={t("Payroll.instructions.message2")} />
                <Title text={t("Payroll.instructions.message2")} tagName={"h3"} color={"black"} size={0} />
              </IconMessage>
            </Column>
            <Column span={12} my={3}>
              <Title
                text={t("Payroll.instructions.subtitle")}
                tagName="h2"
                color="black"
                size={0.5}
              />
            </Column>
            <Column span={12} as={Advantages}>
              <ContainerAdvantages>
                <Icon icon="clock" size="medium" />
                <div>
                  <Text size={0.5} color="black" weight="600" margin="0" align="left">
                    {t("Payroll.instructions.time.title")}
                  </Text>
                  <Text size={0.5} color="grey" margin="0" align="left">
                    {t("Payroll.instructions.time.message")}
                  </Text>
                </div>
              </ContainerAdvantages>
              <ContainerAdvantages>
                <Icon icon="checkPurple" size="medium" color="linear"/>
                <div>
                  <Text size={0.5} color="black" weight="600" margin="0" align="left">
                    {t("Payroll.instructions.convenience.title")}
                  </Text>
                  <Text size={0.5} color="grey" margin="0" align="left">
                    {t("Payroll.instructions.convenience.message")}
                  </Text>
                </div>
              </ContainerAdvantages>
              <ContainerAdvantages>
                <Icon icon="shieldCheck" size="medium" color="linear" />
                <div>
                  <Text size={0.5} color="black" weight="600" margin="0" align="left">
                    {t("Payroll.instructions.security.title")}
                  </Text>
                  <Text size={0.5} color="grey" margin="0" align="left">
                    {t("Payroll.instructions.security.message")}
                  </Text>
                </div>
              </ContainerAdvantages>
            </Column>
            <Column span={12} my={5}>
              <Button
                onClick={() => navigate("/payroll-authorization")}
                variant="primary"
                sizeButton="xlarge"
                sizeText="large"
                text={t("Payroll.instructions.button")}
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default PayrollInstructions;