import styled from "styled-components";
import { prop, ifProp, theme } from "styled-tools";
import { handleSizeType } from "../../Utilities/Sizes";
import { handleColor } from "../../Utilities/Icons";

export const TextStyled = styled.p<{
  $size: number;
  color: string;
  weight: number;
  align: string;
  margin: string;
  lineHeight?: string;
}>`
  color: ${(props) =>
    props.color ? handleColor(props.color) : theme("Content_UX_01")};
  font-family: Poppins;
  font-weight: ${ifProp("weight", prop("weight"), "400")};
  font-size: ${(props) => (props.$size ? handleSizeType(props.$size) : "12px")};
  line-height: ${prop("lineHeight", "normal")};
  text-align: ${ifProp("align", prop("align"), "center")};
  margin: ${prop("margin")};
`;
