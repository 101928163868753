import { createAsyncThunk } from "@reduxjs/toolkit";
import { QUOTATION } from "../../Constants/Quote";
import { AxiosError } from "axios";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GlobalPaymentsAPI } from "../../Services/API/GlobalPaymentsApi";

interface PayloadProps {
  countryDestination: string;
  currencyDestination: string;
  amount: number;
  payerQuoteCode: string;
}

export const getQuoteAction = createAsyncThunk(
  QUOTATION,
  async (payload:PayloadProps, thunkAPI) => {
    try {
      const response = await GlobalPaymentsAPI.get(`Quote/tiers/${payload.countryDestination}/${payload.currencyDestination}/${payload.amount}/${payload.payerQuoteCode}`);
  
      return response.data.deliveryMethods;
    } catch (error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
