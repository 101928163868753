import styled, { css } from "styled-components";
import { ifProp, switchProp, theme } from "styled-tools";

import { IconsFontColors } from "../../Models/IconInterface";

const IconFontStyled = styled.span<{
  size?: string;
  color?: IconsFontColors;
  gradient?: boolean;
}>`
  color: ${ifProp("color", theme("Primary_01"), "inherit")};
  font-size: ${switchProp("size", {
    small: "12px",
    medium: "24px",
    large: "32px",
    xlarge: "40px",
    xxlarge: "48px",
    xxxlarge: "64px",
    default: "16px",
  })};
  ${ifProp(
    "gradient",
    css`
      background: -webkit-${theme("Primary_01")};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
  )}
`;

type StyledParams = Parameters<typeof IconFontStyled>;
type StyledProps = StyledParams[0];

export function FontStyled(props: StyledProps) {
  return <IconFontStyled {...props} />;
}
