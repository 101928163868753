import { useEffect, useState } from "react";
import { propsDarkTooltip } from "../../Models/TooltipProps";

import { TooltipContainer, TooltipBoxText } from "./DarkTooltip.styled";

const DarkTooltip = (props: propsDarkTooltip) => {
  const { textInfo, hasShadow, show } = props;
  const [canShow, setCanShow] = useState(show);
  useEffect(() => {
    if (show) {
      setCanShow(true);
      setTimeout(() => {
        setCanShow(false);
      }, 5000);
    } else {
      setCanShow(false);
    }
  }, [show]);
  return (
    <>
      {canShow && (
        <TooltipContainer $hasShadow={hasShadow}>
          {textInfo && (
            <TooltipBoxText>
              <span
                dangerouslySetInnerHTML={{
                  __html: textInfo || "",
                }}
              />
            </TooltipBoxText>
          )}
        </TooltipContainer>
      )}
    </>
  );
};

export default DarkTooltip;
