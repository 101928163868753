import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

// Models
import { BankTransferStatus } from "../../../../Models/Funds";
import { AlignItems, JustifyContent } from "../../../../Models/Column";

// Own components
import { Column, Row } from "../../../../Components/Grid";
import {
  BankTransfersCardContent,
  BankTransfersCardArrow,
  BankTransfersCardPending,
  BankTransfersCardCompleted,
  BankTransfersCardError,
  BankTransfersCardItem,
  BankTransfersCardDefault
} from "./BankTransfersCard.styled";
import Icon from "../../../../Components/Icon";

// Own Services
import CurrencyService from "../../../../Services/CurrencyService";

import { CustomText } from "../../BankTransfers.styled";
import { BankTransfersHeaderIcons } from "../BankTransfersHeader/BankTransferHeaderIcons.component";

type Props = {
  title?: string;
  subTitle?: string;
  amount?: number;
  description?: string;
  order: string;
  status: string;
  buttonText?: string;
  isListItem?: boolean;
  isEmpty?: boolean;
  action?: () => void;
  buttonAction?: () => void;
};

export const BankTransfersCard = ({
  title,
  subTitle,
  amount,
  description,
  order,
  status,
  buttonText,
  buttonAction,
  action,
  isListItem,
  isEmpty
}: Props) => {
  const [t] = useTranslation("global");
  const statusData = setCardTextsStatus(status, t, isListItem || false);

  if (isEmpty) {
    return (
      <Row>
        <Column
          span={12}
          as={BankTransfersCardDefault}
        >
          <Row>
            <Column span={12} px={2} pt={2} pb={0}>
              <CustomText
                color="black"
                size={0.5}
                align="left"
                weight={600}
                margin="5px 0 12px"
              >
                {title || ""}
              </CustomText>
            </Column>
            <Column span={7} px={2} pb={2}>
              <CustomText
                color="solid_light"
                size={0.5}
                align="left"
                weight={600}
                margin="0"
              >
                {subTitle || ""}
              </CustomText>
              <CustomText
                color="grey"
                size={0}
                align="left"
                weight={400}
                margin="0"
              >
                {description || ""}
              </CustomText>
            </Column>
            <Column
              span={5} 
              pr={2}
              alignItems={AlignItems.center}
              justifyContent={JustifyContent.flexEnd}
            >
              <button onClick={() => buttonAction ? buttonAction() : null}>
                {buttonText}
              </button>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }

  return (
    <Row 
      className="bank-transfers-item"
      onClick={() => action ? action() : null}
    >
      <Column
        span={12}
        as={isListItem ? BankTransfersCardItem : setCardStatus(status)}
      >
        <Row>
          {!isListItem && (
            <Column span={3} xl={2} p={2} pl={2} pr={1} justifyContent={JustifyContent.center}>
              {setCarStatusIcon(status)}
            </Column>
          )}
          <Column
            span={isListItem ? 12 : 9 }
            {...(!isListItem
              ? { xl: 10, pt: 2, pb: 2, pr: 2 }
              : { p: 2, pt: 1 })}
          >
            <BankTransfersCardContent>
              <div>
                {!isListItem ? (
                  <CustomText
                    color="black"
                    size={0}
                    align="left"
                    weight={600}
                    margin="5px 0"
                    status={
                      status === BankTransferStatus.COMPLETED
                        ? BankTransferStatus.COMPLETED
                        : BankTransferStatus.DEFAULT
                    }
                  >
                    {title || statusData.title || ''}
                  </CustomText>
                ) : (
                  <Row>
                    <Column span={12} pt={1}>
                      <BankTransfersHeaderIcons
                        status={status}
                        statusText={statusData.title}
                      />
                    </Column>
                  </Row>
                )}
                <CustomText
                  color="black"
                  size={1}
                  align="left"
                  weight={600}
                  margin="5px 0"
                >
                  {`$${CurrencyService.getFloatAmount(amount?.toString() || "")}`}
                </CustomText>
                <CustomText
                  color="grey"
                  size={0}
                  align="left"
                  weight={600}
                  margin="2px 0"
                >
                  {order}
                </CustomText>
                <CustomText
                  color="grey"
                  status={
                    status === BankTransferStatus.FAILED
                      ? BankTransferStatus.FAILED
                      : BankTransferStatus.DEFAULT
                  }
                  size={0}
                  align="left"
                  weight={status === BankTransferStatus.FAILED ? 600 : 400}
                  margin="2px 0"
                >
                  {description || statusData.description || ""}
                </CustomText>
              </div>
              <BankTransfersCardArrow>
                <Icon icon="chevronRight" size="medium" color="black" />
              </BankTransfersCardArrow>
            </BankTransfersCardContent>
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

const setCardTextsStatus = (status: string, t: TFunction, isOnlyStatus: boolean) => {
  switch (status) {
    case BankTransferStatus.PENDING:
      return {
        title: 
          !isOnlyStatus
            ? t("Funds.BankTransfer.statusDescriptions.titlePending")
            : t("Funds.BankTransfer.statusPending"),
        description: t("Funds.BankTransfer.statusDescriptions.textPending"),
      };
    case BankTransferStatus.PROCESSING:
      return {
        title: 
          !isOnlyStatus
            ? t("Funds.BankTransfer.statusDescriptions.titleProcessing")
            : t("Funds.BankTransfer.statusProcessing"),
        description: t("Funds.BankTransfer.statusDescriptions.textProcessing"),
      };
    case BankTransferStatus.COMPLETED:
      return {
        title:
          !isOnlyStatus
            ? t("Funds.BankTransfer.statusDescriptions.titleConfirmed")
            : t("Funds.BankTransfer.statusCompleted"),
        description: t("Funds.BankTransfer.statusDescriptions.textConfirmed"),
      };
    case BankTransferStatus.FAILED:
      return {
        title: 
          !isOnlyStatus
            ? t("Funds.BankTransfer.statusDescriptions.titleFailed")
            : t("Funds.BankTransfer.statusFailed"),
        description: t("Funds.BankTransfer.statusDescriptions.textFailed"),
      };
    default:
      return {
        title: "",
        description: ""
      };
  }
}

const setCardStatus = (status: string) => {
  switch (status) {
    case BankTransferStatus.PENDING:
      return BankTransfersCardPending;
    case BankTransferStatus.PROCESSING:
      return BankTransfersCardPending;
    case BankTransferStatus.COMPLETED:
      return BankTransfersCardCompleted;
    case BankTransferStatus.FAILED:
      return BankTransfersCardError;
    default:
      return BankTransfersCardDefault;
  }
}

const setCarStatusIcon = (status: string) => {
  switch (status) {
    case BankTransferStatus.PENDING:
      return <Icon icon="circleXWarning" size="xlarge" />;
    case BankTransferStatus.PROCESSING:
      return <Icon icon="circleXWarning" size="xlarge" />;
    case BankTransferStatus.COMPLETED:
      return <Icon icon="alertCheckCircle" width={45} height={45} />;
    case BankTransferStatus.FAILED:
      return <Icon icon="alertInfoErrorCircle" width={45} height={45} />;
    default:
      return <Icon icon="circleXWarning" width={50} height={50} />;
  }
}