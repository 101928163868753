import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useTranslation } from "react-i18next";

// Own styles
import { ReactWebCamContainer, DisclosureFile } from "./ReactWebCam.styled";

// Own components
import { Column, Row } from "../Grid";
import Icon from "../Icon";
import Text from "../Text";
import Button from "../Button";

// Own models
import { CapturePhotoProps } from "../../Models/DragDrop";

const ReactWebCam = (props:CapturePhotoProps) => {
  const { onChange, textLabel, deleteImg, showBtnDelete } = props;
  const [t] = useTranslation("global");
  // eslint-disable-next-line
  const webcamRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onChange(imageSrc);
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const RemovePhoto = () => {
    setImgSrc(null)
    onChange("");
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
  };

  useEffect(() => {
    RemovePhoto();
  }, [deleteImg]);

  return(
    <Row>
      <Column span={12}>
        <Text size={18} weight={600} margin="12px 6px" align="left" color="grey">
         { textLabel }
        </Text>
      </Column>

      {!imgSrc && (
        <Column span={12}>
          <ReactWebCamContainer>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              height="auto"
              width="100%"
              videoConstraints={videoConstraints}
            />
          </ReactWebCamContainer>
        </Column>
      )}
      
      { imgSrc && (
        <Column span={12}>
          <ReactWebCamContainer>
            <img src={imgSrc} width="100%" />
          </ReactWebCamContainer>
        </Column>
      )}

      <Column span={12}>
        {!imgSrc && (
          <Button
            type="button"
            variant="primary"
            text={t("buttons.capturePhoto")}
            sizeText="medium"
            onClick={capture}
          />
        )}
        {showBtnDelete && imgSrc &&
          <Button
            type="button"
            variant="primary"
            text={t("buttons.removePhoto")}
            sizeText="medium"
            iconButtonLeft="trash"
            colorIcon="white"
            sizeIcon="medium"
            onClick={RemovePhoto}
          />
        }
      </Column>

      <Column span={12}>
        <DisclosureFile>
          <Icon fillIcon={false} icon="circleInfo" size="medium" color="black" />
          <Text size={1} weight={500} margin="0" align="left">
            {t("visaIdentity.infoCapture")}
          </Text>
        </DisclosureFile>
      </Column>
    </Row>
  )
};

export default ReactWebCam;