// Own components
import Footer from "../Footer/Footer.component";
import Header from "../Header";
import Link from "../Link";
import Icon from "../Icon";
import AuthProvider from "../../Contexts/AuthProvider/AuthProvider";
import FloatingHelpButton from "../FloatingHelpButton";
import HelpLinks from "../HelpLinks";

// Interface
import { PropsLayout } from "../../Models/Layout";

// Own styles
import {
  ContainerFluid,
  HeaderContainer,
  Content,
  FooterContainer,
  Return,
} from "./Layout.styled";

const Layout = (props: PropsLayout) => {
  const { path, textLink, show=true, showHelpBtn=true, refToScroll } = props;

  return (
    <AuthProvider>
      { show && (
        <ContainerFluid $show={show}>
          <HeaderContainer className="header">
            <Header refToScroll={refToScroll}/>
          </HeaderContainer>
          <Content className="main-content">
            {(path || textLink) && (
              <Return>
                <Link href={path} size={1.5}>
                  <Icon icon="circleArrowLeftGrey" size="large" />
                </Link>
              </Return>
            )}
            {props.children}
          </Content>
          {showHelpBtn &&
            <FloatingHelpButton>
              <HelpLinks />
            </FloatingHelpButton>
          }
          <FooterContainer className="footer">
            <Footer />
          </FooterContainer>
        </ContainerFluid>
      )}
    </AuthProvider>
  );
};

export default Layout;
