import { ItemTransactionProps } from "../../../Models/tTransactionAccount";

import { capitalizeFirstLetter } from "../../../Utilities/CapitalizeFormat";

import Text from "../../../Components/Text";
import Icon from "../../../Components/Icon";

import {
  ContainerItem,
  TitleItem,
  CurrentBalance,
} from "./Transactions.styled";

const ContainerItemTransaction = (props: ItemTransactionProps) => {
  const { title, currentBalance, description, transactionType, merchant, status, showDetails } = props;

  return (
    <ContainerItem onClick={showDetails}>
      <TitleItem>
        <Text size={18} weight={600} align="left">
          {title}
        </Text>
        <CurrentBalance>
          <Text size={18} weight={600}>
            {currentBalance}
          </Text>
          <Icon icon="chevronRight" color="black" size="medium" />
        </CurrentBalance>
      </TitleItem>
      <Text size={0.5} color="grey" align="left">
        {description}
      </Text>
      <Text size={0.5} color="grey" align="left">
        {capitalizeFirstLetter(transactionType)}
      </Text>
      <Text size={0.5} color="grey" align="left">
        {merchant}
      </Text>
      <Text size={0.5} color="grey" align="left">
        {capitalizeFirstLetter(status)}
      </Text>
    </ContainerItem>
  )
};

export default ContainerItemTransaction;