// Own components
import { Column, Container, Row } from "../Grid";
import Text from "../Text";
import Icon from "../Icon";

// Interface
import { TransactionsProps } from "../../Models/TransactionsCard";

// Own styles
import {
  CardDetails,
  IconSection,
  CostSectionMobile,
  GridAlignCenter,
} from "./TransactionsCard.styled";

// Own utils
import { capitalizeFirstLetter } from "../../Utilities/CapitalizeFormat";
import { FormatNumberToThousands } from "../../Utilities/FormatNumberToThousands";

const TransactionsCard = (props: TransactionsProps) => {
  const {
    date,
    detailText,
    operation,
    cost,
    typeOperation,
    getDetails,
    txnType,
  } = props;

  return (
    <Container onClick={getDetails} as={CardDetails}>
      <Row>
        <Column span={3} as={GridAlignCenter}>
          <IconSection>
            <Icon icon="send" color="white" fillIcon={false} size="xlarge" />
          </IconSection>
        </Column>
        <Column span={6}>
          <Row>
            <Column span={12}>
              <Text size={0.5} align="left" color="black" weight={600} margin="0">
                {operation}
              </Text>
              <Text size={0} align="left" color="grey" weight={400} margin="0">
                {capitalizeFirstLetter(typeOperation)} - {detailText}
              </Text>
              <Text size={0} align="left" color="grey" weight={400} margin="0">
                {date}
              </Text>
            </Column>
          </Row>
        </Column>
        <Column span={3}>
          <CostSectionMobile>
            <Text
              size={0.5}
              color={txnType === "credit" ? "success" : "error"}
              weight={600}
              margin="0"
            >
              {txnType === "credit" ? "+" : "-"} {`$${FormatNumberToThousands(cost)}`}
            </Text>
          </CostSectionMobile>
        </Column>
      </Row>
    </Container>
  );
};

export default TransactionsCard;
