import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

// Own state of redux
import { AppDispatch, State } from "../../Redux/Store";
import { AuthAction } from "../../Redux/Auth/Auth.actions";
import { TokenValidationAction } from "../../Redux/TokenValidation/TokenValidation.actions";
import { OnboardingSliceAction } from "../../Redux/Onboarding/Onboarding.actions";
import { ByUserSliceAction } from "../../Redux/ByUser/ByUser.actions";
import { PrefilledDataAction } from "../../Redux/PrefilledData/PrefilledData.actions";
import { getUserFeature } from "../../Redux/UserFeatures/UserFeatures.actions";
import { getKCYStatusAction } from "../../Redux/GetKYCStatus/GetKYCStatus.actions";
import { setSteps } from "../../Redux/Onboarding/Onboarding.slice";
import { GetPersonsAction } from "../../Redux/GetPersons/GetPersons.actions";

// Own contants
import { TOKEN_COOKIE_NAME } from "../../Constants/Auth";
import { baseURLAuthSite } from "../../Constants/ConfigureApi";
import {
  TOKEN_VALIDATION_FULFILLED,
  TOKEN_VALIDATION_REJECTED
} from "../../Constants/Token";
import { GET_BY_USER_DATA_FULFILLED,
  GET_ONBOARDING_STEP_FULFILLED,
  GET_ONBOARDING_STEP_REJECTED,
  GET_USER_FEATURE_FULFILLED,
  GET_USER_FEATURE_REJECTED
} from "../../Constants/user";
import { onboardingRoutes } from "../../Constants/Onboarding";
import { WELCOME_NEW_APP } from "../../Constants/Features";
import { GET_KYC_STATUS_FULFILLED, GET_KYC_STATUS_REJECTED, KYC_APPROVED } from "../../Constants/KYCStatus";

// Own models
import { AuthHandlerProps } from "../../Models/AuthHandler";
import { GET_BY_USER_DATA_REJECTED } from "../../Constants/user";
import { Step } from "../../Models/Onboarding";

// Own pages
import WelcomeNewAppPage from "../../Pages/WelcomeNewApp";

// Own components
import Layout from "../Layout";
import LoaderIcon from "../LoaderIcon/LoaderIcon.component";

const AuthHandler = ({
  children
}: AuthHandlerProps) => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const cookies = new Cookies();
  const [loading, setLoading] = useState<boolean>(true);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [requiredOnboarding, setRequiredOnboarding] = useState<boolean>(false);
  const [userFeatureWelcomeNewApp, setUserFeatureWelcomeNewApp] = useState<boolean>(false);

  const authCookie = cookies.get(TOKEN_COOKIE_NAME) || "";
  const token = authCookie || "";

  const GetPersonsData = async (personId: string) => {
    await dispatch(GetPersonsAction(personId));
  };

  const GetKYCStatus = async (personId: string) => {
    const payload = {
      personId: personId
    };
    const response = await dispatch(getKCYStatusAction(payload));

    // Do not redirect & do not fetch prefilled data if requiredOnboarding is false
    if (response?.type === GET_KYC_STATUS_FULFILLED) {
      if (location.pathname.includes("kyc") && response?.payload?.status === KYC_APPROVED) {
        navigate("/")
      }
    }

    if(response?.type === GET_KYC_STATUS_REJECTED) {
      navigate("/dashboard-error");
    }
  };

  const GetKYCAllStatus = async (personId: string) => {
    const payload = {
      personId: personId,
      path: "all"
    };
    const response = await dispatch(getKCYStatusAction(payload));

    if(response?.type === GET_KYC_STATUS_REJECTED) {
      navigate("/dashboard-error");
    }
  };

  const GetOnboardingStatus = async (userId: string) => {
    const responseOnboarding = await dispatch(OnboardingSliceAction(userId));

    if (responseOnboarding?.type === GET_ONBOARDING_STEP_FULFILLED && typeof responseOnboarding.payload === "object") {
      const needsOnboarding = responseOnboarding.payload.requiredOnboarding;
      const nextStepRoute= responseOnboarding?.payload?.nextStep || "personInformation";
      setRequiredOnboarding(needsOnboarding);

      await GetByUser(userId);
  
      const nextStep = responseOnboarding.payload?.nextStep
      if (nextStep === Step.personInformation) {
        navigate(onboardingRoutes.personInformation);
        return
      }
  
      if (nextStep) {
        dispatch(setSteps({
          lastStep: responseOnboarding?.payload?.lastStep,
          nextStep: responseOnboarding?.payload?.nextStep
        }))
        setRedirectUrl(onboardingRoutes?.[nextStepRoute]);
      }
    }

    if (responseOnboarding?.type === GET_ONBOARDING_STEP_REJECTED) {
      navigate("/dashboard-error");
    }
  };

  const GetUserFeature = async (userId: string) => {
    const request = {
      userId: userId,
      featureCode: WELCOME_NEW_APP
    }
    const response = await dispatch(getUserFeature(request));

    if (response?.type === GET_USER_FEATURE_FULFILLED) {
      setUserFeatureWelcomeNewApp(response?.payload?.isActive);
    }

    if (response?.type === GET_USER_FEATURE_REJECTED) {
      setUserFeatureWelcomeNewApp(false);
    }
  };

  const GetByUser = async (userId: string) => {
    const response = await dispatch(ByUserSliceAction(userId));

    if (response?.type === GET_BY_USER_DATA_FULFILLED) {
      await GetUserFeature(response?.payload?.userId);
      await GetPersonsData(response?.payload?.personId);
      await GetKYCAllStatus(response?.payload?.personId);
      await GetKYCStatus(response?.payload?.personId);
    }

    if (response?.type === GET_BY_USER_DATA_REJECTED && response.payload?.response?.status === 401) {
      window.location.href = baseURLAuthSite;
    }

    if (response?.type === GET_BY_USER_DATA_REJECTED) {
      navigate("/");
    }
  };

  const TokenValidate = async () => {
    const response = await dispatch(TokenValidationAction(authCookie));

    if (response?.type === TOKEN_VALIDATION_FULFILLED) {
      const decode = JSON.stringify(jwtDecode(token));
      const userId = JSON.parse(decode)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
      const auth = {
        token: token,
        userId: userId
      };

      await dispatch(AuthAction(auth));
      await dispatch(PrefilledDataAction());

      if(window.location.pathname !== "kyc-submit-kyc") {
        await GetOnboardingStatus(userId);
      }
    }

    if (response?.type === TOKEN_VALIDATION_REJECTED) {
      localStorage.clear();
      window.location.href = baseURLAuthSite; 
    }

    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      TokenValidate();
    } else {
      window.location.href = baseURLAuthSite; 
    }
  }, []);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Layout show={true}>
          <LoaderIcon
            title={t("header.titleMobile")}
            description={t("loaderText.description")}
          />
        </Layout>
      </ThemeProvider>
    )
  }

  if (!redirectUrl && userFeatureWelcomeNewApp) {
    return <WelcomeNewAppPage />
  }

  if (window.location.pathname === "/" && requiredOnboarding) {
    navigate("/kyc-personal-data");
  }

  return children
}

export default AuthHandler;
