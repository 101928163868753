import {
  CSSObjectWithLabel,
  StylesConfig,
  Theme,
  ThemeConfig,
} from "react-select";
import theme from "../../Contexts/ThemeProvider";
import { SelectOption } from "../../Models/Select";

export const selectStyles: StylesConfig<SelectOption> = {
  container: (baseStyles, state) => ({
    ...baseStyles,
    boxSizing: "initial",
    fontFamily: "Poppins",
    textAlign: "left",
    marginLeft: state.selectProps.menuIsOpen ? "-7px" : "auto",
    marginTop: state.selectProps.menuIsOpen ? "-7px" : "0px",
    marginRight: "auto",
    width: "100%",
    maxWidth: "100%",
    padding: state.selectProps.menuIsOpen ? "7px" : baseStyles.padding,
    borderRadius: state.selectProps.menuIsOpen ? "24px 24px 0px 0px" : "24px",
    border: state.selectProps.menuIsOpen
      ? `1px solid ${theme.Background_03}`
      : undefined,
  }),
  control: (state): CSSObjectWithLabel => ({
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "12px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    padding: "13px 15px",
    borderColor: state.isFocused ? theme.Primary_01 : theme.Background_03,
    margin: "auto",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    marginTop: 0,
    borderRadius: "0% 0% 24px 24px",
    overflow: "hidden",
    left: 0,
    zIndex: 2,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    paddingBottom: "0px",
    maxHeight: "280px",
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    padding: "1rem 1.5rem",
    borderBottom: "1px solid #B3BBCE",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    display: state.selectProps.menuIsOpen ? "none" : baseStyles.display,
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  valueContainer: (baseStyles, props) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: 16,
    padding: !props.selectProps.menuIsOpen ? 0 : baseStyles.padding,
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    margin: 0,
    fontSize: 16,
  }),
};

export const selectTheme: ThemeConfig = (defaultTheme): Theme => ({
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: theme.Background_02,
  },
});
