import { FontStyled } from "./IconFont.styled";

import {
  IconSize,
  IconsFontColors,
  IconsFontType,
} from "../../Models/IconInterface";

interface Props {
  name: IconsFontType;
  size?: IconSize;
  color?: IconsFontColors;
  gradient?: boolean;
  onClick?: () => void;
}

const IconFont = ({ name, gradient, color, size, onClick }: Props) => {
  return (
    <FontStyled
      className={`icon-se-${name}`}
      size={size || "default"}
      {...(gradient ? { gradient: true } : {})}
      {...(color ? { color } : {})}
      {...(onClick ? { onClick } : {})}
    />
  );
};

export default IconFont;
